/* eslint-disable */
import React, { useState } from "react";
import { BottomModal, Button, Text, useNotification } from "./";

interface IDownload {
  data: any;
  name: string;
}

interface IDownloadPromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApprove: () => Promise<IDownload | null>;
  title: string;
}

const DownloadPromptModal: React.FC<IDownloadPromptModalProps> = React.memo(({ isOpen, onClose, onApprove, title }) => {
  const [loading, setLoading] = useState(false);

  const downloadExcelFile = (res, fileName: string) => {
    const url = URL.createObjectURL(new Blob([res.data]));
    console.log(url);
    const link = document.createElement("a");
    link.href = url;
    link.download = `virazh-${fileName}.xlsx`;
    link.click();
    useNotification(`فایل لیست ${title} با موفقیت دانلود شد.`, "success");
  };

  const approveBtnHandler = async () => {
    setLoading(true);
    const result = await onApprove();
    if (result) {
      downloadExcelFile(result.data, result.name);
    }
    setLoading(false);
    onClose();
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <Text type="H4" className="ts-accent mb-4 text-right">
        دانلود لیست
      </Text>

      <Text type="P3" className="ts-accent mb-4 text-right">
        {`آيا میخواهید لیست ${title} را دانلود کنید؟`}
      </Text>

      <div className="w-full flex justify-between">
        <Button
          onClick={approveBtnHandler}
          loading={loading}
          bcolor="primary"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          بله
        </Button>
        <Button
          onClick={onClose}
          bcolor="primaryTransparent"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          لغو
        </Button>
      </div>
    </BottomModal>
  );
});

const privileges = [
  {
    label: "مدیر سیستم (ادمین)",
    value: "admin",
  },
  {
    label: "کاربر عادی",
    value: "agent",
  },
];

export { DownloadPromptModal };

const styles = {};
