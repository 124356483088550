/* eslint-disable */
import { vState } from "core";
import React from "react";
import { Tab } from "ui/components";
import { useSnapshot } from "valtio";

interface IPlateDetailTabsProps {
  activeTab: number;
  onActiveChanged: (id: number) => void;
}

const env = import.meta.env;

const PlateDetailTabs: React.FC<IPlateDetailTabsProps> = React.memo(({ activeTab, onActiveChanged }) => {
  const snap = useSnapshot(vState);

  return (
    <div className="w-full flex-nowrap whitespace-nowrap overflow-x-auto overflow-y-hidden ts-hide-scroll-x-but-keep-working touch-pan-x border-b-2 border-b-gray-300 flex justify-start items-center mb-6">
      <Tab
        onClick={(id) => onActiveChanged(id)}
        tabNumber={1}
        title="یادآور های  ثبت شده"
        activeTabNumber={activeTab}
      />
      <Tab
        className="mx-4"
        onClick={(id) => onActiveChanged(id)}
        tabNumber={2}
        title="تاریخچه خودرو"
        activeTabNumber={activeTab}
      />
    </div>
  );
});

export { PlateDetailTabs };
