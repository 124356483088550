import { ConfigLink } from "core";
import { useAuth } from "hooks";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UnauthorizedAccess from "ui/views/ErrorPages/UnauthorizedAccess";
import { LoadingCustom } from "./LoadingPage";

interface GuardProps {
  forceAuthenticated?: boolean;
  forceUnauthenticated?: boolean;
  forceUserRole?: string;
  children: any;
}

export const Guard: FC<GuardProps> = ({ children, forceAuthenticated, forceUnauthenticated, forceUserRole }) => {
  // show loading if we need to wait for authentication service
  const [ready, setReady] = useState<boolean>(forceAuthenticated === undefined && forceUnauthenticated === undefined);
  const [showRoleError, setShowRoleError] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.ready) {
      setReady(true);

      if (!auth.authenticated && forceAuthenticated) {
        navigate(ConfigLink.homePage, { replace: true });
      } else if (auth.authenticated && forceUnauthenticated) {
        navigate(ConfigLink.reminders, { replace: true });
      }

      if (forceAuthenticated && forceUserRole && auth.authenticated && !auth.profile?.roles.includes(forceUserRole)) {
        console.log("Error access");
        setShowRoleError(true);

        setTimeout(() => {
          auth.logout();
        }, 2500);
      }
    }
  }, [auth.ready, auth.authenticated, forceUserRole]);

  // show loading if authentication result is not ready yet
  if (!ready) {
    return <LoadingCustom />;
  }

  if (showRoleError) {
    return <UnauthorizedAccess />;
  }

  return <>{children}</>;
};
