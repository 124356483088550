/* eslint-disable */
import thumbnail from "assets/images/workshop-card-img.svg";
import { ConfigLink, useQueryString } from "core";
import React, { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { StarRating, Text } from "ui/components";

interface IChangeViewFooterProps {
  selectedWorkshop: null | any;
}

const ChangeViewFooter: React.FC<IChangeViewFooterProps> = React.memo(({ selectedWorkshop }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 389px)",
  });

  const [boxOpacity, setBoxOpacity] = useState(false);

  useEffect(() => {
    if (selectedWorkshop) {
      setTimeout(() => {
        setBoxOpacity(true);
      }, 500);
    } else {
      setBoxOpacity(false);
    }
  }, [selectedWorkshop]);

  const showMoreHandler = () => {
    navigate({ pathname: ConfigLink.serviceProviderDetail, search: useQueryString({ id: selectedWorkshop.id }) });
  };

  return (
    <div
      className={`w-full fixed ts-map-footer-bottom z-50 flex flex-col justify-center items-start px-6 max-2xs:px-3`}
    >
      {selectedWorkshop && (
        <div
          className={`w-full relative transition-all break-normal delay-300 p-3 bg-white rounded-xl ts-shadow flex justify-between items-end mb-4 ${
            boxOpacity ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="w-full h-full flex justify-center items-center">
            <img src={thumbnail} alt="thumbnail" className="ml-2" />
            <div className="w-full flex flex-col justify-between items-start">
              <Text type="H6" className={`ts-accent mb-3 truncate w-1/2`}>
                {selectedWorkshop.name || "---"}
              </Text>
              <Text type="P4" className={`ts-gray-06 truncate ${isMobile ? "w-3/5" : "w-1/2"}`}>
                {selectedWorkshop?.categories ? selectedWorkshop.categories.map((item) => item.name).join(", ") : "---"}
              </Text>
            </div>
          </div>
          <div className="w-full h-full flex flex-col justify-end items-end absolute p-3 left-0 bottom-0">
            {selectedWorkshop.averageScore !== 0 && (
              <div className="flex justify-center items-center -mt-1.5">
                {/* {!isMobile && (
                  <Text type="P4" className="ts-gray-06 ml-1 mt-0.5">
                    {selectedWorkshop.averageScore ? parseFloat(selectedWorkshop.averageScore).toFixed(1) : 0}
                  </Text>
                )} */}
                <StarRating
                  // @ts-ignore
                  initialValue={
                    selectedWorkshop.averageScore ? parseFloat(selectedWorkshop.averageScore).toFixed(1) : 0
                  }
                  size={isMobile ? 16 : 18}
                />
              </div>
            )}
            <div onClick={showMoreHandler} className={`flex mt-2 justify-center items-center cursor-pointer`}>
              <Text type="H6" className="ts-primary ml-1 w-max">
                {isMobile ? "بیشتر" : "اطلاعات بیشتر"}
              </Text>
              <BiChevronLeft size={23} className="ts-primary -mt-0.5" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export { ChangeViewFooter };

const styles = {};
