/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, useQueryString, vState } from "core";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import { FaRegMap } from "react-icons/fa";
import { HiOutlineAdjustments } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { SelectPicker } from "rsuite";
import {
  CardLoading,
  ContainerScroll,
  CustomLoader,
  DownloadPromptModal,
  NoResult,
  SearchInput,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";
import { ProviderCard, ProviderFilterModal } from "./components";

const env = import.meta.env;

type SORT_TYPE = "NON" | "DATE" | "NAME";

interface IQueryStringTransfer {
  q?: string;
  ctg?: string[];
  ct?: any;
  view?: any;
  dates?: any[];
}

const ServiceProviders = ({}) => {
  const metaTags = useChangeTitle("ویراژ - لیست کسب و کارها");
  let { q, ctg, ct, view, dates } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [serviceProviders, setServiceProviders] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [downloadModal, setDownloadModal] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("DATE");

  const [filterOpen, setFilterOpen] = useState(false);
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>([]);
  const [CitiesFilter, setCitiesFilter] = useState("");
  const [viewStatusFilter, setViewStatusFilter] = useState<"all" | "actives" | "not-actives">("all");
  const [selectedDateFilter, setSelectedDateFilter] = useState<any[]>([]);

  const getServices = async (
    sort: SORT_TYPE,
    pageIncreased: boolean,
    query?: string | null,
    cats?: string[] | null,
    cts?: string | null,
    vws?: "all" | "actives" | "not-actives" | null,
    dts?: string[] | null,
  ) => {
    if (query) {
      updateURL(
        query,
        cats || ctg || categoriesFilter,
        cts || ct || CitiesFilter,
        vws || view || viewStatusFilter,
        dts || dates || selectedDateFilter,
      );
    }

    await ApiInstance.common
      .getServiceProviders(
        "data",
        pageCount + 1,
        sort,
        query || null,
        cats || ctg || categoriesFilter,
        cts || ct || CitiesFilter,
        vws || view || viewStatusFilter,
        dts || dates || selectedDateFilter,
      )
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...serviceProviders, ...res.data.data];
          setServiceProviders(data);
          setPageCount((prev) => prev + 1);
        } else {
          setServiceProviders(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "یادآورها");
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    if (q) setSearchText(q);
    if (ctg) setCategoriesFilter(ctg);
    if (ct) setCitiesFilter(ct);
    if (view) setViewStatusFilter(view);
    if (dates) setSelectedDateFilter(dates);
    getServices(sortReminders, false, q || null);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > serviceProviders.length && !loading) {
      getServices(sortReminders, true, searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const downloadHandler = async () => {
    setDownloadModal(true);
    const result = await ApiInstance.common
      .getServiceProviders(
        "export",
        1,
        sortReminders,
        q || null,
        ctg || categoriesFilter,
        ct || CitiesFilter,
        view || viewStatusFilter,
        dates || selectedDateFilter,
      )
      .then((res) => {
        console.log(res);
        return {
          data: res,
          name: "providers",
        };
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "دانلود لیست کسب و کارها");
        return null;
      });

    return result;
  };

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    setLoading(true);
    getServices(value, false, searchText);
  };

  const callApi = (e, ctgr, cty, vw, dts) => {
    setLoading(true);
    getServices(sortReminders, false, e, ctgr, cty, vw, dts);
  };
  const [debouncedCallApi] = useState(() => _debounce((e, ctg, ct, view, dts) => callApi(e, ctg, ct, view, dts), 1100));

  const searchChange = (e) => {
    setSearchText(e);
  };

  const handleChange = () => {
    setLoading(true);
    getServices(sortReminders, false, digitsFaToEn(searchText));
  };

  const resetSearch = () => {
    setSearchText("");
    updateURL("", categoriesFilter, CitiesFilter, viewStatusFilter, selectedDateFilter);
    setLoading(true);
    setTimeout(() => {
      callApi(null, categoriesFilter, CitiesFilter, viewStatusFilter, selectedDateFilter);
    }, 1100);
  };

  const updateURL = (
    search: string | null,
    categories: string[] | null,
    city: string | null,
    viewStt: "all" | "actives" | "not-actives",
    formattedDate: string[] | null,
  ) => {
    navigate(
      {
        pathname: ConfigLink.serviceProviders,
        search: useQueryString({
          q: search || undefined,
          ctg: categories || undefined,
          ct: city || undefined,
          view: viewStt || undefined,
          dates: formattedDate || undefined,
        }),
      },
      { replace: true },
    );
  };

  const getFilterData = (cat, city, viewStt, date) => {
    setCategoriesFilter(cat);
    setCitiesFilter(city);
    setViewStatusFilter(viewStt);
    setSelectedDateFilter(date);
    console.log("func", cat, city, viewStt, date);
    updateURL(q || searchText, cat, city, viewStt, date);
    setLoading(true);
    getServices(sortReminders, false, q || searchText, cat, city, viewStt, date);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={26} className="">
        <div className="w-full fixed top-14 pt-6 bg-white z-40 ts-fixed-header">
          <div className="w-full flex justify-between items-center mb-6">
            <Text type="H3" className="ts-accent">
              لیست کسب و کارها
            </Text>
            <div className="flex justify-center items-center">
              <div
                onClick={() => navigate(ConfigLink.serviceProvidersOnMap)}
                className={`transition-all w-10 h-10 p-2 rounded-xl bg-white ts-shadow flex justify-center items-center cursor-pointer`}
              >
                <FaRegMap size={18} className="ts-accent" />
              </div>

              {/* <div
                onClick={() => setDownloadModal(true)}
                className="w-10 h-10 p-2 mr-2 rounded-xl ts-shadow bg-white cursor-pointer flex justify-center items-center"
              >
                <HiOutlineDownload size={22} className="ts-accent mr-px" />
              </div> */}
            </div>
          </div>
          <div className="w-full flex justify-between items-center mb-6 gap-2">
            <SearchInput
              value={searchText}
              onChange={searchChange}
              onReset={resetSearch}
              onSearch={() => handleChange()}
              disabled={loading}
              placeholder="جستجوی نام تعمیرگاه، تعمیرکار،..."
            />

            <div
              onClick={() => setFilterOpen(true)}
              className="w-12 h-12 rounded-xl ts-shadow bg-white cursor-pointer p-2 flex justify-center items-center relative"
            >
              <HiOutlineAdjustments size={22} className="ts-accent" />
              {(categoriesFilter.length !== 0 ||
                CitiesFilter.length !== 0 ||
                viewStatusFilter !== "all" ||
                selectedDateFilter.length === 2) && (
                <BsCircleFill size={13} className="ts-primary absolute -top-1 -left-1" />
              )}
            </div>
          </div>
        </div>

        <div className="w-full ts-container-margin">
          {(loading || serviceProviders.length !== 0) && (
            <div className="w-full flex justify-between items-center mb-6">
              {loading ? (
                <Skeleton className="w-28" />
              ) : (
                <Text type="H6" className="ts-accent">
                  {`${count} کسب و کار`}
                </Text>
              )}
              <SelectPicker
                size="lg"
                data={sortData}
                value={sortReminders}
                disabled={loading}
                cleanable={false}
                onChange={(value: any) => sortChangeHandler(value)}
                searchable={false}
                placeholder="مرتب سازی"
                className={`w-36 h-12 outline-none rounded-xl`}
              />
            </div>
          )}

          {loading ? (
            <CardLoading height="222px" />
          ) : serviceProviders.length === 0 ? (
            <NoResult forSearch={searchText.length !== 0} />
          ) : (
            serviceProviders.map((item) => {
              return <ProviderCard key={item.id} data={item} />;
            })
          )}
          {snap.isEndOfPage && !loading && (
            <div className="w-full flex justify-center">
              <CustomLoader />
            </div>
          )}
        </div>
      </ContainerScroll>

      <ProviderFilterModal
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onFilterChanged={getFilterData}
        categories={categoriesFilter}
        cities={CitiesFilter}
        viewStatus={viewStatusFilter}
        selDates={selectedDateFilter}
      />

      <DownloadPromptModal
        isOpen={downloadModal}
        onClose={() => setDownloadModal(false)}
        onApprove={downloadHandler}
        title="کسب و کارهای ثبت شده"
      />
    </>
  );
};

const sortData = [
  {
    label: "به ترتیب تاریخ",
    value: "DATE",
  },
  {
    label: "به ترتیب الفبا",
    value: "NAME",
  },
];

export { ServiceProviders as default };
