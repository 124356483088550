/* eslint-disable */
import { ConfigLink, useChangeTitle } from "core";
import { useAuth } from "hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface IIntroductionProps {}

const Introduction: React.FC<IIntroductionProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ");
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth.ready) {
      if (auth.authenticated) {
        navigate(ConfigLink.reminders, { replace: true });
      } else {
        auth.login();
      }
    }
  }, [auth.ready, auth.authenticated]);

  return (
    <>
      {metaTags}
      <div></div>
    </>
  );
});

export { Introduction as default };

const styles = {};
