/* eslint-disable */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface ISetBulkSmsStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  id: string;
  type: "approved" | "rejected";
}

const SetBulkSmsStatusModal: React.FC<ISetBulkSmsStatusModalProps> = React.memo(
  ({ isOpen, onClose, onDone, type, id }) => {
    const history = useNavigate();
    const snap = useSnapshot(vState);

    const [loading, setLoading] = useState(false);

    const approveBtnHandler = async () => {
      setLoading(true);
      await ApiInstance.bulkSms
        .changeBulkStatus(id, type)
        .then((res) => {
          console.log(res);
          useNotification("وضعیت پیام تغییر یافت", "success");
          onDone();
          setTimeout(() => {
            onClose();
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <Text type="H4" className="ts-accent mb-6 text-right">
          {type === "approved" ? "تایید ارسال پیامک" : "رد کردن پیامک"}
        </Text>

        <Text type="P3" className="ts-accent mb-6 text-right">
          {type === "approved"
            ? "پس از  تایید ارسال، پیامک به گیرندگان انتخابی سرویس دهنده ارسال خواهد شد. آیا از ارسال پیامک با این محتوا مطمئن هستید؟"
            : "آیا از رد ارسال پیامک مطمئن هستید؟"}
        </Text>

        <div className="w-full flex justify-between gap-2">
          <Button
            onClick={approveBtnHandler}
            loading={loading}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12"
          >
            {type === "approved" ? "بله، ارسال شود" : "بله، رد شود"}
          </Button>
          <Button
            onClick={onClose}
            bcolor="primaryTransparent"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12"
          >
            لغو
          </Button>
        </div>
      </BottomModal>
    );
  },
);

const privileges = [
  {
    label: "مدیر سیستم (ادمین)",
    value: "admin",
  },
  {
    label: "کاربر عادی",
    value: "agent",
  },
];

export { SetBulkSmsStatusModal };

const styles = {};
