/* eslint-disable */
import { ApiInstance, tools } from "core";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Text, useNotification } from "ui/components";

interface IUserInformationBoxProps {
  id: string;
}

const UserInformationBox: React.FC<IUserInformationBoxProps> = React.memo(({ id }) => {
  const [detail, setDetail] = useState<any>({
    enabled: true,
    city: null,
    id: "",
    name: "",
    phoneNumber: "",
    phoneNumberVerified: true,
    username: "",
    createdTimestamp: "",
  });
  const [loading, setLoading] = useState(true);

  const getUserDetail = async () => {
    await ApiInstance.common
      .getUserDetail(id)
      .then((res) => {
        console.log(res);
        setDetail(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "جزئیات کاربر");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserDetail();
  }, [id]);

  return (
    <div className="w-full mb-6 flex flex-col justify-start items-start">
      <div className="w-full flex justify-start items-center mb-6">
        <Text type="H6" className="ts-gray-07 w-28 ml-6">
          نام و نام خانوادگی:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent">
            {detail?.name || "---"}
          </Text>
        )}
      </div>

      <div className="w-full flex justify-start items-center mb-6">
        <Text type="H6" className="ts-gray-07 w-28 ml-6">
          شماره تماس:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent ts-ltr-dir">
            {detail?.phoneNumber || detail?.username || "---"}
          </Text>
        )}
      </div>

      <div className="w-full flex justify-start items-center mb-6">
        <Text type="H6" className="ts-gray-07 w-28 ml-6">
          موقعیت مکانی:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent">
            {detail?.city ? `${detail.city.city}, ${detail.city.province}` : "---"}
          </Text>
        )}
      </div>

      <div className="w-full flex justify-start items-start mb-6">
        <Text type="H6" className="ts-gray-07 w-28 ml-6">
          زمان ثبت نام:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent">
            {detail.createdTimestamp ? tools.getCardsTime(detail.createdTimestamp) : "---"}
          </Text>
        )}
      </div>
    </div>
  );
});

export { UserInformationBox };

const styles = {};
