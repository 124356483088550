/* eslint-disable */
import { isArabic, toPersianChars } from "@persian-tools/persian-tools";
import { ApiInstance, vState } from "core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { HiCalendar } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import DatePicker, { DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/purple.css";
import { CheckPicker, SelectPicker } from "rsuite";
import { BottomModal, Button, Text } from "ui/components";
import { useSnapshot } from "valtio";

interface IProviderFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  categories: string[];
  cities: string;
  viewStatus: "all" | "actives" | "not-actives";
  selDates: string[] | null;
  onFilterChanged: (cat, city, all, dts) => void;
}

const ProviderFilterModal: React.FC<IProviderFilterModalProps> = React.memo(
  ({ isOpen, onClose, categories, cities, viewStatus, selDates, onFilterChanged }) => {
    const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];
    const categoryPicker = useRef();
    const cityPicker = useRef();
    const datePickerRef = useRef();
    const snap = useSnapshot(vState) as typeof vState;

    const status = [
      {
        label: "همه",
        value: "all",
      },
      {
        label: "فقط آرشیو نشده ها",
        value: "actives",
      },
      {
        label: "فقط آرشیو شده ها",
        value: "not-actives",
      },
    ];

    const [categoriesFilter, setCategoriesFilter] = useState<string[]>([]);
    const [categoriesFilterObj, setCategoriesFilterObj] = useState<any[]>([]);
    const [citiesFilter, setCitiesFilter] = useState("");
    // const [citiesFilterObj, setCitiesFilterObj] = useState<any[]>([]);
    const [showAllFilter, setShowAllFilter] = useState("all");
    const [selectedDate, setSelectedDate] = useState<any[]>([]);
    const [selectedDateLabel, setSelectedDateLabel] = useState<any[]>([]);

    const [citiesList, setCitiesList] = useState<any[]>([]);

    const getCities = async () => {
      await ApiInstance.common
        .getAllCities()
        .then((res) => {
          const newArrayOfObj = res.data.data.map(({ id: value, city: city, province: province }) => ({
            value,
            label: `${isArabic(city) ? toPersianChars(city) : city}، ${
              isArabic(province) ? toPersianChars(province) : province
            }`,
          }));
          setCitiesList(newArrayOfObj);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      if (isOpen) {
        if (citiesList.length === 0) getCities();

        setCategoriesFilter(categories);
        setCategoriesFilterObj(getCategoryObject(categories));
        // setCitiesFilterObj(getCityObject(cities));
        setCitiesFilter(cities);
        setShowAllFilter(viewStatus);
        if (selDates) {
          setSelectedDate(selDates);
          setSelectedDateLabel([
            new Date(selDates[0]).toLocaleDateString("fa-IR"),
            new Date(selDates[1]).toLocaleDateString("fa-IR"),
          ]);
        }
      }
    }, [isOpen]);

    const getCategoryObject = (cat) => {
      console.log(cat);

      return snap.categories.filter((item) => cat.filter((ws) => ws === item.id)[0]);
    };
    useEffect(() => {
      const values: any[] = getCategoryObject(categoriesFilter);
      setCategoriesFilterObj(values);
    }, [categoriesFilter]);

    const getCityObject = (cit) => {
      return citiesList.filter((item) => cit.filter((ct) => ct === item.value)[0]);
    };
    // useEffect(() => {
    //   const values: any[] = getCityObject(citiesFilter);
    //   setCitiesFilterObj(values);
    // }, [citiesFilter]);

    const removeCategoryItem = (id) => {
      setCategoriesFilter(categoriesFilter.filter((item) => item !== id));
      setCategoriesFilterObj(categoriesFilterObj.filter((item) => item.id !== id));
    };

    // const removeCityItem = (id) => {
    //   setCitiesFilter(citiesFilter.filter((item) => item !== id));
    //   setCitiesFilterObj(citiesFilterObj.filter((item) => item.value !== id));
    // };

    const saveDateHandler = (date) => {
      if (date.length === 2) {
        setSelectedDate([moment(date[0].toDate()).toISOString(), moment(date[1].toDate()).toISOString()]);
        setSelectedDateLabel([date[0]?.toString(), date[1]?.toString()]);
        // @ts-ignore
        datePickerRef.current?.closeCalendar();
      }
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <Text type="H4" className="ts-accent text-right mb-8">
          فیلتر کسب و کارها
        </Text>
        <div className="w-full flex flex-col justify-start items-start mb-8">
          <Text type="H6" className="ts-accent text-right mb-1">
            نوع
          </Text>
          <Text type="P5" className="ts-accent text-right mb-2">
            می توانید چند گزینه را انتخاب کنید.
          </Text>
          <CheckPicker
            size="lg"
            name="workshop_type"
            placement="auto"
            // @ts-ignore
            ref={categoryPicker}
            data={snap.SelectPickerCategories}
            value={categoriesFilter}
            onChange={(value) => setCategoriesFilter(value)}
            onClean={() => setCategoriesFilter([])}
            block
            cleanable
            searchable={false}
            countable={false}
            placeholder="نوع کسب و کار خود را انتخاب کنید"
            menuClassName="ts-workshops-picker"
            className={`ts-filter-picker w-full h-12 outline-none rounded-xl placeholder:text-black`}
            renderExtraFooter={() => (
              <div className="w-full py-3 px-5 border-t-2 flex items-center">
                <Button
                  bcolor="primaryTransparent"
                  onClick={() => {
                    //@ts-ignore
                    categoryPicker.current.close();
                  }}
                  className="w-full rounded-xl h-11"
                  classNameContainer="w-full"
                >
                  تایید و بستن
                </Button>
              </div>
            )}
          />
          {categoriesFilterObj.length !== 0 && (
            <div className="w-full flex flex-wrap justify-start items-center gap-2 mt-2">
              {categoriesFilterObj.map((item: any) => {
                return (
                  <div
                    onClick={() => removeCategoryItem(item.id)}
                    className="flex rounded-full cursor-pointer justify-center items-center px-4 py-1 ts-bg-gray-08 w-max"
                  >
                    <Text type="HM" className="text-white ml-1">
                      {item.name}
                    </Text>
                    <IoMdClose size={20} className="text-white -mt-px" />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="w-full flex flex-col justify-start items-start mb-8">
          <Text type="H6" className="ts-accent text-right mb-2">
            شهر
          </Text>

          <SelectPicker //change to close button picker
            size="lg"
            name="workshop_type"
            placement="auto"
            // @ts-ignore
            ref={cityPicker}
            data={citiesList}
            value={citiesFilter}
            onChange={(value) => setCitiesFilter(value)}
            onClean={() => setCitiesFilter("")}
            block
            cleanable
            searchable
            countable={false}
            placeholder="شهر را انتخاب کنید"
            menuClassName="ts-workshops-picker"
            className={`ts-filter-picker w-full h-12 outline-none rounded-xl`}
          />
        </div>

        <Text type="H6" className="ts-accent text-right mb-2">
          وضعیت
        </Text>
        <SelectPicker
          size="lg"
          name="workshop_type"
          placement="auto"
          cleanable={false}
          searchable={false}
          data={status}
          value={showAllFilter}
          onChange={(value) => setShowAllFilter(value)}
          block
          placeholder=""
          // menuClassName="ts-workshops-picker"
          className={`w-full h-12 mb-8 outline-none rounded-xl`}
        />

        <Text type="H6" className="ts-accent text-right mb-2">
          تاریخ ثبت نام
        </Text>
        <DatePicker
          ref={datePickerRef}
          range
          dateSeparator=" - "
          value={selectedDate}
          editable={false}
          onChange={saveDateHandler}
          weekDays={weekDays}
          maxDate={new DateObject({ calendar: persian })}
          calendar={persian}
          locale={persian_fa}
          containerClassName="w-full h-12 mb-8"
          className="purple"
          inputClass="w-full h-12"
          mapDays={({ date }) => {
            //disable friday
            let isWeekend = [6, 6].includes(date.weekDay.index);

            if (isWeekend)
              return {
                disabled: false,
                style: { color: "#900" },
              };
          }}
          render={(value, openCalendar) => {
            return (
              <div
                onClick={openCalendar}
                className="w-full h-12 px-4 py-3 rounded-xl ts-date-picker-border flex justify-between items-center"
              >
                <Text type="P4" className={`ts-accent`}>
                  {selectedDate.length === 0 ? "انتخاب بازه زمانی" : selectedDateLabel.join(" - ")}
                </Text>
                {selectedDate.length === 0 ? (
                  <HiCalendar size={20} className={`ts-accent -mt-px`} />
                ) : (
                  <IoMdClose
                    onClick={() => {
                      setSelectedDate([]);
                      setSelectedDateLabel([]);
                    }}
                    size={21}
                    className="ts-gray-07 -mt-px cursor-pointer"
                  />
                )}
              </div>
            );
          }}
        />

        <Button
          onClick={() => {
            onFilterChanged(categoriesFilter, citiesFilter, showAllFilter, selectedDate);
            onClose();
          }}
          bcolor="primary"
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl"
        >
          اعمال فیلتر
        </Button>
      </BottomModal>
    );
  },
);

export { ProviderFilterModal };

const styles = {};
