/* eslint-disable */
import { tools } from "core";
import React from "react";
import { HiCheckCircle, HiClock, HiExclamationCircle } from "react-icons/hi";
import { Text } from "ui/components";

interface IFestivalCardProps {
  data: any;
}

const FestivalCard: React.FC<IFestivalCardProps> = React.memo(({ data }) => {
  return (
    <div className={`w-full bg-white ts-shadow rounded-2xl p-4 mb-3`}>
      <div className="w-full flex justify-between items-center mb-4">
        <div className={`flex w-full justify-start items-center`}>
          <Text type="H5" className="ts-accent">
            جشنواره تابستانه
          </Text>
        </div>
      </div>

      <div className="flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-24 ml-3">
          تاریخ شروع:
        </Text>
        <Text type="P4" className="ts-accent">
          {data.festivalActivatedAt ? tools.convertDateToPersianDate(data.festivalActivatedAt) : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-24 ml-3">
          تاریخ پایان:
        </Text>
        <Text type="P4" className="ts-accent">
          {data.festivalTerminatedAt ? tools.convertDateToPersianDate(data.festivalTerminatedAt) : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-24 ml-3">
          اقدامات:
        </Text>
        <Text type="P4" className="ts-accent">
          {`${data.festivalCount || "0"} خودرو پذیرش شده`}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-24 ml-3">
          وضعیت:
        </Text>
        {data.festivalStatus === "succeeded" ? (
          <HiCheckCircle size={23} className="ts-success -mt-px ml-1" />
        ) : data.festivalStatus === "failed" ? (
          <HiExclamationCircle size={23} className="ts-secondary-red -mt-px ml-1" />
        ) : (
          <HiClock size={23} className="ts-warning -mt-px ml-1" />
        )}
        <Text
          type="P4"
          className={`${
            data.festivalStatus === "succeeded"
              ? "ts-success"
              : data.festivalStatus === "failed"
              ? "ts-secondary-red"
              : "ts-warning"
          }`}
        >
          {`${
            data.festivalStatus === "succeeded" ? "موفق" : data.festivalStatus === "failed" ? "ناموفق" : "در حال انجام"
          }`}
        </Text>
      </div>
    </div>
  );
});

export { FestivalCard };

const styles = {};
