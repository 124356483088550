/* eslint-disable */
import { vState } from "core";
import React from "react";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import { Text } from "ui/components";
import { useSnapshot } from "valtio";

interface ICityCardProps {
  data: any;
  onEdit: () => void;
  onDelete: () => void;
}

const CityCard: React.FC<ICityCardProps> = React.memo(({ data, onEdit, onDelete }) => {
  const snap = useSnapshot(vState);

  return (
    <div
      key={data.id}
      className={`w-full rounded-2xl ts-shadow mb-4 p-3 bg-white flex justify-between items-center cursor-pointer relative`}
    >
      <div className="flex flex-col justify-start items-start">
        <Text type="H6" className="ts-accent mb-2">
          {data.city || "---"}
        </Text>
        <Text type="P4" className="ts-accent">
          {data.province || "---"}
        </Text>
      </div>

      {snap.superAdmin && (
        <div className="flex justify-center items-center gap-2">
          <HiPencilAlt onClick={onEdit} size={21} className="ts-primary cursor-pointer" />

          <HiTrash onClick={onDelete} size={21} className="ts-primary cursor-pointer" />
        </div>
      )}
    </div>
  );
});

export { CityCard };

const styles = {};
