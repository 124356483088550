/* eslint-disable */

const ConfigLink = {
  setParam: (url: string, paramName: string, paramValue: string) => {
    return url.replace(paramName, paramValue);
  },
  //Page Not Found
  pageNotFound: "/page-not-found",

  //dashboard
  homePage: "/",

  reminders: "/reminders",
  reminderDetail: "/reminders/detail",

  serviceProviders: "/service-providers",
  serviceProviderDetail: "/service-providers/detail",
  serviceProviderFeedbacks: "/service-providers/feedbacks",
  serviceProviderLocation: "/service-providers/location",
  serviceProvidersOnMap: "/service-providers/map",
  serviceProvidersActivity: "/service-providers/activity",

  plates: "/plates",
  plateDetail: "/plates/detail",
  plateHistory: "/plates/detail/history",
  plateReminderDetail: "/plates/detail/reminders/detail",

  usersList: "/users",
  userDetail: "/users/detail",

  reports: "/reports",
  feedbacks: "/feedbacks",

  versions: "/versions",

  providerFeatures: "/settings/providers",
  ownerFeatures: "/settings/owners",

  partRequests: "/part-requests",
  partRequestDetail: "/part-requests/detail",
  partCategories: "/parts/categories",

  partsList: "/parts-list",
  partDetail: "/parts-list/detail",

  carBrandAndTypes: "/cars-type",
  brandDetail: "/cars-type/detail",

  cities: "/cities",

  bulkSmsList: "/bulk-sms",
  bulkSmsDetail: "/bulk-sms/detail",
};
export { ConfigLink };
