/* eslint-disable */
import { ApiInstance, useObjectState, vState } from "core";
import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

type IType = "add" | "edit";
const env = import.meta.env;

interface IAddCarModelModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: IType;
  data?: any;
  parentId: string;
  childId: string;
  onDone: (data?: any) => void;
}

const AddCarModelModal: React.FC<IAddCarModelModalProps> = React.memo(
  ({ isOpen, onClose, type, data, parentId, childId, onDone }) => {
    const snap = useSnapshot(vState);
    const uploader = React.useRef();

    const [loading, setLoading] = useState(false);
    const [values, setValues] = useObjectState<any>({
      name: "",
    });
    const [modelChildren, setModelChildren] = useState<any[]>([]);
    const { name } = values;

    useEffect(() => {
      if (isOpen && data) {
        console.log(data);
        setValues({ name: data.name });
        setModelChildren(data.children);
      }
      if (type === "add") {
        setValues({ name: "" });
        setModelChildren([]);
      }
    }, [isOpen]);

    const addChildren = async (id, item) => {
      const value = {
        name: item.name,
        parentId: id,
      };
      console.log("add", item);

      await ApiInstance.car
        .addCar(value)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          useNotification(`تیپ ${item.name} اضافه نشد. لطفا مجددا امتحان کنید`, "error", "اضافه کردن تیپ جدید");
        });
    };

    const editChildren = async (id, item) => {
      const value = {
        name: item.name,
      };
      console.log("edit", item);
      await ApiInstance.car
        .editCar(id, value)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          useNotification(`تیپ ${item.name} اضافه نشد. لطفا مجددا امتحان کنید`, "error", "ویرایش تیپ");
        });
    };

    const addBrand = async () => {
      const value = {
        name: name,
        parentId: parentId,
      };

      await ApiInstance.car
        .addCar(value)
        .then((res) => {
          console.log(res);
          if (modelChildren.length !== 0) {
            modelChildren.map(async (item, index) => {
              if (item.name) {
                await addChildren(res.data.data.id, item);
              }
              if (index === modelChildren.length - 1) {
                useNotification("مدل و تیپ جدید اضافه شد", "success", "اضافه کردن مدل جدید");
                onDone();
                setTimeout(() => {
                  onClose();
                }, 1000);
              }
            });
          } else {
            useNotification("مدل جدید اضافه شد", "success", "اضافه کردن مدل جدید");
            onDone();
            setTimeout(() => {
              onClose();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "اضافه کردن برند جدید");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editBrand = async () => {
      const value = {
        name: name,
      };
      const mainChildren = data.children.filter((item) => item.name);

      await ApiInstance.car
        .editCar(childId, value)
        .then((res) => {
          console.log(res);
          if (modelChildren.length !== 0) {
            modelChildren.map(async (item, index) => {
              if (item.name && mainChildren.indexOf((child) => child === item.name) === -1) {
                typeof item.id === "string" ? await editChildren(item.id, item) : await addChildren(childId, item);
              }
              if (index === modelChildren.length - 1) {
                useNotification("مدل و تیپ ویرایش شد", "success", "ویرایش مدل");
                onDone();
                setTimeout(() => {
                  onClose();
                }, 1000);
              }
            });
          } else {
            useNotification("مدل و تیپ ویرایش شد", "success", "ویرایش مدل");
            onDone();
            setTimeout(() => {
              onClose();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "اضافه کردن برند جدید");
        })
        .finally(() => {
          setLoading(false);
        });
      // await ApiInstance.car
      //   .editCar(id || "", value)
      //   .then((res) => {
      //     console.log(res);
      //     useNotification("برند ویرایش شد", "success", "ویرایش برند");
      //     onDone(value);
      //     setTimeout(() => {
      //       onClose();
      //     }, 1000);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "ویرایش برند");
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //   });
    };

    const actionFunction = () => {
      setLoading(true);
      type === "add" ? addBrand() : editBrand();
    };

    const addTypeHandler = () => {
      const newType = {
        id: modelChildren.length,
        name: "",
      };
      setModelChildren([...modelChildren, newType]);
    };

    const changeTypeHandler = (index, e) => {
      let values = [...modelChildren];
      values[index].name = e;
      setModelChildren(values);
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <Text type="H4" className="ts-accent text-right mb-6">
            {type === "add" ? "اضافه کردن مدل و تیپ جدید" : "ویرایش مدل و تیپ"}
          </Text>

          <InputField label="مدل" value={name} onChange={(e) => setValues({ name: e })} placeholder="مدل خودرو" />

          <Text type="H6" className="ts-accent text-right mb-2">
            تیپ‌ها
          </Text>
          <Button
            onClick={() => addTypeHandler()}
            textType="H6"
            bcolor="blackTransparent"
            icon={<IoIosAddCircle size={21} className="ts-accent" />}
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl mb-2"
          >
            اضافه کردن تیپ
          </Button>
          {modelChildren.map((item, index) => {
            return (
              <InputField
                value={item.name}
                disabled={item.name.includes("همه")}
                onChange={(e) => changeTypeHandler(index, e)}
                placeholder="مثلا 206 تیپ دو"
                margin="mb-2"
              />
            );
          })}

          <Button
            onClick={actionFunction}
            loading={loading}
            disabled={name.length === 0}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl"
          >
            ذخیره
          </Button>
        </BottomModal>
      </>
    );
  },
);

export { AddCarModelModal };

const styles = {};
