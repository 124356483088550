/* eslint-disable */
import { useObjectState } from "core";
import { countries } from "hooks";
import React, { useEffect, useState } from "react";
import { SelectPicker } from "rsuite";
import { BottomModal, Button, InputField, /*CarBrandSelector, CarsModal,*/ Text } from "ui/components";

interface IFilter {
  car: null | any;
  manufacture: string;
  manufacturingCountry: string;
}

interface IPartsListFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: (f: any) => void;
  mainFilters: IFilter;
}

const PartsListFilterModal: React.FC<IPartsListFilterModalProps> = React.memo(
  ({ isOpen, onClose, onDone, mainFilters }) => {
    const defaultValues: IFilter = {
      car: null,
      manufacture: "",
      manufacturingCountry: "",
    };

    const [info, setInfo] = useObjectState<any>(defaultValues);
    const { car, manufacture, manufacturingCountry } = info;

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
      if (isOpen) {
        setInfo(mainFilters);
      }
    }, [isOpen]);

    const approveBtnHandler = async () => {
      setLoading(true);
      await onDone(info);
      setLoading(false);
      onClose();
    };

    const resetFilters = () => {
      setInfo(defaultValues);
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <div className="w-full flex flex-col justify-start items-start">
            <Text type="H4" className="ts-accent mb-6">
              فیلتر
            </Text>

            {JSON.stringify(info) !== JSON.stringify(defaultValues) && (
              <Text onClick={resetFilters} type="H6" className="ts-primary mb-6 cursor-pointer">
                حذف همه فیلتر‌ها
              </Text>
            )}

            {/* <CarBrandSelector value={car ? car.name : null} /> */}

            <InputField
              label="شرکت سازنده"
              value={manufacture}
              onChange={(e) => setInfo({ manufacture: e })}
              placeholder="شرکت سازنده قطعه"
            />

            <Text type="H6" className="ts-accent mb-2">
              کشور سازنده
            </Text>
            <SelectPicker
              size="lg"
              data={countries}
              value={manufacturingCountry}
              onChange={(value) => setInfo({ manufacturingCountry: value })}
              block
              placement="auto"
              searchable
              cleanable={false}
              placeholder="کشور سازنده قطعه را انتخاب کنید"
              className={`w-full h-12 outline-none rounded-xl mb-6`}
            />

            <div className="w-full flex justify-between">
              <Button
                onClick={approveBtnHandler}
                loading={loading}
                disabled={JSON.stringify(mainFilters) === JSON.stringify(info)}
                bcolor="primary"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                اعمال فیلتر
              </Button>
              <Button
                onClick={onClose}
                bcolor="primaryTransparent"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                لغو
              </Button>
            </div>
          </div>
        </BottomModal>

        {/* <CarsModal
        isSingleChoice
        selectedCars={car}
        selectCarHandler={(value) => {
          setInfo({ car: value });
        }}
      /> */}
      </>
    );
  },
);

export { PartsListFilterModal };

const styles = {};
