/* eslint-disable */
import { ApiInstance, useChangeTitle, vState } from "core";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import {
  Breadcrumb,
  Button,
  CardLoading,
  ContainerScroll,
  NoResult,
  SearchInput,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";
import { AddEditCategoryModal, DeleteCategoryModal, PartCategoryCard } from "./components";

const PartCategories = ({}) => {
  const metaTags = useChangeTitle("ویراژ - مدیریت دسته بندی قطعات");
  const snap = useSnapshot(vState);

  const [categories, setCategories] = useState<any[]>([]);

  const [addCategoryShow, setAddCategoryShow] = useState(false);
  const [deleteCategoryShow, setDeleteCategoryShow] = useState(false);
  const [type, setType] = useState<"add" | "edit">("add");
  const [selectedCategory, setSelectedCategory] = useState<any>(null);

  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const getCategories = async (pageIncreased: boolean, query?: string | null) => {
    await ApiInstance.shop
      .getPartCategories(pageCount + 1, query || null)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...categories, ...res.data.data];
          setCategories(data);
          setPageCount((prev) => prev + 1);
        } else {
          setCategories(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "دسته بندی قطعات");
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getCategories(false);
  }, []);

  const callApi = (e) => {
    setLoading(true);
    getCategories(false, e);
  };
  const [debouncedCallApi] = useState(() => _debounce((e) => callApi(e), 1100));

  const handleChange = (e) => {
    setSearchText(e);
    debouncedCallApi(e);
  };

  const resetSearch = () => {
    setSearchText("");
    setLoading(true);
    setTimeout(() => {
      callApi(null);
    }, 1100);
  };

  const addItemHandler = () => {
    setType("add");
    setAddCategoryShow(true);
  };

  const editItemHandler = (item) => {
    setSelectedCategory(item);
    setType("edit");
    setAddCategoryShow(true);
  };
  const deleteItemHandler = (item) => {
    setSelectedCategory(item);
    setDeleteCategoryShow(true);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={110} className="">
        <div className="w-full fixed top-14 pt-6 bg-white z-40 ts-fixed-header">
          <Breadcrumb
            links={[
              {
                name: "قطعات",
                active: false,
                link: "",
              },
              {
                name: "مدیریت دسته بندی قطعات",
                active: false,
                link: ``,
              },
            ]}
            className="mb-4"
          />
          <Text type="H3" className="ts-accent mb-4">
            مدیریت دسته بندی قطعات
          </Text>

          <SearchInput
            value={searchText}
            onChange={handleChange}
            onReset={resetSearch}
            onSearch={() => callApi(searchText)}
          />
        </div>

        <div
          className={`w-full ${
            categories.length === 0 && !loading ? "ts-container-margin" : "ts-container-more-margin"
          }`}
        >
          <div className="w-full flex justify-between items-center mb-4">
            {loading ? (
              <>
                <Skeleton className="w-28" />
                {/* <Skeleton className="w-36 h-12 outline-none rounded-xl" /> */}
              </>
            ) : (
              count !== 0 && (
                <>
                  <Text type="H6" className="ts-accent">
                    {`${count} دسته بندی`}
                  </Text>
                </>
              )
            )}
          </div>

          {snap.superAdmin && (
            <Button
              onClick={addItemHandler}
              textType="H6"
              bcolor="blackTransparent"
              icon={<IoIosAddCircle size={21} className="ts-accent" />}
              classNameContainer="w-full"
              className={`w-full h-12 rounded-xl mb-4 ${categories.length === 0 && "mt-2"}`}
            >
              اضافه کردن دسته بندی جدید
            </Button>
          )}

          {loading ? (
            <CardLoading height={"48px"} className="mb-2" />
          ) : categories.length === 0 ? (
            <NoResult forSearch={searchText.length !== 0} />
          ) : (
            categories.map((item: any, index) => {
              return (
                <PartCategoryCard
                  key={index}
                  data={item}
                  onEdit={() => editItemHandler(item)}
                  onDelete={() => deleteItemHandler(item)}
                />
              );
            })
          )}
        </div>
      </ContainerScroll>
      <AddEditCategoryModal
        isOpen={addCategoryShow}
        onClose={() => setAddCategoryShow(false)}
        type={type}
        onDone={() => getCategories(false)}
        id={selectedCategory ? selectedCategory.id : ""}
        data={selectedCategory ? selectedCategory : null}
      />

      <DeleteCategoryModal
        isOpen={deleteCategoryShow}
        onClose={() => setDeleteCategoryShow(false)}
        onDone={() => getCategories(false)}
        id={selectedCategory ? selectedCategory.id : ""}
        name={selectedCategory ? selectedCategory.name : null}
      />
    </>
  );
};

export { PartCategories as default };
