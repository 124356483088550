/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { ServerNotRespond } from "ui/views/ErrorPages/ServerNotRespond";
import { useSnapshot } from "valtio";
import { LoadingCustom } from "../LoadingPage";

interface IConfigFetcherProps {
  children: any;
}

const ConfigFetcher: React.FC<IConfigFetcherProps> = React.memo(({ children }) => {
  const snap = useSnapshot(vState);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    healthCheck();
  }, []);

  const getUI = async () => {
    await ApiInstance.common.getUI();
  };

  const healthCheck = async () => {
    await ApiInstance.common
      .healthCheck()
      .then((res) => {
        console.log("HealthCheck", res);
        vState.hasError = false;
        setIsFetched(true);
        getUI();
      })
      .catch((e) => {
        console.log("error", e);
        vState.hasError = true;
      });
  };

  //return children;
  return !snap.hasError ? isFetched ? children : <LoadingCustom /> : <ServerNotRespond />;
});

export { ConfigFetcher };

const styles = {};
