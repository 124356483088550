/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { CardLoading, CustomLoader, NoResult, useNotification } from "ui/components";
import { ReportsCard } from "ui/views/Reports/components";
import { useSnapshot } from "valtio";

interface IProviderReportsTabProps {
  id: string;
}

const ProviderReportsTab: React.FC<IProviderReportsTabProps> = React.memo(({ id }) => {
  const snap = useSnapshot(vState);

  const [reportsLoading, setReportsLoading] = useState(true);
  const [reports, setReports] = useState<any[]>([]);
  const [reportsPage, setReportsPage] = useState(1);
  const [reportsCount, setReportsCount] = useState(0);

  const getParts = async () => {
    await ApiInstance.common
      .getServiceProviderReports(id, reportsPage)
      .then((res) => {
        console.log("reports", res);
        setReportsCount(res.data.meta.total);
        if (reportsPage > 1) {
          let data = [...reports, ...res.data.data];
          setReports(data);
          setReportsPage((prev) => prev + 1);
        } else {
          setReports(res.data.data);
        }
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "لیست گزارشات و تخلفات");
      })
      .finally(() => {
        setReportsLoading(false);
      });
  };

  useEffect(() => {
    setReportsLoading(true);
    getParts();
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && reportsCount > reports.length && reports.length !== 0 && !reportsLoading) {
      getParts();
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      {reportsLoading ? (
        <CardLoading height="131px" />
      ) : reports.length === 0 ? (
        <NoResult />
      ) : (
        reports.map((item, index) => {
          return <ReportsCard key={index} data={item} />;
        })
      )}
      {snap.isEndOfPage && !reportsLoading && reports.length !== 0 && (
        <div className="w-full flex justify-center">
          <CustomLoader />
        </div>
      )}
    </>
  );
});

export { ProviderReportsTab };
