/* eslint-disable */
import { ApiInstance, ConfigLink, tools, useChangeTitle, useFetchQueryString, useQueryString, vState } from "core";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Button, ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { SetBulkSmsStatusModal } from "./components";

interface IQueryStringTransfer {
  id: string;
}

const ProvidersBulkSmsDetail = ({}) => {
  const metaTags = useChangeTitle("ویراژ - جزئیات پیامک");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { id } = useFetchQueryString<IQueryStringTransfer>();

  const [detail, setDetail] = useState<any>({
    title: "",
    payload: "",
    status: "payment_succeeded",
    createdAt: "",
  });
  const [statusColor, setStatusColor] = useState("ts-warning");
  const [loading, setLoading] = useState(true);
  const [statusModal, setStatusModal] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [statusModalType, setStatusModalType] = useState<"approved" | "rejected">("approved");

  const getDetail = async () => {
    await ApiInstance.bulkSms
      .getHistoryDetail(id)
      .then((res) => {
        console.log(res);
        const item = res.data.data;
        setDetail(item);
        setStatusColor(
          item.status === "sent"
            ? "ts-success"
            : item.status === "rejected" || item.status === "payment_failed"
            ? "ts-secondary-red"
            : "ts-warning",
        );
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "گزارشات");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const setRequestStatusHandler = (type) => {
    setStatusModalType(type);
    setStatusModal(true);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={26} className="">
        <Breadcrumb
          links={[
            {
              name: "پیامک",
              active: false,
              link: ``,
            },
            {
              name: "پیامک‌های سرویس دهندگان",
              active: true,
              link: ConfigLink.bulkSmsList,
            },
            {
              name: "جزئیات",
              active: false,
              link: ``,
            },
          ]}
          className="mb-6"
        />
        <Text type="H3" className="ts-accent mb-4 text-right">
          جزئیات
        </Text>

        <div className="w-full flex flex-col justify-start items-start rounded-2xl ts-shadow p-4 mb-4 overflow-hidden">
          <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-24">
              سرویس دهنده
            </Text>
            {loading ? (
              <Skeleton className="w-16 -mb-0.5" />
            ) : (
              <Text
                onClick={() => {
                  if (detail && detail.workshop?.id) {
                    navigate({
                      pathname: ConfigLink.serviceProviderDetail,
                      search: useQueryString({ id: detail.workshop?.id || "" }),
                    });
                  }
                }}
                type="H6"
                className={`${detail && detail.workshop?.id ? "ts-primary cursor-pointer" : "ts-accent"}`}
              >
                {(detail && detail.workshop?.name) || "---"}
              </Text>
            )}
          </div>

          <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-24">
              عنوان پیام
            </Text>
            {loading ? (
              <Skeleton className="w-16 -mb-0.5" />
            ) : (
              <Text type="P4" className="ts-accent ts-sp-detail-item">
                {detail ? detail.title || "---" : "---"}
              </Text>
            )}
          </div>

          <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-24">
              تاریخ ثبت
            </Text>
            {loading ? (
              <Skeleton className="w-16 -mb-0.5" />
            ) : (
              <Text type="P4" className="ts-accent ts-sp-detail-item">
                {detail && detail.createdAt ? tools.convertDateToPersianDate(detail.createdAt, true) : "---"}
              </Text>
            )}
          </div>

          <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-24">
              وضعیت
            </Text>
            {loading ? (
              <Skeleton className="w-16 -mb-0.5" />
            ) : (
              <span className="flex justify-center items-center">
                {detail.status === "sent" ? (
                  <IoIosCheckmarkCircle size={16} className="ts-success" />
                ) : detail.status === "rejected" || detail.status === "payment_failed" ? (
                  <IoIosCloseCircle size={16} className="ts-secondary-red" />
                ) : (
                  <MdOutlineAccessTimeFilled size={16} className="ts-warning -mt-0.5" />
                )}
                <Text type="H6" className={`${statusColor} mr-1 pt-px`}>
                  {detail.status === "sent"
                    ? "ارسال شده"
                    : detail.status === "approved"
                    ? "در انتظار ارسال"
                    : detail.status === "rejected"
                    ? "رد شده"
                    : detail.status === "payment_succeeded"
                    ? "در صف انتشار"
                    : detail.status === "created"
                    ? "در انتظار پرداخت"
                    : "پرداخت ناموفق"}
                </Text>
              </span>
            )}
          </div>

          <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-24">
              تاریخ ارسال
            </Text>
            {loading ? (
              <Skeleton className="w-16" />
            ) : (
              <Text type="P4" className="ts-accent ts-sp-detail-item">
                {detail && detail.updatedAt ? tools.convertDateToPersianDate(detail.updatedAt, true) : "---"}
              </Text>
            )}
          </div>

          <Text type="H6" className="ts-gray-06 w-24 mb-4">
            گیرندگان
          </Text>
          {loading ? (
            <div className="w-full flex justify-start items-start flex-wrap mb-4 gap-2">
              {[1, 2, 3, 4, 5].map((item) => {
                return <Skeleton key={item} className="w-24 h-6 rounded-full" />;
              })}
            </div>
          ) : (
            <div className="w-full flex justify-start items-start flex-wrap mb-4 gap-2">
              {detail.contacts.map((item, index) => {
                if (index < 5) {
                  return (
                    <span className="flex justify-center text-white items-center rounded-full ts-bg-gray-08 px-4 py-1">
                      <Text type="HM" className="w-max">
                        {item.contactName || "بدون نام"}
                      </Text>
                    </span>
                  );
                }
              })}
              {detail.contacts.length > 5 && !showAllUsers && (
                <Text onClick={() => setShowAllUsers(true)} type="H6" className="ts-gray-06 pt-1.5">{`${
                  detail.contacts.length - 5
                }+ نفر دیگر`}</Text>
              )}
              {detail.contacts.length > 5 &&
                showAllUsers &&
                detail.contacts.map((item, index) => {
                  if (index > 4) {
                    return (
                      <span className="flex justify-center text-white items-center rounded-full ts-bg-gray-08 px-4 py-1 cursor-pointer">
                        <Text type="HM" className="w-max">
                          {item.contactName || "بدون نام"}
                        </Text>
                      </span>
                    );
                  }
                })}
            </div>
          )}

          <Text type="H6" className="ts-accent mb-2">
            متن پیام
          </Text>

          {loading ? (
            <Skeleton className="w-16 -mb-0.5" />
          ) : (
            <Text type="P4" className="ts-accent">
              {detail && detail.payload ? detail.payload : "---"}
            </Text>
          )}
        </div>

        {snap.superAdmin && (
          <div className="w-full flex justify-between items-center gap-1.5">
            <Button
              onClick={() => setRequestStatusHandler("approved")}
              bcolor="primary"
              disabled={detail && ["sent", "approved", "rejected"].includes(detail.status)}
              textType="H6"
              classNameContainer="w-full"
              className="w-full h-12 rounded-xl"
            >
              تایید ارسال
            </Button>

            <Button
              onClick={() => setRequestStatusHandler("rejected")}
              disabled={detail && ["sent", "approved", "rejected"].includes(detail.status)}
              bcolor="blackTransparent"
              textType="H6"
              classNameContainer="w-full"
              className="w-full h-12 rounded-xl"
            >
              رد پیامک
            </Button>
          </div>
        )}
      </ContainerScroll>

      <SetBulkSmsStatusModal
        isOpen={statusModal}
        onClose={() => setStatusModal(false)}
        onDone={() => {
          setLoading(true);
          getDetail();
        }}
        id={id}
        type={statusModalType}
      />
    </>
  );
};

export { ProvidersBulkSmsDetail as default };
