/* eslint-disable */
import { ApiInstance, tools, useObjectState, vState } from "core";
import React, { useEffect, useState } from "react";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { InputLicensePlate } from "./";

interface IEditPlateInfoProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}

const EditPlateInfo: React.FC<IEditPlateInfoProps> = React.memo(({ isOpen, onClose, data }) => {
  const snap = useSnapshot(vState);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [plateValue, setPlateValue] = useObjectState({
    firstSection: "",
    secondSection: "",
    thirdSection: "",
    letter: "ا",
  });
  const { firstSection, secondSection, thirdSection, letter } = plateValue;

  const [info, setInfo] = useObjectState({
    carName: "",
    mobile: "",
    nameFamily: "",
  });

  useEffect(() => {
    if (isOpen && data) {
      setPlateValue({
        firstSection: data.plateNumber.slice(0, 2),
        secondSection: data.plateNumber.substring(3, 6),
        thirdSection: data.plateNumber.substring(7, 9),
        letter: data.plateNumber.slice(2, 3),
      });
      setInfo({
        carName: data.carName || "",
        // mobile: data.mobile || "",
        nameFamily: data.ownerName || "",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const { carName, nameFamily } = info;
    const { firstSection, secondSection, thirdSection, letter } = plateValue;
    if (data) {
      const plateNumber = data.plateNumber;
      setDisabled(
        ((carName === data.carName || carName.length === 0) &&
          (nameFamily === (data.ownerName || "") || (data.ownerName !== null && info.nameFamily.length === 0)) &&
          firstSection === plateNumber.slice(0, 2) &&
          secondSection === plateNumber.substring(3, 6) &&
          thirdSection === plateNumber.substring(7, 9) &&
          letter === plateNumber.slice(2, 3)) ||
          firstSection.length !== 2 ||
          secondSection.length !== 3 ||
          thirdSection.length !== 2,
      );
    }
  }, [info, plateValue]);

  const registerClickHandler = async () => {
    setLoading(true);

    const sentData = {
      plateNumber: tools.getFormattedPlate(firstSection, letter, secondSection, thirdSection),
      ownerName: info.nameFamily,
      carName: info.carName,
    };
    await ApiInstance.plates
      .editPlate(data.id, sentData)
      .then((res) => {
        useNotification("اطلاعات تغییر یافت.", "success", "ویرایش اطلاعات خودرو");
        const index = snap.plates.findIndex((item) => item.id === data.id);
        vState.plates[index] = { ...snap.plates[index], ...sentData };
        onClose();
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است", "error", "ویرایش اطلاعات خودرو");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setPlateValues = (e, place) => {
    if (/^[\u06F0-\u06F9]*$/.test(e) || /^[0-9]*$/g.test(e)) {
      setPlateValue({ [place]: e });
    }
  };

  const checkStateValue = (state: string, operation: () => void) => {
    if (plateValue[state].length === 0) {
      operation();
    }
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full text-right">
        <Text type="H4" className="ts-accent mb-6">
          ویرایش اطلاعات خودرو
        </Text>

        <Text type="H6" className="ts-accent mb-2">
          شماره پلاک وارد شده
        </Text>
        {isOpen && (
          <InputLicensePlate
            plateValue={plateValue}
            setPlateValues={setPlateValues}
            checkStateValue={checkStateValue}
            letterChange={(value) => setPlateValue({ letter: value })}
          />
        )}

        <InputField
          label="خودرو"
          value={info.carName}
          onChange={(e) => setInfo({ carName: e })}
          placeholder="نام و مدل خودرو"
        />

        <InputField
          label="نام مالک"
          value={info.nameFamily}
          onChange={(e) => setInfo({ nameFamily: e })}
          placeholder="نام و نام خانوادگی"
        />

        <Button
          onClick={registerClickHandler}
          loading={loading}
          disabled={disabled}
          bcolor="primary"
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl"
        >
          ویرایش خودرو
        </Button>
      </div>
    </BottomModal>
  );
});

export { EditPlateInfo };

const styles = {};
