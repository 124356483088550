/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import React, { useEffect } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IInviteeCardProps {
  data: any;
}

const InviteeCard: React.FC<IInviteeCardProps> = React.memo(({ data }) => {
  useEffect(() => {}, []);
  const navigate = useNavigate();

  const seeDetailHandler = () => {
    if (data.userProfileId) {
      navigate({ pathname: ConfigLink.userDetail, search: useQueryString({ id: data.userProfileId }) });
    }
  };

  return (
    <div
      style={{ height: "76px" }}
      onClick={seeDetailHandler}
      className={`w-full ${
        data.id ? "bg-white" : "ts-bg-gray-02"
      } flex justify-between items-start ts-shadow rounded-2xl p-3 mb-4 relative`}
    >
      <div className={`flex flex-col w-full ${data.id ? "justify-between" : "justify-start"} items-start`}>
        <Text type="H6" className="ts-accent truncate mb-2">
          {data.name || "---"}
        </Text>
        <Text type="P4" className="ts-gray-06">
          {data.phoneNumber || "---"}
        </Text>
      </div>
      {data.userProfileId ? (
        <div className={`w-8 h-full ts-bg-gray-01 flex justify-center items-center rounded-xl`}>
          <BiChevronLeft size={24} className="ts-accent" />
        </div>
      ) : (
        <div className="bg-white absolute top-3 left-3 rounded-full flex justify-center items-center py-1 px-4">
          <Text type="HM" className="ts-gray-06">
            ثبت نام نکرده
          </Text>
        </div>
      )}
    </div>
  );
});

export { InviteeCard };

const styles = {};
