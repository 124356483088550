/* eslint-disable */
import { ApiInstance, useChangeTitle, vState } from "core";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, CardLoading, ContainerScroll, NoResult, Text, useNotification } from "ui/components";
import { AddEditVersion, VersionCard } from "./components";

type Applications = "service_provider" | "vehicle_owner";

export interface VersionData {
  id: string;
  versionName: string;
  versionCode: number;
  description: string;
  application: Applications;
}

const Versions = ({}) => {
  const metaTags = useChangeTitle("ویراژ - مدیریت بروز رسانی ها");

  const [versions, setVersions] = useState<VersionData[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<VersionData | null>(null);
  const [addEditModal, setAddEditModal] = useState(false);
  const [modalType, setModalType] = useState<"add" | "edit">("add");
  const [loading, setLoading] = useState(true);

  const getVersion = async () => {
    await ApiInstance.common
      .getVersions()
      .then((res) => {
        console.log(res);
        setVersions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "مدیریت بروزرسانی ها");
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getVersion();
  }, []);

  const editClickHandler = (item) => {
    setSelectedVersion(item);
    setModalType("edit");
    setTimeout(() => {
      setAddEditModal(true);
    }, 500);
  };

  const addNewVersionHandler = () => {
    setModalType("add");
    setTimeout(() => {
      setAddEditModal(true);
    }, 500);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={20} className="">
        <Text type="H3" className="ts-accent mb-6">
          مدیریت بروزرسانی ها
        </Text>

        <div className="w-full flex justify-between items-center mb-6">
          {loading ? (
            <Skeleton className="w-28" />
          ) : (
            <Text type="H6" className="ts-accent">
              {`${versions.length} نسخه`}
            </Text>
          )}
        </div>

        <Button
          bcolor="primaryTransparent"
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl mb-4"
          onClick={addNewVersionHandler}
        >
          تعریف بروزسانی جدید
        </Button>

        {loading ? (
          <CardLoading height="76px" />
        ) : versions.length === 0 ? (
          <NoResult />
        ) : (
          versions.map((item) => {
            return <VersionCard key={item.id} data={item} onEdit={() => editClickHandler(item)} />;
          })
        )}
      </ContainerScroll>
      <AddEditVersion
        isOpen={addEditModal}
        onClose={() => setAddEditModal(false)}
        onEnd={getVersion}
        type={modalType}
        data={selectedVersion}
        allVersions={versions}
      />
    </>
  );
};

export { Versions as default };
