/* eslint-disable */
import { configs } from "configs";
import { vState } from "core";
import React from "react";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import { Text } from "ui/components";
import { useSnapshot } from "valtio";

interface IPartCategoryCardProps {
  data: any;
  onEdit: () => void;
  onDelete: () => void;
}

const PartCategoryCard: React.FC<IPartCategoryCardProps> = React.memo(({ data, onEdit, onDelete }) => {
  const snap = useSnapshot(vState);

  return (
    <div
      key={data.id}
      className={`w-full h-12 rounded-2xl ts-shadow mb-2 p-3 bg-white flex justify-between items-center cursor-pointer relative`}
    >
      <div className="flex justify-center items-center">
        <div className="w-4 h-4 ml-2">
          {data.image && data.image.length !== 0 && (
            <img src={`${configs().imageUrl}/icons/${data.image}`} alt={data.id} className="object-cover" />
          )}
        </div>
        <Text type="P4" className="ts-accent pt-0.5">
          {data.name}
        </Text>
      </div>

      {snap.superAdmin && (
        <div className="flex justify-center items-center">
          <HiPencilAlt size={23} className="ts-primary ml-2 -rotate-90" onClick={onEdit} />
          <HiTrash size={23} className="ts-primary" onClick={onDelete} />
        </div>
      )}
    </div>
  );
});

export { PartCategoryCard };

const styles = {};
