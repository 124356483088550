// @ts-nocheck
/* eslint-disable */
import React from "react";

//Images:
import { SuspenseLoading } from "ui/_layouts/components";

const LoadingCustom = React.memo(() => {
  return <SuspenseLoading />;
});

export { LoadingCustom };
