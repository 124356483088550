/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { CardLoading, CustomLoader, FeedbacksCard, NoResult, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

interface IUserFeedbacksTabProps {
  id: string;
}

const UserFeedbacksTab: React.FC<IUserFeedbacksTabProps> = React.memo(({ id }) => {
  const snap = useSnapshot(vState);

  const [tabLoading, setTabLoading] = useState(true);
  const [comments, setComments] = useState<any[]>([]);
  const [commentsPage, setCommentsPage] = useState(1);
  const [commentsCount, setCommentsCount] = useState(0);

  const getComments = async () => {
    await ApiInstance.common
      .getFeedbacks(commentsPage, "DATE", null, null, id)
      .then((res) => {
        console.log("comments", res);
        setCommentsCount(res.data.meta.total);
        if (commentsPage > 1) {
          let data = [...comments, ...res.data.data];
          setComments(data);
          setCommentsPage((prev) => prev + 1);
        } else {
          setComments(res.data.data);
        }
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "نظرات");
      })
      .finally(() => {
        setTabLoading(false);
      });
  };

  useEffect(() => {
    getComments();
  }, [id]);

  useEffect(() => {
    if (snap.isEndOfPage && commentsCount > comments.length && !tabLoading) {
      setCommentsPage((prev) => prev + 1);
      getComments();
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      {tabLoading ? (
        <CardLoading height={"76px"} />
      ) : comments.length === 0 ? (
        <NoResult />
      ) : (
        comments.map((item, index) => {
          return <FeedbacksCard key={index} data={item} forUser />;
        })
      )}
      {snap.isEndOfPage && !tabLoading && commentsCount > comments.length && comments.length !== 0 && (
        <div className="w-full flex justify-center">
          <CustomLoader />
        </div>
      )}
    </>
  );
});

export { UserFeedbacksTab };

const styles = {};
