/* eslint-disable */
import { ApiInstance, useObjectState } from "core";
import React, { useEffect, useState } from "react";
import { Input, Radio, RadioGroup } from "rsuite";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";
import { VersionData } from "../Versions";

interface IAddEditVersionProps {
  isOpen: boolean;
  onClose: () => void;
  onEnd: () => void;
  data?: VersionData | null;
  type: "add" | "edit";
  allVersions: VersionData[];
}

const AddEditVersion: React.FC<IAddEditVersionProps> = React.memo(
  ({ isOpen, onClose, onEnd, data, type, allVersions }) => {
    const defaultValues: any = {
      versionCode: 0,
      versionName: "",
      description: "",
      application: "vehicle_owner",
    };

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [values, setValues] = useObjectState(defaultValues);
    const { versionName, versionCode, description, application } = values;

    useEffect(() => {
      setLoading(false);
      if (type === "add") {
        setValues(defaultValues);
      } else {
        setValues(data || defaultValues);
      }
    }, [isOpen]);

    useEffect(() => {
      setDisabled(versionName.length === 0 || description.length === 0);
    }, [values]);

    const addVersionHandler = async () => {
      const finalData = { ...values, versionCode: allVersions.length };
      console.log(finalData);

      await ApiInstance.common
        .addVersion(finalData)
        .then((res) => {
          console.log(res);
          useNotification("ورژن جدید اضافه شد", "success", "افزودن نسخه جدید");
          onEnd();
          setTimeout(() => {
            onClose();
          }, 800);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است.", "error", "افزودن نسخه جدید");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editVersionHandler = async () => {
      const id = data ? data.id : "";
      const finalData = {
        versionCode: versionCode,
        versionName: versionName,
        description,
        application,
      };
      await ApiInstance.common
        .updateVersion(id, finalData)
        .then((res) => {
          console.log(res);
          useNotification("تغییرات اعمال شد.", "success", "ویرایش نسخه");
          onEnd();
          setTimeout(() => {
            onClose();
          }, 800);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است.", "error", "ویرایش نسخه");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const buttonClickHandler = () => {
      setLoading(true);

      type === "add" ? addVersionHandler() : editVersionHandler();
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent mb-8">
            {type === "add" ? "تعریف نسخه جدید" : "ویرایش نسخه"}
          </Text>

          <InputField
            label="شماره نسخه"
            value={versionName}
            onChange={(e) => setValues({ versionName: e })}
            placeholder="مثلا: 1.5.6"
          />

          <Text type="H6" className="ts-accent mb-2">
            جزئیات نسخه
          </Text>
          <Input
            as="textarea"
            value={description}
            onChange={(e) => setValues({ description: e })}
            rows={4}
            className={`w-full transition-all whitespace-pre-wrap duration-200 rounded-xl mb-6 outline-none placeholder:text-gray-300`}
            placeholder="تغییرات اعمال شده در نسخه جدید"
          />
          <Text type="H6" className="ts-accent mb-2">
            پلتفرم نسخه
          </Text>
          <div className="w-full flex justify-start items-start ts-version-radio">
            <RadioGroup name="platform" inline value={application} onChange={(e) => setValues({ application: e })}>
              <Radio value="vehicle_owner"> مالک خودرو</Radio>
              <Radio value="service_provider"> سرویس دهنده</Radio>
            </RadioGroup>
          </div>
          <Button
            onClick={buttonClickHandler}
            loading={loading}
            disabled={disabled}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12 mt-8"
          >
            {type === "add" ? "تعریف نسخه جدید" : "اعمال تغییرات"}
          </Button>
        </div>
      </BottomModal>
    );
  },
);

export { AddEditVersion };

const styles = {};
