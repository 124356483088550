/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { CardLoading, CustomLoader, NoResult, useNotification } from "ui/components";
import { PlateCard } from "ui/views/Plates/components";
import { useSnapshot } from "valtio";

interface IProviderAdmissionsTabProps {
  id: string;
}

const ProviderAdmissionsTab: React.FC<IProviderAdmissionsTabProps> = React.memo(({ id }) => {
  const snap = useSnapshot(vState);

  const [admissionLoading, setAdmissionsLoading] = useState(true);
  const [cars, setCars] = useState<any[]>([]);
  const [carsPage, setCarsPage] = useState(0);
  const [carsCount, setCarsCount] = useState(0);

  const getAdmissions = async (pageIncreased: boolean) => {
    await ApiInstance.common
      .getServiceProviderPlates(id, carsPage + 1)
      .then((res) => {
        console.log(res);
        setCarsCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...cars, ...res.data.data];
          setCars(data);
          setCarsPage((prev) => prev + 1);
        } else {
          setCars(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "تاریخچه خودرو");
      })
      .finally(() => {
        setAdmissionsLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    setAdmissionsLoading(true);
    getAdmissions(false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && carsCount > cars.length && cars.length !== 0 && !admissionLoading) {
      getAdmissions(true);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      {admissionLoading ? (
        <CardLoading height="228px" />
      ) : cars.length === 0 ? (
        <NoResult note="خودروی پذیرش شده ای وجود ندارد." />
      ) : (
        cars.map((item, index) => {
          return <PlateCard key={index} data={item} forAdmission />;
        })
      )}
      {snap.isEndOfPage && !admissionLoading && cars.length !== 0 && (
        <div className="w-full flex justify-center">
          <CustomLoader />
        </div>
      )}
    </>
  );
});

export { ProviderAdmissionsTab };
