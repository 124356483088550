/* eslint-disable */
import React, { useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import { BsCircleFill } from "react-icons/bs";
import { HiOutlineAdjustments, HiOutlineLocationMarker } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { Form, Input, InputGroup, Loader } from "rsuite";
import { Button, Text } from "ui/components";

interface ISearchMapHeaderProps {
  onGetLocation: () => void;
  locationLoading: boolean;
  searchText: string;
  loading: boolean;
  searchChanged: (e: string) => void;
  resultSelected: (item: any) => void;
  searchClicked: () => void;
  selectedSearchResult: any | null;
  searchResults: any[] | null;
  onReset: () => void;
  closeSearchBox: () => void;
  filter: string[];
  filterOpen: () => void;
}

const SearchMapHeader: React.FC<ISearchMapHeaderProps> = React.memo(
  ({
    onGetLocation,
    locationLoading,
    searchText,
    searchResults,
    resultSelected,
    selectedSearchResult,
    loading,
    searchChanged,
    searchClicked,
    onReset,
    closeSearchBox,
    filter,
    filterOpen,
  }) => {
    useEffect(() => {}, []);

    const condition =
      (searchResults && searchResults.length !== 0 && selectedSearchResult === null) ||
      (searchResults && searchResults.length === 0 && searchText.length !== 0);

    return (
      <>
        {condition && (
          <div
            onClick={closeSearchBox}
            className="w-full h-screen bg-black opacity-30 absolute top-0 left-0 z-40"
          ></div>
        )}
        <div className="fixed right-6 left-6 max-2xs:right-3 max-2xs:left-3 z-50 flex flex-col justify-start items-start ts-search-header">
          <div className="w-full flex justify-between items-center">
            <Form fluid onSubmit={searchClicked} className={`w-5/60 w-full transition-all ts-shadow rounded-xl`}>
              <InputGroup inside className="w-full h-12 outline-none bg-white ts-input-border">
                <InputGroup.Button
                  onClick={onReset}
                  className={`${
                    searchText.length === 0 ? "hidden" : ""
                  } left-9 h-full bg-transparent hover:bg-transparent mr-7`}
                >
                  <IoMdClose size={19} className={`transition-colors duration-200 -mt-1`} />
                </InputGroup.Button>
                {loading ? (
                  <Loader size="xs" style={{ top: "15px" }} className="absolute left-4 z-20" />
                ) : (
                  <InputGroup.Button type="submit" className="h-full left-0 bg-transparent hover:bg-transparent">
                    <AiOutlineSearch size={23} className={`ts-gray-03 transition-colors duration-200 -mt-1`} />
                  </InputGroup.Button>
                )}
                <Input
                  value={searchText}
                  onChange={searchChanged}
                  className="h-full bg-white outline-none border-none rounded-2xl placeholder:text-gray-200"
                  placeholder="جستجوی استان، شهر، منطقه..."
                />
              </InputGroup>
            </Form>
            <div
              onClick={filterOpen}
              className="w-12 h-12 mr-2 rounded-xl ts-shadow bg-white cursor-pointer p-2 flex justify-center items-center relative"
            >
              <HiOutlineAdjustments size={22} className="ts-accent" />
              {filter.length !== 0 && <BsCircleFill size={13} className="ts-primary absolute -top-1 -left-1" />}
            </div>
          </div>

          <Button
            bcolor="white"
            onClick={onGetLocation}
            icon={<BiCurrentLocation size={28} className="ts-primary ts-my-location-icon" />}
            loading={locationLoading}
            classNameContainer="w-12 h-12 ts-shadow mt-4"
            className="w-full h-full rounded-xl flex justify-center items-center bg-white ts-shadow z-40"
          ></Button>
        </div>

        {condition && (
          <div className="fixed ts-search-result-container right-6 left-6 max-2xs:right-3 max-2xs:left-3 ts-search-header max-h-72 z-50 ts-hide-scroll-but-keep-working overflow-auto flex flex-col p-4 justify-start items-start rounded-xl ts-shadow bg-white mt-14">
            {searchResults.length === 0 ? (
              <Text type="P4" className="ts-gray-08 m-auto">
                نتیجه ای یافت نشد
              </Text>
            ) : (
              searchResults.map((item, index) => {
                return (
                  <>
                    <div
                      onClick={() => resultSelected(item)}
                      className="w-full flex justify-start items-center cursor-pointer"
                    >
                      <HiOutlineLocationMarker size={22} className="ts-accent ml-3" />
                      <Text type="P4" className="ts-map-search-result">
                        {item.title}
                      </Text>
                    </div>
                    {index !== searchResults.length - 1 && <hr className="w-full h-px my-4" />}
                  </>
                );
              })
            )}
          </div>
        )}
      </>
    );
  },
);

export { SearchMapHeader };

const styles = {};
