/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import {
  ApiInstance,
  ConfigLink,
  useChangeTitle,
  useEffectNoInitial,
  useFetchQueryString,
  useObjectState,
  useQueryString,
  vState,
} from "core";
import { useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import { HiOutlineAdjustments, HiOutlineDownload } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { SelectPicker } from "rsuite";
import {
  CardLoading,
  ContainerScroll,
  CustomLoader,
  DownloadPromptModal,
  NoResult,
  SearchInput,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";
import { PlateCard, PlatesFilterModal } from "./components";

const env = import.meta.env;

type SORT_TYPE = "NON" | "DATE" | "NAME" | "OWNER";

interface ISelectedCar {
  id: string;
  name: string;
}

interface IQueryStringTransfer {
  q?: string;
  cars?: ISelectedCar;
  usage?: any;
  dates?: any[];
}

const Plates = ({}) => {
  const metaTags = useChangeTitle("ویراژ - خودروهای ثبت شده");
  let { q, cars, usage, dates } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const history = useNavigate();

  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [downloadModal, setDownloadModal] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("DATE");

  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useObjectState({
    cars: null,
    monthlyUsage: "",
    date: [],
  });

  const getPlates = async (sort: SORT_TYPE, pageIncreased: boolean, query?: string | null, filterObj?: any) => {
    if (!pageIncreased) setPageCount(0);
    const filter = {
      cars: filterObj ? filterObj.cars : cars || "",
      monthlyUsage: filterObj ? filterObj.monthlyUsage : usage || "",
      dates: filterObj ? filterObj.date : dates || [],
    };

    console.log(filter);

    await ApiInstance.common
      .getCars("data", pageCount + 1, sort, query || null, filter)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...snap.plates, ...res.data.data];
          vState.plates = data;
          setPageCount((prev) => prev + 1);
        } else {
          vState.plates = res.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "خودروهای ثبت شده");
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  const downloadHandler = async () => {
    const filter = {
      cars: cars || "",
      monthlyUsage: usage || "",
      date: dates || [],
    };
    const result = await ApiInstance.common
      .getCars("export", 1, null, q || null, filter)
      .then((res) => {
        console.log(res);
        return {
          data: res,
          name: "plates",
        };
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "دانلود خودروهای ثبت شده");
        return null;
      });

    return result;
  };

  useEffect(() => {
    const filterObj: any = {
      cars: cars || null,
      monthlyUsage: usage || "",
      date: dates || [],
    };
    setFilters(filterObj);
    if (q) {
      setSearch(q);
      setSearchText(q);
    } else {
      getPlates(sortReminders, false, q || null);
    }
  }, []);

  useEffectNoInitial(() => {
    setLoading(true);
    console.log(search, cars || filters.cars, dates || filters.date, usage || filters.monthlyUsage);
    updateURL(search, cars || filters.cars, dates || filters.date, usage || filters.monthlyUsage);
    getPlates(sortReminders, false, search);
  }, [search]);

  useEffect(() => {
    if (snap.isEndOfPage && count > snap.plates.length && !loading) {
      getPlates(sortReminders, true, q || searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    setLoading(true);
    getPlates(value, false, q || searchText);
  };

  const handleChange = (e) => {
    setSearchText(digitsFaToEn(e));
  };

  const resetSearch = () => {
    setSearchText("");
    setSearch("");
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب مالک",
      value: "OWNER",
    },
    {
      label: "به ترتیب خودرو",
      value: "NAME",
    },
  ];

  const getFilterData = (filter) => {
    setFilters(filter);
    console.log("func", filter);
    updateURL(q || searchText, filter.cars || null, filter.date, filter.monthlyUsage);
    setLoading(true);
    getPlates(sortReminders, false, q || searchText, filter);
  };

  const updateURL = (
    search: string | null,
    cars: ISelectedCar | null,
    formattedDate: string[] | null,
    usage: string | null,
  ) => {
    history(
      {
        pathname: ConfigLink.plates,
        search: useQueryString({
          q: search || undefined,
          cars: cars ? { id: cars?.id, name: cars?.name } : undefined,
          dates: formattedDate || undefined,
          usage: usage || undefined,
        }),
      },
      { replace: true },
    );
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={26} className="">
        <div className="w-full fixed top-14 pt-6 bg-white z-40 ts-fixed-header">
          <div className="w-full flex justify-between items-center mb-6">
            <Text type="H3" className="ts-accent">
              خودرو های ثبت شده
            </Text>
            <div className="flex justify-center items-center">
              <div
                onClick={() => setDownloadModal(true)}
                className="w-10 h-10 p-2 mr-2 rounded-xl ts-shadow bg-white cursor-pointer flex justify-center items-center"
              >
                <HiOutlineDownload size={22} className="ts-accent mr-px" />
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between items-center mb-6 gap-2">
            <SearchInput
              value={searchText}
              onChange={handleChange}
              onReset={resetSearch}
              onSearch={() => setSearch(searchText)}
              disabled={loading}
              placeholder="جستجو بر اساس پلاک، مالک و خودرو"
            />

            <div
              onClick={() => setFilterOpen(true)}
              className="w-12 h-12 rounded-xl ts-shadow bg-white cursor-pointer p-2 flex justify-center items-center relative"
            >
              <HiOutlineAdjustments size={22} className="ts-accent" />
              {(filters.cars || filters.date.length === 2 || filters.monthlyUsage.length !== 0) && (
                <BsCircleFill size={10} className="ts-primary absolute -top-1 -left-1" />
              )}
            </div>
          </div>
        </div>

        <div className="w-full ts-container-margin">
          <div className="w-full flex justify-between items-center mb-6">
            {loading ? (
              <Skeleton className="w-28" />
            ) : (
              <Text type="H6" className="ts-accent">
                {`${count} خودرو`}
              </Text>
            )}
            <SelectPicker
              size="lg"
              data={sortData}
              value={sortReminders}
              disabled={loading}
              cleanable={false}
              onChange={(value: any) => sortChangeHandler(value)}
              searchable={false}
              placeholder="مرتب سازی"
              className={`w-36 h-12 outline-none rounded-xl`}
            />
          </div>

          {loading ? (
            <CardLoading height="228px" />
          ) : snap.plates.length === 0 ? (
            <NoResult forSearch={searchText.length !== 0} />
          ) : (
            snap.plates.map((item, index) => {
              return <PlateCard key={index} data={item} />;
            })
          )}
          {snap.isEndOfPage && !loading && (
            <div className="w-full flex justify-center">
              <CustomLoader />
            </div>
          )}
        </div>
      </ContainerScroll>

      <PlatesFilterModal
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onFilterChanged={getFilterData}
        filter={filters}
      />

      <DownloadPromptModal
        isOpen={downloadModal}
        onClose={() => setDownloadModal(false)}
        onApprove={downloadHandler}
        title="خودروهای ثبت شده"
      />
    </>
  );
};

export { Plates as default };
