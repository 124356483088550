/* eslint-disable */
import { ApiInstance, useChangeTitle, vState } from "core";
import { useEffect, useState } from "react";
import { Breadcrumb, Button, CardLoading, ContainerScroll, NoResult, Text } from "ui/components";
import { useSnapshot } from "valtio";
import { AddEditBusinessType, ProviderFeatureCard } from "./components";

const ProviderFeatures = ({}) => {
  const metaTags = useChangeTitle("ویراژ - انواع کسب و کار");
  const snap = useSnapshot(vState);

  const [addEditBusinessShow, setAddEditBusinessShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalType, setModalType] = useState<"add" | "edit">("add");

  const [loading, setLoading] = useState(true);

  const getUI = async () => {
    await ApiInstance.common.getUI();
  };

  useEffect(() => {
    if (snap.categories.length === 0) {
      getUI();
    }
    setLoading(false);
  }, []);

  const addItemHandler = () => {
    setSelectedItem(null);
    setModalType("add");
    setAddEditBusinessShow(true);
  };

  const editItemHandler = (item, index) => {
    setSelectedItem(item);
    setModalType("edit");
    setAddEditBusinessShow(true);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={110} className="">
        <Breadcrumb
          links={[
            {
              name: "تنظیمات پایه",
              active: false,
              link: "",
            },
            {
              name: "انواع کسب و کار",
              active: false,
              link: ``,
            },
          ]}
          className="mb-8"
        />
        <div className="w-full flex justify-between items-center mb-6">
          <Text type="H3" className="ts-accent">
            انواع کسب و کار
          </Text>
        </div>

        {loading ? (
          <CardLoading height={"78px"} />
        ) : snap.categories.length === 0 ? (
          <NoResult />
        ) : (
          snap.categories.map((item, index) => {
            const featureList =
              item.features && item.features.length !== 0
                ? item.features
                    .map((item) => {
                      const match = featuresList.find((obj) => obj.value === item);
                      return match ? match.label : null;
                    })
                    .filter(Boolean)
                    .join("، ")
                : "---";

            return (
              <ProviderFeatureCard
                key={index}
                data={item}
                featureList={featureList}
                onEdit={() => editItemHandler(item, index)}
              />
            );
          })
        )}

        <Button
          onClick={addItemHandler}
          textType="H6"
          bcolor="primary"
          classNameContainer="w-full"
          className="ts-float-button h-12 rounded-xl"
        >
          اضافه کردن کسب و کار جدید
        </Button>
      </ContainerScroll>
      <AddEditBusinessType
        isOpen={addEditBusinessShow}
        onClose={() => setAddEditBusinessShow(false)}
        type={modalType}
        selectedItem={selectedItem}
        featuresList={featuresList}
      />
    </>
  );
};

const featuresList = [
  {
    label: "پذیرش و ترخیص",
    value: "admission_discharge",
  },
  {
    label: "تعریف یادآور",
    value: "add_reminders",
  },
  {
    label: "مدیریت کاربران",
    value: "user_management",
  },
  {
    label: "لیست مشتریان",
    value: "contact_list",
  },
  {
    label: "پیامک انبوه",
    value: "bulk_sms",
  },
  {
    label: "فروشگاه قطعات یدکی",
    value: "shop",
  },
  {
    label: "فروشگاه تجهیزات",
    value: "equipment",
  },
  {
    label: "درخواست قطعات",
    value: "part_order",
  },
  {
    label: "مشاهده لیست قطعات درخواستی",
    value: "part_order_list",
  },
  {
    label: "جستجوی قطعات یدکی",
    value: "shop_search",
  },
  {
    label: "جستجوی تجهیزات",
    value: "equipment_search",
  },
  {
    label: "جستجوی کسب و کار",
    value: "workshop_search",
  },
];

export { ProviderFeatures as default };
