/* eslint-disable */
import { configs } from "#configs";
import React from "react";
import { HiChevronLeft } from "react-icons/hi";
import { Text } from "../../../components";

interface ICarBrandCardProps {
  data: any;
  onClick: () => void;
}

const CarBrandCard: React.FC<ICarBrandCardProps> = React.memo(({ data, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`w-full h-14 rounded-2xl ts-shadow mb-2 p-3 bg-white flex justify-between items-center cursor-pointer relative`}
    >
      <div className="flex justify-center items-center">
        <div className="w-6 h-6 ml-2">
          {data.image && data.image.length !== 0 && (
            <img src={`${configs().imageUrl}/icons/${data.image}`} alt={data.name || "img"} className="object-cover" />
          )}
        </div>
        <Text type="P4" className="ts-accent pt-0.5">
          {data.name || "نام تعریف نشده است"}
        </Text>
      </div>

      <HiChevronLeft size={24} className="ts-accent" />
    </div>
  );
});

export { CarBrandCard };

const styles = {};
