export const carMonthlyUsage = [
  {
    label: "۱۰,۰۰۰+ کیلومتر - خیلی پرکار",
    value: "VeryHigh",
  },
  {
    label: "۵۰۰۰ تا ۱۰,۰۰۰ کیلومتر - پرکار",
    value: "High",
  },
  {
    label: "۱۰۰۰ تا ۵۰۰۰ کیلومتر - متوسط",
    value: "Normal",
  },
  {
    label: "کمتر از ۱۰۰۰ کیلومتر - کم کار",
    value: "Low",
  },
  {
    label: "نمی دانم",
    value: "IDK",
  },
];

export const fuelTypes = [
  {
    label: "بنزین",
    value: "petrol",
  },
  {
    label: "گازوئیل",
    value: "gasoline",
  },
  {
    label: "دوگانه سوز(CNG)",
    value: "cng",
  },
  {
    label: "دوگانه سوز(LPG)",
    value: "lpg",
  },
  {
    label: "هیبرید",
    value: "hybrid",
  },
];
