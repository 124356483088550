/* eslint-disable */
import React, { useEffect, useState } from "react";
import { HiChevronDown, HiPencilAlt } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { Text } from "../../../components";

interface ICarModelsCardProps {
  data: any;
  editClicked: () => void;
}

const CarModelsCard: React.FC<ICarModelsCardProps> = React.memo(({ data, editClicked }) => {
  const history = useNavigate();
  const auth = useAuth();
  const snap = useSnapshot(vState);

  const [cardOpen, setCardOpen] = useState(false);

  useEffect(() => {}, []);

  return (
    <div
      key={data.id}
      className={`w-full ${
        cardOpen ? "ts-car-model-open" : "ts-plate-box-close h-14"
      } rounded-2xl ts-shadow mb-2 p-4 bg-white flex flex-col justify-start items-start relative`}
    >
      <div className="w-full flex justify-between items-center">
        <div
          onClick={() => {
            if (data.children.length !== 0) {
              setCardOpen((prev) => !prev);
            }
          }}
          className="flex justify-center items-center cursor-pointer"
        >
          <Text type="P4" className="ts-accent pt-0.5">
            {data.name || "نام تعریف نشده است"}
          </Text>
          {data.children.length !== 0 && <HiChevronDown size={24} className="ts-accent ml-2" />}
        </div>

        {!data.name.includes("همه") && snap.superAdmin && (
          <div onClick={editClicked} className="flex justify-center items-center z-10 cursor-pointer">
            <HiPencilAlt size={20} className="ts-primary ml-1" />
            <Text type="H6" className="ts-primary pt-0.5">
              ویرایش
            </Text>
          </div>
        )}
      </div>

      {data.children.map((child, index) => {
        return (
          <>
            <hr className={`w-full my-4 ${index === 0 ? "mt-5" : ""} ts-bg-gray-02 opacity-20`} />
            <Text type="P4" className="ts-accent pt-0.5">
              {child.name || "نام تعریف نشده است"}
            </Text>
          </>
        );
      })}
    </div>
  );
});

export { CarModelsCard };

const styles = {};
