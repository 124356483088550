/* eslint-disable */
import Skeleton from "react-loading-skeleton";
import { Text } from "ui/components";
import { PartListCardLoader } from "./";

const PartsListLoader = ({}) => {
  return (
    <>
      <Text type="H4" className="ts-accent mb-4">
        قطعات
      </Text>
      <div className="w-full flex justify-between items-center mb-3 -mt-0.5 gap--2">
        <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl" />
        {/* <Skeleton className="w-14 h-12 rounded-xl" /> */}
      </div>

      <div className="w-full flex justify-between items-center mb-4">
        <Skeleton className="w-28" />
        <Skeleton className="w-36 h-12 outline-none rounded-xl" />
      </div>

      <PartListCardLoader />
    </>
  );
};

export { PartsListLoader };
