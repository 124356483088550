/* eslint-disable */
import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CustomProvider } from "rsuite";
import OwnerFeatures from "ui/views/BasicSettings/OwnerFeatures";
import ProviderFeatures from "ui/views/BasicSettings/ProviderFeatures";
import ProvidersBulkSms from "ui/views/BulkSMS/ProvidersBulkSms";
import ProvidersBulkSmsDetail from "ui/views/BulkSMS/ProvidersBulkSmsDetail";
import CarBrandDetail from "ui/views/CarsList/CarBrandDetail";
import CarBrandTypes from "ui/views/CarsList/CarBrandTypes";
import CitiesList from "ui/views/Cities/CitiesList";
import Introduction from "ui/views/Introductions/Introduction";
import PartCategories from "ui/views/Parts/PartCategories";
import PartDetail from "ui/views/Parts/PartDetail";
import PartRequestDetail from "ui/views/Parts/PartRequestDetail";
import PartRequests from "ui/views/Parts/PartRequests";
import PartsList from "ui/views/Parts/PartsList";
import PlateDetail from "ui/views/Plates/PlateDetail";
import PlateHistoryDetail from "ui/views/Plates/PlateHistoryDetail";
import PlateReminderDetail from "ui/views/Plates/PlateReminderDetail";
import Plates from "ui/views/Plates/Plates";
import ReminderDetail from "ui/views/Reminders/ReminderDetail";
import Reminders from "ui/views/Reminders/Reminders";
import Feedbacks from "ui/views/Reports/Feedbacks";
import Reports from "ui/views/Reports/Reports";
import ServiceProviderDetail from "ui/views/ServiceProviders/ServiceProviderDetail";
import ServiceProviderFeedbacks from "ui/views/ServiceProviders/ServiceProviderFeedbacks";
import ServiceProviders from "ui/views/ServiceProviders/ServiceProviders";
import ServiceProvidersActivity from "ui/views/ServiceProviders/ServiceProvidersActivity";
import ServiceProvidersOnMap from "ui/views/ServiceProviders/ServiceProvidersOnMap";
import ViewLocation from "ui/views/ServiceProviders/ViewLocation";
import UserDetail from "ui/views/Users/UserDetail";
import Users from "ui/views/Users/Users";
import Versions from "ui/views/Versions/Versions";
import { AuthProvider } from "./auth";
import { ConfigLink, vState } from "./core";
import { AppProvider } from "./core/contexts/context.app";
import { ConfigFetcher, Guard } from "./ui/components";
import { LoadingCustom } from "./ui/components/LoadingPage";

const env = import.meta.env;
//================= [Solo Pages] =================
const ErrorPage = React.lazy(() => import("./ui/views/ErrorPages/ErrorPage"));

//Layouts
const MainPagesLayout = React.lazy(() => import("./ui/_layouts/MainPagesLayout"));
const SubPagesLayout = React.lazy(() => import("./ui/_layouts/SubPagesLayout"));

const App = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  useEffect(() => {
    vState.isMobileDevice = isMobile;
  }, []);

  useEffect(() => {
    vState.isMobileDevice = isMobile;
  }, [isMobile]);

  const router = createBrowserRouter([
    // main
    {
      element: (
        <Guard forceAuthenticated>
          <MainPagesLayout />
        </Guard>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: ConfigLink.homePage,
          element: <Introduction />,
        },
        {
          path: ConfigLink.reminders,
          element: <Reminders />,
        },
        {
          path: ConfigLink.serviceProviders,
          element: <ServiceProviders />,
        },
        {
          path: ConfigLink.serviceProvidersActivity,
          element: <ServiceProvidersActivity />,
        },
        {
          path: ConfigLink.plates,
          element: <Plates />,
        },
        {
          path: ConfigLink.usersList,
          element: <Users />,
        },
        {
          path: ConfigLink.reports,
          element: <Reports />,
        },
        {
          path: ConfigLink.feedbacks,
          element: <Feedbacks />,
        },
        {
          path: ConfigLink.versions,
          element: <Versions />,
        },
        {
          path: ConfigLink.providerFeatures,
          element: <ProviderFeatures />,
        },
        {
          path: ConfigLink.ownerFeatures,
          element: <OwnerFeatures />,
        },
        {
          path: ConfigLink.partRequests,
          element: <PartRequests />,
        },
        {
          path: ConfigLink.partsList,
          element: <PartsList />,
        },
        {
          path: ConfigLink.partCategories,
          element: <PartCategories />,
        },
        {
          path: ConfigLink.carBrandAndTypes,
          element: <CarBrandTypes />,
        },
        {
          path: ConfigLink.cities,
          element: <CitiesList />,
        },
        {
          path: ConfigLink.bulkSmsList,
          element: <ProvidersBulkSms />,
        },
      ],
    },
    // sub
    {
      element: (
        <Guard forceAuthenticated>
          <SubPagesLayout />
        </Guard>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: ConfigLink.reminderDetail,
          element: <ReminderDetail />,
        },
        {
          path: ConfigLink.serviceProviderDetail,
          element: <ServiceProviderDetail />,
        },
        {
          path: ConfigLink.serviceProviderFeedbacks,
          element: <ServiceProviderFeedbacks />,
        },
        {
          path: ConfigLink.plateDetail,
          element: <PlateDetail />,
        },
        {
          path: ConfigLink.plateHistory,
          element: <PlateHistoryDetail />,
        },
        {
          path: ConfigLink.plateReminderDetail,
          element: <PlateReminderDetail />,
        },
        {
          path: ConfigLink.userDetail,
          element: <UserDetail />,
        },
        {
          path: ConfigLink.partRequestDetail,
          element: <PartRequestDetail />,
        },
        {
          path: ConfigLink.partDetail,
          element: <PartDetail />,
        },
        {
          path: ConfigLink.brandDetail,
          element: <CarBrandDetail />,
        },
        {
          path: ConfigLink.bulkSmsDetail,
          element: <ProvidersBulkSmsDetail />,
        },
      ],
    },
    {
      path: ConfigLink.serviceProviderLocation,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <ViewLocation />
        </Guard>
      ),
    },
    {
      path: ConfigLink.serviceProvidersOnMap,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <ServiceProvidersOnMap />
        </Guard>
      ),
    },
  ]);

  return (
    <AuthProvider>
      <AppProvider>
        <CustomProvider>
          <React.Suspense fallback={<LoadingCustom />}>
            <ConfigFetcher>
              <RouterProvider router={router} />
            </ConfigFetcher>
          </React.Suspense>
        </CustomProvider>
      </AppProvider>
    </AuthProvider>
  );
};

export default App;
