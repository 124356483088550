/* eslint-disable */
import React, { useEffect, useState } from "react";
import { HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface IDeleteWorkshopModalProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  onEnd?: () => void;
}

const DeleteWorkshopModal: React.FC<IDeleteWorkshopModalProps> = React.memo(({ id, isOpen, onClose, onEnd }) => {
  const history = useNavigate();
  const auth = useAuth();
  const snap = useSnapshot(vState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const deleteWorkshop = async () => {
    await ApiInstance.common
      .deleteWorkshop(id)
      .then((res) => {
        console.log(res);
        useNotification("کسب و کار با موفقیت حذف شد.", "success", "حذف کسب و کار");
        onEnd && onEnd();
        setTimeout(() => {
          onClose();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        //TEMP
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "حذف کسب و کار");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteBtnHandler = () => {
    setLoading(true);
    deleteWorkshop();
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <div className="flex justify-end items-center mb-6">
          <HiTrash size={21} color="#CA2525" className="ts-secondary-red -mt-1" />
          <Text type="H4" className="ts-secondary-red mr-2">
            {/* //TEMP */}
            حذف کسب و کار
          </Text>
        </div>
        <Text type="P3" className="ts-accent mb-8">
          {`آیا مایلید که این کسب و کار حذف شود؟ پس از حذف، اطلاعات این کسب و کار قابل بازیابی نخواهد بود.`}
        </Text>
        <div className=" w-full flex justify-between items-center">
          <Button
            onClick={deleteBtnHandler}
            loading={loading}
            bcolor="black"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            بله، حذف شود
          </Button>

          <Button
            onClick={() => onClose()}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            خیر
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { DeleteWorkshopModal };

const styles = {};
