/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import React, { useEffect } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IPlateReminderCardProps {
  data: any;
  currentKilometer?: number;
  plateId?: string;
}

const PlateReminderCard: React.FC<IPlateReminderCardProps> = React.memo(({ data, currentKilometer, plateId }) => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const seeDetailHandler = () => {
    navigate({
      pathname: ConfigLink.plateReminderDetail,
      search: useQueryString({ id: data.id || "", currentKilometer, plateId }),
    });
  };

  return (
    <div
      onClick={seeDetailHandler}
      style={{ height: "72px" }}
      className={`w-full bg-white ts-shadow rounded-2xl p-4 mb-4 flex justify-center items-center`}
    >
      <div className="w-full flex justify-between items-center">
        <Text type="H5" className="ts-gray-08">
          {data.service.name || "---"}
        </Text>

        <BiChevronLeft size={24} className="ts-accent" />
      </div>
    </div>
  );
});

export { PlateReminderCard };

const styles = {};
