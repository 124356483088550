/* eslint-disable */
import { tools } from "core";
import React from "react";
import { Text } from "ui/components";

interface IPartRequestDetailRequesterInfoProps {
  data: any;
  userClicked: () => void;
}

const PartRequestDetailRequesterInfo: React.FC<IPartRequestDetailRequesterInfoProps> = React.memo(
  ({ data, userClicked }) => {
    return (
      <div className="w-full rounded-2xl ts-shadow p-4 mb-6 flex flex-col justify-start items-start">
        <div className="w-full flex justify-between items-center mb-4">
          <Text type="H5" className="ts-accent ">
            اطلاعات درخواست دهنده
          </Text>
        </div>
        <div className="w-full flex justify-start items-start mb-4">
          <Text type="H6" className="ts-gray-06 w-28 ml-2">
            نام و نام خانوادگی
          </Text>
          <Text type="H6" onClick={userClicked} className="ts-primary cursor-pointer">
            {data.createdBy?.name || "---"}
          </Text>
        </div>

        <div className="w-full flex justify-start items-start mb-4">
          <Text type="H6" className="ts-gray-06 w-28 ml-2">
            شماره تماس
          </Text>
          <Text type="P4" className="ts-accent">
            {data.createdBy?.phoneNumber || "---"}
          </Text>
        </div>

        <div className="w-full flex justify-start items-start">
          <Text type="H6" className="ts-gray-06 w-28 ml-2">
            تاریخ
          </Text>
          <Text type="P4" className="ts-accent">
            {data.createdAt ? tools.convertDateToPersianDate(data.createdAt, true) : "---"}
          </Text>
        </div>
      </div>
    );
  },
);

export { PartRequestDetailRequesterInfo };

const styles = {};
