/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import React, { useEffect } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IProviderStaffCardProps {
  data: any;
}

const ProviderStaffCard: React.FC<IProviderStaffCardProps> = React.memo(({ data }) => {
  useEffect(() => {}, []);
  const history = useNavigate();

  const seeDetailHandler = () => {
    if (data.user?.userProfileId) {
      history({ pathname: ConfigLink.userDetail, search: useQueryString({ id: data.user?.userProfileId }) });
    }
  };

  const privileges = [
    {
      label: "مدیر",
      value: "admin",
    },
    {
      label: "کاربر عادی",
      value: "agent",
    },
    {
      label: "مالک کسب و کار",
      value: "owner",
    },
  ];

  return (
    <div
      style={{ height: "76px" }}
      onClick={seeDetailHandler}
      className={`w-full ${
        data.user?.userProfileId ? "bg-white" : "ts-bg-gray-02"
      } flex justify-between items-start ts-shadow rounded-2xl p-3 mb-4 relative`}
    >
      <div
        className={`flex flex-col w-full ${data.user?.userProfileId ? "justify-between" : "justify-start"} items-start`}
      >
        <Text type="H6" className="ts-accent truncate mb-2">
          {data.user ? data.user?.name || "بدون نام" : "---"}
        </Text>
        <Text type="P4" className="ts-gray-06 ts-ltr-dir">
          {data.user ? data.user?.mobile || data.user?.phoneNumber || "بدون شماره" : "---"}
        </Text>
      </div>

      {data.user?.userProfileId && data.accessLevel && (
        <Text type="HM" className="ts-gray-06 absolute left-16">
          {privileges.filter((item) => item.value === data.accessLevel)[0].label || ""}
        </Text>
      )}

      {data.user?.userProfileId ? (
        <div className={`w-8 h-full ts-bg-gray-03 flex justify-center items-center rounded-xl`}>
          <BiChevronLeft size={26} className="ts-gray-07" />
        </div>
      ) : (
        <div className="bg-white absolute top-3 left-3 rounded-full flex justify-center items-center py-1 px-4">
          <Text type="HM" className="ts-gray-06">
            در انتظار تایید
          </Text>
        </div>
      )}
    </div>
  );
});

export { ProviderStaffCard };

const styles = {};
