/* eslint-disable */
import React, { useEffect } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Text } from "./";

interface ILinks {
  name: string;
  active?: boolean;
  link: string;
}

interface IBreadcrumbProps {
  links: ILinks[];
  className?: string;
}

const Breadcrumb: React.FC<IBreadcrumbProps> = React.memo(({ links, className = "" }) => {
  useEffect(() => {}, []);
  const navigate = useNavigate();
  return (
    <div
      className={`w-full flex justify-start items-center flex-nowrap whitespace-nowrap overflow-x-auto overflow-y-hidden ts-hide-scroll-x-but-keep-working touch-pan-x ${className}`}
    >
      {links.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Text
              onClick={() => {
                if (item.active) {
                  navigate(item.link, { replace: true });
                }
              }}
              type="P3"
              className={`w-max ${item.active ? "ts-primary cursor-pointer hover:underline" : "ts-gray-06"}`}
            >
              {item.name}
            </Text>
            {index < links.length - 1 && <HiChevronLeft size={21} className="ts-gray-06 mx-1 -mt-1" />}
          </React.Fragment>
        );
      })}
    </div>
  );
});

export { Breadcrumb };

const styles = {};
