/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { ApiInstance, useChangeTitle, vState } from "core";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { SelectPicker } from "rsuite";
import {
  CardLoading,
  ContainerScroll,
  CustomLoader,
  NoResult,
  SearchInput,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";
import { ReportsCard } from "./components";

const env = import.meta.env;

type SORT_TYPE = "NON" | "DATE" | "NAME";

const Reports = ({}) => {
  const metaTags = useChangeTitle("ویراژ - گزارشات تخلف");
  const snap = useSnapshot(vState);

  const [reports, setReports] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("DATE");

  const getReports = async (sort: SORT_TYPE, pageIncreased: boolean, query?: string | null) => {
    await ApiInstance.common
      .getReports(pageCount + 1, sort, query || null)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...reports, ...res.data.data];
          setReports(data);
          setPageCount((prev) => prev + 1);
        } else {
          setReports(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "گزارشات");
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getReports(sortReminders, false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > reports.length && !loading) {
      getReports(sortReminders, true, searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    setLoading(true);
    getReports(value, false, searchText);
  };

  const callApi = (e) => {
    setLoading(true);
    getReports(sortReminders, false, digitsFaToEn(e));
  };

  const handleChange = (e) => {
    setSearchText(e);
    // debouncedCallApi(e);
  };

  const resetSearch = () => {
    setSearchText("");
    setLoading(true);
    setTimeout(() => {
      callApi(null);
    }, 1100);
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={26} className="">
        <div className="w-full fixed top-14 pt-6 bg-white z-40 ts-fixed-header">
          <Text type="H3" className="ts-accent mb-6">
            گزارشات
          </Text>

          <SearchInput
            value={searchText}
            onChange={handleChange}
            onReset={resetSearch}
            onSearch={() => callApi(searchText)}
          />
        </div>

        <div className="w-full ts-container-margin">
          {(loading || reports.length !== 0) && (
            <div className="w-full flex justify-between items-center mb-6">
              {loading ? (
                <Skeleton className="w-28" />
              ) : (
                <Text type="H6" className="ts-accent">
                  {`${count} گزارش`}
                </Text>
              )}
              <SelectPicker
                size="lg"
                data={sortData}
                value={sortReminders}
                disabled={loading}
                cleanable={false}
                onChange={(value: any) => sortChangeHandler(value)}
                searchable={false}
                placeholder="مرتب سازی"
                className={`w-36 h-12 outline-none rounded-xl`}
              />
            </div>
          )}

          {loading ? (
            <CardLoading height="131px" />
          ) : reports.length === 0 ? (
            <NoResult forSearch={searchText.length !== 0} />
          ) : (
            reports.map((item) => {
              return <ReportsCard key={item.id} data={item} />;
            })
          )}
          {snap.isEndOfPage && !loading && (
            <div className="w-full flex justify-center">
              <CustomLoader />
            </div>
          )}
        </div>
      </ContainerScroll>
    </>
  );
};

export { Reports as default };
