/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { CardLoading, CustomLoader, NoResult, useNotification } from "ui/components";
import { PartCard } from "ui/views/Parts/components";
import { useSnapshot } from "valtio";

interface IProviderPartsTabProps {
  id: string;
}

const ProviderPartsTab: React.FC<IProviderPartsTabProps> = React.memo(({ id }) => {
  const snap = useSnapshot(vState);

  const [partsLoading, setPartsLoading] = useState(true);
  const [parts, setParts] = useState<any[]>([]);
  const [partsPage, setPartsPage] = useState(1);
  const [partsCount, setPartsCount] = useState(0);

  const getParts = async () => {
    await ApiInstance.shop
      .getWorkshopParts(partsPage, id)
      .then((res) => {
        console.log("parts", res);
        setPartsCount(res.data.meta.total);
        if (partsPage > 1) {
          let data = [...parts, ...res.data.data];
          setParts(data);
          setPartsPage((prev) => prev + 1);
        } else {
          setParts(res.data.data);
        }
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "لیست گزارشات و تخلفات");
      })
      .finally(() => {
        setPartsLoading(false);
      });
  };

  useEffect(() => {
    setPartsLoading(true);
    getParts();
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && partsCount > parts.length && parts.length !== 0 && !partsLoading) {
      getParts();
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      {partsLoading ? (
        <CardLoading height="99px" />
      ) : parts.length === 0 ? (
        <NoResult />
      ) : (
        parts.map((item, index) => {
          return <PartCard key={index} data={item} />;
        })
      )}
      {snap.isEndOfPage && !partsLoading && parts.length !== 0 && (
        <div className="w-full flex justify-center">
          <CustomLoader />
        </div>
      )}
    </>
  );
});

export { ProviderPartsTab };
