/* eslint-disable */
import { ApiInstance, ConfigLink, tools, useChangeTitle, useFetchQueryString, useQueryString } from "core";
import { countries } from "hooks";
import React, { useEffect, useState } from "react";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Button, ContainerScroll, ImageSlider, Text, useNotification } from "ui/components";
import { AddEditPartModal, DeletePartModal } from "./components";

interface IPartDetailProps {}

interface IQueryStringTransfer {
  id: string;
}

interface ICarProps {
  id: string;
  name: string;
}

interface IPartProps {
  name: string;
  cars: ICarProps[] | null;
  allCars?: boolean;
  grouping: string;
  createdAt: string;
  // manufacture: [],
  manufacture: string;
  manufacturingCountry: string;
  price: string;
  code?: string;
  description?: string;
  workshop: any;
  createdBy: any;
  images: string[] | null;
}

const PartDetail: React.FC<IPartDetailProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - جزئیات قطعه");
  const navigate = useNavigate();
  const { id } = useFetchQueryString<IQueryStringTransfer>();

  const [partInfo, setPartInfo] = useState<IPartProps>({
    name: "",
    cars: null,
    allCars: false,
    grouping: "",
    createdAt: "",
    // manufacture: [],
    manufacture: "",
    manufacturingCountry: "",
    price: "",
    code: "",
    description: "",
    workshop: {
      name: "",
    },
    createdBy: {
      name: "",
    },
    images: null,
  });
  const [pageLoading, setPageLoading] = useState(true);

  const [editPartModal, setEditPartModal] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const getPartInfo = async () => {
    await ApiInstance.shop
      .getPartInfo(id)
      .then((res) => {
        console.log(res);
        const data = res.data.data;
        setPartInfo(data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "جزئیات قطعه");
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getPartInfo();
  }, []);

  const creatorHandler = () => {
    navigate({ pathname: ConfigLink.userDetail, search: useQueryString({ id: partInfo.createdBy.id }) });
  };

  const workshopHandler = () => {
    navigate({ pathname: ConfigLink.serviceProviderDetail, search: useQueryString({ id: partInfo.workshop.id }) });
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={115} className={`relative overflow-x-hidden`}>
        <div className="w-full flex justify-between items-center mb-4">
          <Text type="H4" className="ts-accent">
            اطلاعات قطعه
          </Text>
          <div className="flex justify-center items-center gap-2">
            <Button
              onClick={() => setEditPartModal(true)}
              bcolor="white"
              classNameContainer="w-10 ts-shadow rounded-xl"
              className="w-full h-10 rounded-xl"
            >
              <HiPencilAlt size={21} className="ts-primary" />
            </Button>

            <Button
              onClick={() => setCancelModalOpen(true)}
              bcolor="white"
              classNameContainer="w-10 ts-shadow rounded-xl"
              className="w-full h-10 rounded-xl "
            >
              <HiTrash size={21} className="ts-primary" />
            </Button>
          </div>
        </div>
        <>
          <div className="w-full rounded-2xl ts-shadow p-4 flex flex-col justify-start items-start">
            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                نام قطعه
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.name || "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                خودرو
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.cars && partInfo.cars.length !== 0
                    ? partInfo.cars.map((item) => item.name).join("، ")
                    : partInfo.allCars
                    ? "همه خودروها"
                    : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                دسته بندی
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.grouping || "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                شرکت سازنده
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.manufacture ? partInfo.manufacture : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                کشور سازنده
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.manufacturingCountry
                    ? countries.filter((item) => item.value === partInfo.manufacturingCountry)[0].label
                    : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                قیمت
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.price ? `${tools.thousandSeparator(partInfo.price)} تومان` : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                ثبت کننده
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text onClick={creatorHandler} type="H6" className="ts-primary cursor-pointer">
                  {partInfo.createdBy && partInfo.createdBy?.name ? partInfo.createdBy?.name : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                فروشگاه
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text onClick={workshopHandler} type="H6" className="ts-primary cursor-pointer">
                  {partInfo.workshop ? partInfo.workshop?.name : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                تاریخ ثبت
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.createdAt
                    ? `${new Date(partInfo.createdAt).toLocaleDateString("fa-IR", {
                        month: "long",
                        year: "numeric",
                        day: "numeric",
                      })}`
                    : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                کد یکتا
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.code ? partInfo.code : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start">
              <Text type="H6" className="ts-accent w-20 ml-2">
                توضیحات
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent ts-request-description">
                  {partInfo.description || "---"}
                </Text>
              )}
            </div>

            {((partInfo.images && partInfo.images.length !== 0) || pageLoading) && (
              <div className="w-full flex flex-col justify-start items-start mt-4">
                <Text type="H6" className="ts-accent mb-2">
                  عکس قطعه
                </Text>
                {pageLoading ? (
                  <Skeleton className="w-full ts-carousel-image-height rounded-xl" />
                ) : (
                  <ImageSlider images={partInfo.images || []} dir="" />
                )}
              </div>
            )}
          </div>
        </>
      </ContainerScroll>

      <AddEditPartModal
        type="edit"
        isOpen={editPartModal}
        onClose={() => setEditPartModal(false)}
        onDone={getPartInfo}
        data={partInfo}
        id={id}
      />
      <DeletePartModal isOpen={cancelModalOpen} onClose={() => setCancelModalOpen(false)} id={id} />
    </>
  );
});

export { PartDetail as default };

const styles = {};
