/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { ApiInstance, ConfigLink, useChangeTitle, useQueryString, vState } from "core";
import { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  CardLoading,
  ContainerScroll,
  NoResult,
  SearchInput,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";
import { AddCarBrandModal, CarBrandCard } from "./components";

const CarBrandTypes = ({}) => {
  const metaTags = useChangeTitle("ویراژ - برند و تیپ خودرو‌ها");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [addCarModal, setAddCarModal] = useState(false);

  const [brands, setBrands] = useState<any[]>([]);
  const [brandsAll, setBrandsAll] = useState<any[]>([]);

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const getBrands = async () => {
    await ApiInstance.car
      .getCarBrands()
      .then((res) => {
        console.log(res);
        setBrands(res.data.data);
        setBrandsAll(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "برند و تیپ خودرو‌ها");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBrands();
  }, []);

  const searchHandler = (e, list) => {
    console.log(e);
    let result;
    if (e.length === 0) {
      result = list;
    } else {
      result = list.filter((item) => item.name.includes(e));
    }
    console.log(result);

    setBrands(result);
    setLoading(false);
  };

  const callApi = (e, list) => {
    setLoading(true);
    searchHandler(digitsFaToEn(e), list);
  };

  const handleChange = (e) => {
    setSearchText(e);
  };

  const resetSearch = () => {
    setSearchText("");
    setLoading(true);
    setTimeout(() => {
      callApi("", brandsAll);
    }, 1100);
  };

  const brandClickHandler = (item) => {
    navigate({
      pathname: ConfigLink.brandDetail,
      search: useQueryString({ id: item.id, name: item.name, ...(item.image ? { image: item.image } : undefined) }),
    });
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={110} className="">
        <div className="w-full fixed top-14 pt-6 bg-white z-40 ts-fixed-header">
          <Breadcrumb
            links={[
              {
                name: "برند و تیپ خودرو‌ها",
                active: false,
                link: "",
              },
            ]}
            className="mb-4"
          />
          <Text type="H3" className="ts-accent mb-4">
            برند و تیپ خودرو‌ها
          </Text>

          <SearchInput
            value={searchText}
            onChange={handleChange}
            onReset={resetSearch}
            onSearch={() => callApi(searchText, brandsAll)}
          />
        </div>

        <div
          className={`w-full ${brands.length === 0 && !loading ? "ts-container-margin" : "ts-container-more-margin"}`}
        >
          <div className="w-full flex justify-between items-center mb-4">
            {loading ? (
              <>
                <Skeleton className="w-28" />
              </>
            ) : (
              brands.length !== 0 && (
                <>
                  <Text type="H6" className="ts-accent">
                    {`${brands.length} برند خودرو`}
                  </Text>
                </>
              )
            )}
          </div>

          {snap.superAdmin && (
            <Button
              onClick={() => setAddCarModal(true)}
              textType="H6"
              bcolor="blackTransparent"
              icon={<IoIosAddCircle size={21} className="ts-accent" />}
              classNameContainer="w-full"
              className={`w-full h-12 rounded-xl mb-4 ${brands.length === 0 && "mt-2"}`}
            >
              اضافه کردن برند جدید
            </Button>
          )}

          {loading ? (
            <CardLoading height={"56px"} className="mb-2" />
          ) : brands.length === 0 ? (
            <NoResult forSearch={searchText.length !== 0} />
          ) : (
            brands.map((item, index) => {
              return <CarBrandCard key={index} data={item} onClick={() => brandClickHandler(item)} />;
            })
          )}
        </div>
      </ContainerScroll>
      <AddCarBrandModal isOpen={addCarModal} onClose={() => setAddCarModal(false)} onDone={getBrands} type={"add"} />
    </>
  );
};

export { CarBrandTypes as default };
