/* eslint-disable */
import { ConfigLink, tools, useQueryString, vState } from "core";
import React, { useEffect } from "react";
import { HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Button, Text } from "ui/components";
import { useSnapshot } from "valtio";
import { MiniMap } from "./mapComponents";

interface IProviderInfoSectionProps {
  data: any;
  onDelete: () => void;
}

const ProviderInfoSection: React.FC<IProviderInfoSectionProps> = React.memo(({ data, onDelete }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  useEffect(() => {}, []);

  const showOnMapHandler = () => {
    const coord = data.location.coordinates;
    navigate({
      pathname: ConfigLink.serviceProviderLocation,
      search: useQueryString({ lat: coord[0], lng: coord[1] }),
    });
  };

  return (
    <div className="w-full rounded-2xl ts-shadow p-4 mb-8 flex flex-col justify-start items-start">
      <div className="w-full flex justify-start items-start mb-3">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          نوع:
        </Text>
        <Text type="P4" className="ts-accent ts-sp-detail-item ts-sp-detail-item">
          {data.categories ? data.categories.map((item) => item.name).join("، ") || "---" : "---"}
        </Text>
      </div>

      <div className="w-full flex justify-start items-center mb-3">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          شهر:
        </Text>
        <Text type="P4" className="ts-accent ts-sp-detail-item">
          {data.city ? `${data.city.city || ""}, ${data.city.province || ""}` : "---"}
        </Text>
      </div>

      {/* <div className="w-full flex justify-start items-center mb-3">
              <Text type="H6" className="ts-gray-06 w-28 ml-2">
                نام مالک:
              </Text>
              <Text type="P4" className="ts-accent">
                {data.agents && data.agents.length !== 0 ? data.agents[0].user.name || "---" : "---"}
              </Text>
            </div>


            <div className="w-full flex justify-start items-center mb-3">
              <Text type="H6" className="ts-gray-06 w-28 ml-2">
                شماره تماس:
              </Text>
              <Text type="P4" className="ts-accent ts-ltr-dir">
                {(data.agents && data.agents.length !== 0 && data.agents[0].user.phoneNumber) || "---"}
              </Text>
            </div> */}

      <div className="w-full flex justify-start items-center mb-3">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          شماره ثابت:
        </Text>
        <Text type="P4" className="ts-accent">
          {data.phone || "---"}
        </Text>
      </div>

      <div className="w-full flex justify-start items-center mb-3">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          زمان ایجاد:
        </Text>
        <Text type="P4" className="ts-accent">
          {data.createdAt ? tools.getCardsTime(data.createdAt) : "---"}
        </Text>
      </div>

      <div className="w-full flex justify-start items-center mb-3">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          زمان بروزرسانی:
        </Text>
        <Text type="P4" className="ts-accent">
          {data.updatedAt ? tools.getCardsTime(data.updatedAt) : "---"}
        </Text>
      </div>

      <div className="w-full flex justify-start items-start mb-3">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          نشانی:
        </Text>
        <Text type="P4" className="ts-accent ts-sp-detail-item">
          {data.address || "---"}
        </Text>
      </div>

      <div className="w-full flex justify-start items-start mb-3">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          معرفی تعمیرگاه:
        </Text>
        <Text type="P4" className="ts-accent ts-sp-detail-item">
          {data.description || "---"}
        </Text>
      </div>

      <div className="w-full flex justify-start items-start">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          خدمات قابل ارائه:
        </Text>
        <Text type="P4" className="ts-accent ts-sp-detail-item">
          {data.services && data.services.length !== 0 ? data.services.map((item) => item.name).join(", ") : "---"}
        </Text>
      </div>

      {data.location && (
        <div className="w-full mt-6">
          <MiniMap data={data} id={"workshop-mini-map"} />

          <Button
            bcolor="primaryTransparent"
            onClick={showOnMapHandler}
            classNameContainer="w-full"
            className="w-full h-12 mt-3"
          >
            مشاهده روی نقشه
          </Button>
        </div>
      )}

      {snap.superAdmin && (
        <Button
          bcolor="redTransparent"
          disabled={data.archive || false}
          onClick={onDelete}
          icon={<HiTrash size={23} className="-mt-1" />}
          classNameContainer="w-full"
          className={`w-full h-12 ${data.location ? "mt-3" : "mt-6"}`}
        >
          حذف کسب و کار
        </Button>
      )}
    </div>
  );
});

export { ProviderInfoSection };

const styles = {};
