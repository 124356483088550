/* eslint-disable */
import React, { useEffect, useState } from "react";
import { BottomModal, Button, InputField, Text } from "ui/components";

interface IServiceDescriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: (value: string) => void;
  value: string | null;
}

const ServiceDescriptionModal: React.FC<IServiceDescriptionModalProps> = React.memo(
  ({ isOpen, onClose, onDone, value }) => {
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
      setDisabled(description.length === 0);
    }, [description]);

    useEffect(() => {
      if (isOpen && value) {
        setDescription(value);
        setLoading(false);
      }
    }, [isOpen]);

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent">
            {`توضیحات`}
          </Text>

          <InputField
            label="در قالب بیشتر بدانید و نکاتی درباره این مورد به کاربر نمایش داده می شود."
            fieldType="textarea"
            value={description}
            onChange={(e) => setDescription(e)}
            rows={6}
            placeholder=""
          />

          <Button
            onClick={() => {
              setLoading(true);
              onDone(description);
            }}
            disabled={disabled}
            loading={loading}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12"
          >
            ذخیره
          </Button>
        </div>
      </BottomModal>
    );
  },
);

export { ServiceDescriptionModal };

const styles = {};
