/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IReminderCardProps {
  data: any;
  isLargeScreen: boolean;
}

const ReminderCard: React.FC<IReminderCardProps> = React.memo(({ data, isLargeScreen }) => {
  const navigate = useNavigate();
  const relatedCondition = data.recommendations && data.recommendations.length !== 0;

  const reminderClickHandler = () => {
    navigate({ pathname: ConfigLink.reminderDetail, search: useQueryString({ id: data.id }) });
  };

  return (
    <div
      key={data.id}
      onClick={reminderClickHandler}
      style={{ height: isLargeScreen ? "111px" : "56px" }}
      className={`w-full rounded-2xl ts-shadow mb-4 p-3 ${
        data.disabled === true ? "ts-bg-gray03" : "bg-white"
      } flex flex-col justify-center cursor-pointer relative`}
    >
      <div className="w-full flex justify-between items-center">
        <div className="flex flex-col justify-between items-start">
          <div className="flex justify-center items-center">
            <Text type="H5" className="ts-gray-08 ml-2 pt-0.5">
              {data.name}
            </Text>
            {data.disabled && (
              <div className="ts-bg-gray-01 flex justify-center items-center rounded-full px-4 py-1">
                <Text type="HM" className="ts-gray-06">
                  آرشیو شده
                </Text>
              </div>
            )}
          </div>

          {isLargeScreen && (
            <Text type="P4" className="ts-accent mt-2">
              {`حداکثر زمان تعویض: ${data.expiresInDays ? Math.round(data.expiresInDays / 30) : "0"} ماه`}
            </Text>
          )}
        </div>

        <div className="flex items-center">
          <BiChevronLeft size={24} className="ts-accent" />
        </div>
      </div>

      {isLargeScreen && (
        <div className="w-full mt-2 flex items-center truncate">
          <Text type="HM" className="ts-gray-06 ml-0.5">
            {`یادآورهای مرتبط:`}
          </Text>
          <Text type="P5" className={`ts-gray-06 truncate ${relatedCondition ? "" : "mt-0.5"}`}>
            {relatedCondition ? data.recommendations.map((item) => item.recommendService?.name).join(", ") : "----"}
          </Text>
        </div>
      )}
    </div>
  );
});

export { ReminderCard };
