//================== [CONSTANT KEYS] ==================
const KEY_AUTH = "key_auth";
const KEY_TOKEN = "key_token";
const KEY_TEMP_RETURN_URL = "KEY_TEMP_RETURN_URL";

const ServiceStorage = {
  //!--------------- Temp Return URL
  setTempReturnURL: (pathFromConfigLink: string | null) => {
    if (pathFromConfigLink) {
      localStorage.setItem(KEY_TEMP_RETURN_URL, pathFromConfigLink);
    } else {
      localStorage.removeItem(KEY_TEMP_RETURN_URL);
    }
  },
  getTempReturnURL: (): string | null => {
    return localStorage.getItem(KEY_TEMP_RETURN_URL);
  },
  removeTempReturnURL: (): void => {
    localStorage.removeItem(KEY_TEMP_RETURN_URL);
    // return true;
  },
  //!--------------- Auth
  setAuth: (auth: any) => {
    localStorage.setItem(KEY_AUTH, JSON.stringify(auth));
  },
  getAuth: (): any | null => {
    return JSON.parse(localStorage.getItem(KEY_AUTH) as string);
  },
  removeAuth: (): boolean => {
    localStorage.removeItem(KEY_AUTH);
    return true;
  },
  setToken: (token: string) => {
    localStorage.setItem(KEY_TOKEN, token);
  },
  getToken: (): string | null => {
    return localStorage.getItem(KEY_TOKEN) as string;
  },
  removeToken: (): boolean => {
    localStorage.removeItem(KEY_TOKEN);
    return true;
  },
};

export { ServiceStorage };
