/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { CardLoading, CustomLoader, NoResult, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { PlateHistoryCard } from "./PlateHistoryCard";

interface IPlateHistoryTabProps {
  id: string;
}

const PlateHistoryTab: React.FC<IPlateHistoryTabProps> = React.memo(({ id }) => {
  const snap = useSnapshot(vState);

  const [historyLoading, setHistoryLoading] = useState(true);
  const [history, setHistory] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);

  const getHistory = async (pageIncreased: boolean) => {
    await ApiInstance.plates
      .getPlateHistory(id, pageCount + 1)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...history, ...res.data.data];
          setHistory(data);
          setPageCount((prev) => prev + 1);
        } else {
          setHistory(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "تاریخچه خودرو");
      })
      .finally(() => {
        setHistoryLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    setHistoryLoading(true);
    getHistory(false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > history.length && !historyLoading) {
      getHistory(true);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      {historyLoading ? (
        <CardLoading height="91px" />
      ) : history.length === 0 ? (
        <NoResult />
      ) : (
        history.map((item, index) => {
          return <PlateHistoryCard key={index} data={item} plateId={id} />;
        })
      )}
      {snap.isEndOfPage && count > history.length && !historyLoading && history.length !== 0 && (
        <div className="w-full flex justify-center">
          <CustomLoader />
        </div>
      )}
    </>
  );
});

export { PlateHistoryTab };
