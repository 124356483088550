/* eslint-disable */
import { useEffect, useState } from "react";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Button, CardLoading, ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, useQueryString, vState } from "../../../core";
import {
  DeletedPartRequestSection,
  DeletePartRequestModal,
  EditPartRequestModal,
  PartRequestDetailRequesterInfo,
  PartRequestDetailSegmentInfo,
  SupplierCard,
} from "./components";

interface IQueryStringTransfer {
  id: string;
}

const PartRequestDetail = ({}) => {
  const metaTags = useChangeTitle("ویراژ - جزئیات درخواست");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { id } = useFetchQueryString<IQueryStringTransfer>();

  const [pageLoading, setPageLoading] = useState(true);
  const [data, setData] = useState<any>({
    name: "",
    car: {
      id: "",
      name: "",
    },
    description: "",
    delete: false,
    deleteReason: "",
    createdBy: {},
    suppliers: [],
    images: null,
  });

  const [editRequestModal, setEditRequestModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const getRequestDetail = async () => {
    await ApiInstance.partSupply
      .getRequestDetail(id)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "اطلاعات خودرو");
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getRequestDetail();
  }, []);

  const userClickHandler = (id) => {
    if (id) {
      navigate({ pathname: ConfigLink.userDetail, search: useQueryString({ id }) });
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={100} className="">
        <Breadcrumb
          links={[
            {
              name: "درخواست قطعات",
              active: true,
              link: ConfigLink.partRequests,
            },
            {
              name: "جزییات",
              active: false,
              link: ``,
            },
          ]}
          className="mb-6"
        />

        {pageLoading ? (
          <Skeleton className="w-40 h-8 mb-6" />
        ) : (
          <Text type="H3" className="ts-accent mb-6">
            {data.name || "نام تعریف نشده"}
          </Text>
        )}

        {pageLoading ? (
          <>
            <Skeleton containerClassName="w-full" className="w-full h-24 ts-part-card rounded-2xl mb-6" />
            <Skeleton containerClassName="w-full" className="w-full h-24 ts-part-card rounded-2xl mb-6" />
            <Skeleton containerClassName="w-full" className="w-full h-11 rounded-xl mb-4" />
            <Skeleton containerClassName="w-full" className="w-full h-11 rounded-xl mb-6" />
          </>
        ) : (
          <>
            {data.delete && (
              <DeletedPartRequestSection data={data} userClicked={() => userClickHandler(data.createdBy?.id)} />
            )}

            {/* segment info */}
            <PartRequestDetailSegmentInfo data={data} />

            {/* requester info */}
            <PartRequestDetailRequesterInfo data={data} userClicked={() => userClickHandler(data.createdBy?.id)} />

            {data.delete && snap.superAdmin ? null : (
              <>
                <Button
                  bcolor="primaryTransparent"
                  onClick={() => setEditRequestModal(true)}
                  icon={<HiPencilAlt size={23} className="ts-primary mr-1 -mt-0.5" />}
                  classNameContainer="w-full"
                  className="w-full h-11 mb-4"
                >
                  ویرایش
                </Button>

                <Button
                  bcolor="redTransparent"
                  onClick={() => setDeleteModal(true)}
                  icon={<HiTrash size={23} className="-mt-1" />}
                  classNameContainer="w-full"
                  className="w-full h-11 mb-6"
                >
                  حذف درخواست
                </Button>
              </>
            )}
          </>
        )}

        <Text type="H4" className="ts-accent mb-3">
          تامین کنندگان
        </Text>

        {pageLoading ? (
          <CardLoading height="104px" />
        ) : (
          data.suppliers.map((item: any) => {
            return <SupplierCard data={item} key={item.id} />;
          })
        )}
      </ContainerScroll>
      <EditPartRequestModal
        isOpen={editRequestModal}
        onClose={() => setEditRequestModal(false)}
        id={id}
        selectedItem={data}
        onDone={getRequestDetail}
      />
      <DeletePartRequestModal isOpen={deleteModal} onClose={() => setDeleteModal(false)} id={id} />
    </>
  );
};

export { PartRequestDetail as default };
