/* eslint-disable */
import { useEffect, useState } from "react";
import { Toggle } from "rsuite";
import { ApiInstance } from "../../../core";
import { useChangeTitle } from "../../../core/hooks";
import { Breadcrumb, ContainerScroll, Text, useNotification } from "../../components";

const OwnerFeatures = ({}) => {
  const metaTags = useChangeTitle("ویراژ - تنظیمات مالک");

  const [features, setFeatures] = useState<any[]>([]);
  const [loading, setLoading] = useState(-1);
  const [pageLoading, setPageLoading] = useState(true);

  const getSettings = async () => {
    await ApiInstance.common
      .getOwnerSettings()
      .then((res) => {
        console.log(res);
        setFeatures(res.data.features || []);
      })
      .catch((err) => {
        console.log(err);

        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "لیست کاربرها");
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  const changeFeatureHandler = async (value, index) => {
    setLoading(index);
    let newFeatures = [...features];
    if (newFeatures.includes(value)) {
      newFeatures = newFeatures.filter((item) => item !== value);
    } else {
      newFeatures.push(value);
    }
    console.log(newFeatures);

    await ApiInstance.common
      .setOwnerSettings(newFeatures)
      .then((res) => {
        console.log(res);
        useNotification("تغییرات اعمال شد.", "success");
        setFeatures(newFeatures);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "لیست کاربرها");
      })
      .finally(() => {
        setLoading(-1);
      });
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={20} className="">
        <Breadcrumb
          links={[
            {
              name: "تنظیمات پایه",
              active: false,
              link: ``,
            },
            {
              name: "تنظیمات مالک خودرو",
              active: false,
              link: ``,
            },
          ]}
          className="mb-6"
        />

        <div className="w-full">
          <div className="w-full flex justify-between items-center mb-3">
            <Text type="H3" className="ts-accent">
              تنظیمات مالک خودرو
            </Text>
          </div>
          <Text type="P4" className="ts-gray-07 mb-4">
            در این قسمت میتوانید امکانات قابل مشاهده برای مالکین خودرو مشخص کنید.
          </Text>
        </div>

        <div className="w-full">
          {featureList.map((item, index) => {
            return (
              <span className="w-full flex justify-start items-center rounded-2xl p-4 ts-shadow mb-4">
                <Toggle
                  size={"md"}
                  className="ts-reminders-toggle"
                  loading={loading === index || pageLoading}
                  checked={features.includes(item.value)}
                  onChange={() => changeFeatureHandler(item.value, index)}
                />
                <Text type="H6" className="ts-accent mr-2">
                  {item.label}
                </Text>
              </span>
            );
          })}
        </div>
      </ContainerScroll>
    </>
  );
};

const featureList = [
  {
    label: "جستجوی کسب و کار",
    value: "workshop_search",
  },
  {
    label: "جستجوی قطعات یدکی",
    value: "shop_search",
  },
  {
    label: "درخواست قطعات",
    value: "part_order",
  },
  {
    label: "تبلیغات",
    value: "advertising",
  },
  {
    label: "مشاوره آنلاین",
    value: "guidance",
  },
  {
    label: "عوارض و مالیات",
    value: "toll_and_taxes",
  },
  {
    label: "بیمه",
    value: "insurance",
  },
  {
    label: "ارسال پیام(چت)",
    value: "chat",
  },
  {
    label: "دعوت از دیگران",
    value: "share",
  },
  {
    label: "امداد خودرو",
    value: "roadside_assistance",
  },
  {
    label: "مخفی کردن شماره تلفن",
    value: "hide_mobile_number",
  },
];

export { OwnerFeatures as default };
