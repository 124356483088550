/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useRef, useState } from "react";
import { IoIosCloudDownload } from "react-icons/io";
import { MdOutlineFileUpload } from "react-icons/md";
import { Loader } from "rsuite";
import { useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import * as XLSX from "xlsx";

interface IFunctionalButtonsProps {
  downloadClicked: () => void;
  onUploadFinished: () => void;
}

const FunctionalButtons: React.FC<IFunctionalButtonsProps> = React.memo(({ downloadClicked, onUploadFinished }) => {
  const snap = useSnapshot(vState);
  const uploadRef = useRef();

  const [uploadLoading, setUploadLoading] = useState(false);

  const uploadFileHandler = async (file) => {
    await ApiInstance.common
      .uploadCities(file)
      .then((res) => {
        console.log(res);
        onUploadFinished();
        useNotification("فایل انتخابی آپلود شد.", "success");
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "برند و تیپ خودرو‌ها");
      })
      .finally(() => {
        setUploadLoading(false);
      });
  };

  const handleFileChange = async (e) => {
    console.log(e.target.files[0]);
    setUploadLoading(true);

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      console.log("event", event);

      const workbook = XLSX.read(event?.target?.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      console.log(sheetData);
      const data = sheetData.map((item: any) => {
        return { city: item.city || "", province: item.province || "" };
      });
      console.log(data);

      await uploadFileHandler(data);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div className="flex justify-center items-center">
      {snap.superAdmin && (
        <>
          <input
            // @ts-ignore
            ref={uploadRef}
            id="file"
            type="file"
            accept=".xlsx, .xls, .csv"
            onChange={handleFileChange}
            multiple={false}
            hidden
          />
          <div
            onClick={() => {
              // @ts-ignore
              if (!uploadLoading) uploadRef?.current?.click();
            }}
            className={`transition-all w-10 h-10 p-2 rounded-xl bg-white ts-shadow flex justify-center items-center cursor-pointer`}
          >
            {uploadLoading ? <Loader size="xs" /> : <MdOutlineFileUpload size={22} className="ts-accent" />}
          </div>
        </>
      )}

      <div
        onClick={downloadClicked}
        className="w-10 h-10 p-2 mr-2 rounded-xl ts-shadow bg-white cursor-pointer flex justify-center items-center"
      >
        <IoIosCloudDownload size={21} className="ts-accent mr-px" />
      </div>
    </div>
  );
});

export { FunctionalButtons };

const styles = {};
