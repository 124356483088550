import markerImg from "assets/images/marker-2.svg";
import L from "leaflet";
import React, { useRef } from "react";
import { Marker, Tooltip } from "react-leaflet";
import { Text } from "ui/components";

interface ISetLocationProps {
  data: any;
  currentLocation: L.LatLngExpression | null;
  selectedWorkshop: null | any;
  selectWorkshopHandler: (any) => void;
  workshops: any[];
  index: number;
}

const SPMarkerComponent: React.FC<ISetLocationProps> = React.memo(
  ({ data, currentLocation, selectedWorkshop, selectWorkshopHandler, workshops, index }) => {
    let markerRef: any = useRef();

    let mapIcon = L.icon({
      iconUrl: markerImg,
      iconSize: [20, 24],
      iconAnchor: [11, 29],
      tooltipAnchor: [75, -20],
    });

    return (
      <Marker
        ref={markerRef}
        icon={mapIcon}
        autoPan={false}
        position={data.location.coordinates}
        eventHandlers={{
          click: (L) => {
            console.log("marker clicked");
            selectWorkshopHandler(data);
            console.log(L);
            L.target._tooltip._contentNode.classList.add("ts-tooltip-selected");
          },
        }}
      >
        <Tooltip
          permanent
          position={data.location.coordinates}
          interactive
          direction={"bottom"}
          offset={[data.name.length > 15 ? data.name.length + 50 : 25, 0]}
        >
          <div
            onClick={() => {
              console.log("tooltip clicked");
              selectWorkshopHandler(data);
            }}
            className={`ts-tooltip-default w-full h-7 flex justify-center items-center rounded-xl ${
              selectedWorkshop && selectedWorkshop.id === data.id ? "ts-tooltip-selected" : "ts-tooltip-not-selected"
            }`}
          >
            <Text type="P4">{data.name}</Text>
          </div>
        </Tooltip>
      </Marker>
    );
  },
);

export { SPMarkerComponent };
