/* eslint-disable */
import { configs } from "configs";
import { ApiInstance, useObjectState } from "core";
import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { HiTrash } from "react-icons/hi";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";
import { CarsModal } from "ui/components/CarsModal";
import { DeleteAllImagesModal } from "../";

interface IEditPartRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  id: string;
  selectedItem: any;
}

const EditPartRequestModal: React.FC<IEditPartRequestModalProps> = React.memo(
  ({ isOpen, onClose, onDone, id, selectedItem }) => {
    const [values, setValues] = useObjectState<any>({
      name: "",
      car: {
        name: "",
        id: "",
      },
      // category: "",
      description: "",
      images: null,
    });
    const { name, car, /*category,*/ description, images } = values;

    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [carsModal, setCarModalVisible] = useState(false);
    const [deleteAllModal, setDeleteAllModal] = useState(false);

    useEffect(() => {
      setValues({
        name: selectedItem.name,
        car: selectedItem.car,
        description: selectedItem.description || "",
        images: selectedItem.images || null,
      });
    }, [isOpen]);

    useEffect(() => {
      setDisabled(name.length === 0 || car.id.length === 0 /*|| category.length === 0*/);
    }, [values]);

    const editRequestHandler = async () => {
      await ApiInstance.partSupply
        .editRequestDetail(id, { ...values, car: car.id })
        .then((res) => {
          console.log(res);
          useNotification("درخواست قطعه ویرایش شد.", "success", "ویرایش درخواست");
          onDone();
          onClose();
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "ویرایش درخواست");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const requestHandler = async () => {
      setLoading(true);
      editRequestHandler();
    };

    const removeAllImageHandler = () => {
      setValues({ images: null });
    };

    const removeImage = (item) => {
      const imageItems = images.filter((im) => im !== item);
      setValues({ images: imageItems });
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose} className={`${deleteAllModal ? "h-60" : "h-auto"}`}>
          <div className="w-full flex flex-col justify-start items-start">
            <Text type="H4" className="ts-accent mb-6">
              ویرایش درخواست قطعه
            </Text>

            <InputField
              label="نام قطعه"
              value={name}
              onChange={(e) => setValues({ name: e })}
              placeholder="مثلا: آیینه بغل سمت شاگرد"
            />

            <Text type="H6" className="ts-accent mb-2">
              برند و مدل خودرو
            </Text>
            <div
              onClick={() => setCarModalVisible(true)}
              className={`w-full rounded-xl outline-none ts-car-input-border h-12 mb-6 flex justify-between items-center px-4 py-3`}
            >
              <Text type="P4" className={`ts-accent`}>
                {car.name || "خودروی مورد نظر خود را انتخاب کنید"}
              </Text>
              <BiChevronDown size={20} className="ts-accent -mt-0.5" />
            </div>

            <InputField
              label="توضیحات (اختیاری)"
              fieldType="textarea"
              value={description}
              rows={4}
              onChange={(e) => setValues({ description: e })}
              placeholder="توضیحاتی درمورد قطعه درخواستی و..."
            />

            {images && images.length !== 0 && (
              <>
                <div className="w-full flex justify-between items-center mb-2">
                  <Text type="H6" className="ts-accent">
                    عکس قطعه
                  </Text>
                  <Button
                    bcolor="transparent"
                    onClick={() => setDeleteAllModal(true)}
                    icon={<HiTrash size={23} className="ts-secondary-red -mt-1" />}
                    className="ts-secondary-red -ml-2"
                    textType="H6"
                  >
                    حذف همه
                  </Button>
                </div>
                <div className="w-full grid grid-cols-2 gap-2 mb-6">
                  {images.map((item, index) => {
                    return (
                      <div style={{ height: "121px" }} className="w-full rounded-xl relative">
                        <img
                          src={`${configs().imageUrl}/parts/${item}`}
                          alt={index}
                          className="w-full h-full object-cover rounded-xl"
                        />
                        <div
                          onClick={() => removeImage(item)}
                          className="absolute top-2 right-2 w-10 h-10 ts-base-bg-white z-10 flex justify-center items-center rounded-xl cursor-pointer p-2"
                        >
                          <HiTrash size={20} className="ts-accent -mt-1" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <Button
              onClick={requestHandler}
              loading={loading}
              disabled={disabled}
              bcolor="primary"
              classNameContainer="w-full"
              className="w-full rounded-xl h-12"
            >
              ذخیره
            </Button>
          </div>
        </BottomModal>
        <CarsModal
          isOpen={carsModal}
          onClose={() => setCarModalVisible(false)}
          isSingleChoice
          selectedCars={car}
          selectCarHandler={(value) => {
            setValues({ car: value });
          }}
        />

        <DeleteAllImagesModal
          isOpen={deleteAllModal}
          onClose={() => setDeleteAllModal(false)}
          onDone={removeAllImageHandler}
        />
      </>
    );
  },
);

export { EditPartRequestModal };

const styles = {};
