//@ts-nocheck
/* eslint-disable */
import { vState } from "core";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";

interface IContainerScrollProps {
  heightOffset?: number | undefined;
  children?: any;
  className?: string;
}

const ContainerScroll = React.forwardRef(
  ({ className = "", children, heightOffset = undefined }: IContainerScrollProps, ref) => {
    const snap = useSnapshot(vState);
    let location = useLocation();
    const [lastScrollTop, setLastScrollTop] = useState(0);
    useCallback(() => {
      vState.isScrolling = false;
    }, []);

    React.useEffect(() => {
      // trigger on url change. In order to scroll to top of the page
      window.scrollTo(0, 0);
    }, [location]);

    function navbarControlHandler(e) {
      let st = window.pageYOffset || document.documentElement.scrollTop;

      if (st <= 20) {
        vState.isScrolling = false;
      }

      if (st > lastScrollTop && st > 20) {
        // downScroll code
        if (!snap.isScrolling) {
          vState.isScrolling = true;
        }
      } else {
        // upScroll code
        if (st <= 10) {
          if (snap.isScrolling) {
            vState.isScrolling = false;
          }
        }
      }
      setLastScrollTop(st <= 0 ? 0 : st); // For Mobile or negative scrolling

      if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 1.5) {
        console.log("REACHED END OF PAGE");
        vState.isEndOfPage = true;
      }
    }

    useEffect(() => {
      window.addEventListener("scroll", navbarControlHandler);

      return () => {
        window.removeEventListener("scroll", navbarControlHandler);
      };
    }, []);

    return (
      <div
        // onScroll={controlNavbar}
        style={{ position: "unset", height: "inherit", paddingBottom: heightOffset, paddingTop: "88px" }}
        className={`w-full overflow-x-hidden overflow-y-auto ts-hide-scroll-but-keep-working z-30 ${className} max-2xs:px-3 px-6`}
      >
        {children}
      </div>
    );
  },
);

export { ContainerScroll };
