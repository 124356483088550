/* eslint-disable */
import defaultCar from "assets/images/part-request-default.svg";
import { configs } from "configs";
import { ConfigLink, tools, useQueryString } from "core";
import React from "react";
import { MdChevronLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IPartRequestCardProps {
  data: any;
}

const PartRequestCard: React.FC<IPartRequestCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  const requestClickHandler = () => {
    navigate({ pathname: ConfigLink.partRequestDetail, search: useQueryString({ id: data.id }) });
  };

  return (
    <div
      onClick={requestClickHandler}
      key={data.id}
      className="w-full flex justify-between items-center p-3 bg-white rounded-2xl ts-shadow mb-4 cursor-pointer relative"
    >
      <div className="flex h-full flex-col justify-between items-start">
        <div className="flex justify-start items-start">
          <div className="w-69 h-full rounded-xl">
            <img
              src={
                data.images && data.images.length !== 0 ? `${configs().imageUrl}/parts/${data.images[0]}` : defaultCar
              }
              alt="default"
              className={`w-full h-full ${
                data.images && data.images.length !== 0 ? "object-cover aspect-square rounded-xl" : ""
              }`}
            />
          </div>
          <div className="flex flex-col justify-start items-start mr-3">
            <div className="flex justify-start items-center">
              <Text type="H6" className={`ts-accent ${data.delete ? "w-1/3 truncate" : ""}`}>
                {data.name || "---"}
              </Text>
              {data.delete && (
                <div className="ts-bg-gray-01 w-max ts-archive-chips mr-2 -mt-1 flex justify-center items-center rounded-full px-4 py-1">
                  <Text type="HM" className="ts-gray-06">
                    آرشیو شده
                  </Text>
                </div>
              )}
            </div>
            <Text type="P4" className="ts-gray-07 my-2">
              {data.car.name || "---"}
            </Text>
            <Text type="P5" className="ts-accent">
              {data.createdAt ? tools.convertDateToPersianDate(data.createdAt, true) : "---"}
            </Text>
          </div>
        </div>
      </div>
      <MdChevronLeft size={25} className="ts-accent absolute top-9 left-3" />
    </div>
  );
});

export { PartRequestCard };

const styles = {};
