/* eslint-disable */
import React from "react";
import { Text } from "ui/components";

interface IDeletedPartRequestSectionProps {
  data: any;
  userClicked: () => void;
}

const DeletedPartRequestSection: React.FC<IDeletedPartRequestSectionProps> = React.memo(({ data, userClicked }) => {
  const deleteReasonList = [
    {
      label: "قطعه از طریق ویراژ تامین شد.",
      value: "provided_by_virazh",
    },
    {
      label: "قطعه را از جای دیگری تهیه کردم.",
      value: "provided_by_others",
    },
    {
      label: "تامین کننده‌ای پیدا نشد.",
      value: "not_provided",
    },
    {
      label: "از خرید قطعه منصرف شدم.",
      value: "cancelled",
    },
  ];

  return (
    <div className="w-full rounded-2xl ts-shadow p-4 mb-6 flex flex-col justify-start items-start">
      <div className="w-full flex justify-start items-start mb-3">
        <Text type="H6" className="ts-gray-06 w-69 ml-2">
          وضعیت
        </Text>
        <div className="ts-bg-gray-01 w-max -mt-1 flex justify-center items-center rounded-full px-4 py-1">
          <Text type="HM" className="ts-gray-06">
            آرشیو شده
          </Text>
        </div>
      </div>

      <div className="w-full flex justify-start items-start">
        <Text type="H6" className="ts-gray-06 w-69 ml-2">
          حذف کننده
        </Text>
        {data.deleteReason === "deleted_by_admin" ? (
          <Text type="P4" className="ts-accent">
            ادمین
          </Text>
        ) : (
          <Text type="H6" onClick={userClicked} className="ts-primary cursor-pointer">
            {data.createdBy.name || "---"}
          </Text>
        )}
      </div>

      {data.deleteReason !== "deleted_by_admin" && (
        <div className="w-full flex justify-start items-start mt-3">
          <Text type="H6" className="ts-gray-06 w-69 ml-2">
            دلیل حذف
          </Text>
          <Text type="P4" className="ts-accent ts-request-description">
            {deleteReasonList.filter((item) => item.value === data.deleteReason)[0].label || "---"}
          </Text>
        </div>
      )}
    </div>
  );
});

export { DeletedPartRequestSection };

const styles = {};
