/* eslint-disable */
import { ApiInstance, useChangeTitle, vState } from "core";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Button, CardLoading, ContainerScroll, NoResult, Text } from "ui/components";
import { useSnapshot } from "valtio";
import { AddEditReminderModal, ReminderCard } from "./components";

const Reminders = ({}) => {
  const metaTags = useChangeTitle("ویراژ - یادآورها");
  const snap = useSnapshot(vState);

  const isLargeScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const [addServiceShow, setAddServiceShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const getUI = async () => {
    await ApiInstance.common.getUI();
  };

  useEffect(() => {
    if (snap.services.length === 0) {
      getUI();
    }
    setLoading(false);
  }, []);

  const addItemHandler = () => {
    setAddServiceShow(true);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={70} className="">
        <div className="w-full flex justify-between items-center mb-6">
          <Text type="H3" className="ts-accent">
            لیست یادآورها
          </Text>
          {isLargeScreen && snap.superAdmin && (
            <Button
              onClick={addItemHandler}
              textType="H6"
              bcolor="primary"
              classNameContainer="w-64"
              className="w-full h-12 rounded-xl"
            >
              تعریف یادآور جدید
            </Button>
          )}
        </div>

        {loading ? (
          <CardLoading height={isLargeScreen ? "111px" : "56px"} />
        ) : snap.services.length === 0 ? (
          <NoResult />
        ) : (
          snap.services.map((item, index) => {
            return <ReminderCard key={index} data={item} isLargeScreen={isLargeScreen} />;
          })
        )}

        {!isLargeScreen && snap.superAdmin && (
          <Button
            onClick={addItemHandler}
            textType="H6"
            bcolor="primary"
            classNameContainer="w-full"
            className="ts-float-button h-12 rounded-xl"
          >
            تعریف یادآور جدید
          </Button>
        )}
      </ContainerScroll>
      <AddEditReminderModal isOpen={addServiceShow} onClose={() => setAddServiceShow(false)} />
    </>
  );
};

export { Reminders as default };
