/* eslint-disable */
import "leaflet.markercluster";
import "leaflet.markercluster/dist/leaflet.markercluster";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { BboxHandler } from "./BboxHandler";
import { MoveToPoint } from "./MoveToPoint";
import { SPMarkerComponent } from "./SPMarker";
import { UserLocation } from "./UserLocation";

interface IMapViewProps {
  reference: any;
  setRef: (ref) => void;
  currentLocation: any | null;
  userLocation: any | null;
  workshops: any[];
  setCurrentLocation: (any) => void;
  setBbox: (any) => void;
  selectedWorkshop: null | any;
  selectWorkshopHandler: (any) => void;
  zoomLevel: string;
  setZoomLevel: (zoom) => void;
}

const MapView: React.FC<IMapViewProps> = React.memo(
  ({
    reference,
    setRef,
    workshops,
    currentLocation,
    userLocation,
    setCurrentLocation,
    setBbox,
    selectWorkshopHandler,
    selectedWorkshop,
    zoomLevel,
    setZoomLevel,
  }) => {
    const defaultLocation = [35.69979085412715, 51.33753633512243];
    const mapRef: any = useRef();

    const [workshopList, setWorkshopList] = useState<any[]>([]);

    useEffect(() => {
      setRef(mapRef);
    }, [currentLocation, userLocation]);

    useEffect(() => {
      let finalItem = [...workshopList];
      workshops.map((item) => {
        const isAddedBefore = finalItem.findIndex((loc) => loc.id === item.id);
        if (isAddedBefore === -1) {
          finalItem.push(item);
        }
      });
      const filteredResult = finalItem.filter((o) => workshops.some(({ id }) => o.id === id));

      setWorkshopList(filteredResult);
    }, [workshops]);

    if (currentLocation) {
      return (
        <MapContainer
          id="owner-map"
          center={currentLocation || defaultLocation}
          zoom={+zoomLevel}
          minZoom={5}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MoveToPoint ref={mapRef} />
          <BboxHandler
            setBbox={setBbox}
            setCurrentLocation={setCurrentLocation}
            selectWorkshopHandler={selectWorkshopHandler}
            zoom={zoomLevel}
            setZoomLevel={setZoomLevel}
          />

          <MarkerClusterGroup chunkedLoading animate showCoverageOnHover zoomToBoundsOnClick spiderfyOnMaxZoom>
            {workshopList.map((item: any, index: number) => {
              return (
                <SPMarkerComponent
                  workshops={workshops}
                  selectedWorkshop={selectedWorkshop}
                  selectWorkshopHandler={selectWorkshopHandler}
                  data={item}
                  currentLocation={item.location.coordinates}
                  index={index}
                />
              );
            })}
          </MarkerClusterGroup>
          <UserLocation userLocation={userLocation} />
        </MapContainer>
      );
    } else {
      return null;
    }
  },
);

export { MapView };

const styles = {};
