/* eslint-disable */
import { configs } from "configs";
import { ApiInstance, useObjectState } from "core";
import { countries, shopCategories } from "hooks";
import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { HiTrash } from "react-icons/hi";
import { SelectPicker } from "rsuite";
import { BottomModal, Button, CarsModal, InputField, Text, useNotification } from "ui/components";
import { DeleteAllImagesModal } from "../";

type ModalType = "add" | "edit";

interface IAddEditPartModalProps {
  isOpen: boolean;
  type: ModalType;
  onClose: () => void;
  onDone: () => void;
  id?: string;
  data?: any;
}

const AddEditPartModal: React.FC<IAddEditPartModalProps> = React.memo(({ isOpen, onClose, onDone, id, type, data }) => {
  const defaultValues = {
    name: "",
    car: null,
    grouping: "",
    // manufacture: [],
    manufacture: "",
    manufacturingCountry: "",
    price: "",
    code: "",
    description: "",
    images: null,
  };

  const [info, setInfo] = useObjectState<any>(defaultValues);
  const { name, car, grouping, manufacture, manufacturingCountry, price, code, description, images } = info;

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [carModalOpen, setCarModalOpen] = useState(false);
  const [deleteAllModal, setDeleteAllModal] = useState(false);

  useEffect(() => {
    if (type === "edit" && data) {
      setInfo({
        ...data,
        price: data.price
          ? parseFloat(data.price)
              .toFixed(0)
              .toString()
              .split(/(?=(?:\d{3})+$)/)
              .join(",")
          : "",
        defaultValues,
      });
    } else {
      setInfo(defaultValues);
    }
  }, [isOpen]);

  useEffect(() => {
    setDisabled(
      name.length === 0 ||
        car === null ||
        grouping.length === 0 ||
        manufacture.length === 0 ||
        manufacturingCountry.length === 0 ||
        price.length === 0,
    );
  }, [info]);

  const getFormattedData = () => {
    return {
      ...info,
      car: car.id,
      price: price.replace(/,/gi, ""),
    };
  };

  const acceptRequest = async () => {
    const finalData = getFormattedData();

    await ApiInstance.shop
      .addPart(finalData)
      .then((res) => {
        console.log(res);
        useNotification("قطعه مورد نظر اضافه شد", "success");
        onDone();
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editRequest = async () => {
    const finalData = getFormattedData();

    await ApiInstance.shop
      .editPartInfo(id || "", finalData)
      .then((res) => {
        console.log(res);
        useNotification("قطعه مورد نظر ویرایش شد", "success");
        onDone();
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const approveBtnHandler = () => {
    setLoading(true);

    type === "add" ? acceptRequest() : editRequest();
  };

  const removeAllImageHandler = () => {
    setInfo({ images: null });
  };

  const removeImage = (item) => {
    const imageItems = images.filter((im) => im !== item);
    setInfo({ images: imageItems });
  };

  return (
    <>
      <BottomModal isOpen={isOpen} onClose={onClose} className={`${deleteAllModal ? "h-60" : "h-auto"}`}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent mb-6">
            {type === "edit" ? "ویرایش قطعه" : "تعریف قطعه جدید"}
          </Text>

          <InputField
            label="نام قطعه"
            value={name}
            onChange={(e) => setInfo({ name: e })}
            placeholder="مثلا مه شکن عقب"
          />

          <Text type="H6" className="ts-accent mb-2">
            برند و تیپ خودرو
          </Text>
          <div
            onClick={() => setCarModalOpen(true)}
            className={`w-full rounded-xl outline-none ts-car-input-border h-12 mb-6 flex justify-between items-center px-4 py-3`}
          >
            <Text type="P4" className={`ts-accent`}>
              {car ? car.name : "خودروی مورد نظر خود را انتخاب کنید"}
            </Text>
            <BiChevronDown size={20} className="ts-accent -mt-0.5" />
          </div>

          <Text type="H6" className="ts-accent mb-2">
            دسته بندی
          </Text>
          <SelectPicker
            size="lg"
            data={shopCategories}
            value={grouping}
            onChange={(value) => setInfo({ grouping: value })}
            block
            placement="auto"
            searchable={false}
            cleanable={false}
            placeholder="دسته بندی را انتخاب کنید"
            className={`w-full h-12 outline-none rounded-xl mb-6`}
          />

          <InputField
            label="شرکت سازنده"
            value={manufacture}
            onChange={(e) => setInfo({ manufacture: e })}
            placeholder="شرکت سازنده قطعه"
          />

          <Text type="H6" className="ts-accent mb-2">
            کشور سازنده
          </Text>
          <SelectPicker
            size="lg"
            data={countries}
            value={manufacturingCountry}
            onChange={(value) => setInfo({ manufacturingCountry: value })}
            block
            placement="auto"
            searchable
            cleanable={false}
            placeholder="کشور سازنده قطعه را انتخاب کنید"
            className={`w-full h-12 outline-none rounded-xl mb-6`}
          />

          <InputField
            label="قیمت"
            inputType="price"
            value={price}
            onChange={(e) => {
              let tempNumber = e.replace(/\D/g, "");
              let commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");
              setInfo({ price: commaSeparatedNumber });
            }}
            hint="تومان"
            placeholder="قیمت به تومان"
          />

          <InputField
            label="کد یکتا (اختیاری)"
            value={code}
            onChange={(e) => setInfo({ code: e })}
            placeholder="کد یکتای قطعه"
          />

          <InputField
            label="توضیحات (اختیاری)"
            fieldType="textarea"
            value={description}
            rows={4}
            onChange={(e) => setInfo({ description: e })}
            placeholder="توضیحاتی درمورد قطعه، نحوه تحویل و..."
          />

          {images && images.length !== 0 && (
            <>
              <div className="w-full flex justify-between items-center mb-2">
                <Text type="H6" className="ts-accent">
                  عکس قطعه
                </Text>
                <Button
                  bcolor="transparent"
                  onClick={() => setDeleteAllModal(true)}
                  icon={<HiTrash size={23} className="ts-secondary-red -mt-1" />}
                  className="ts-secondary-red -ml-2"
                  textType="H6"
                >
                  حذف همه
                </Button>
              </div>
              <div className="w-full grid grid-cols-2 gap-2 mb-6">
                {images.map((item, index) => {
                  return (
                    <div style={{ height: "121px" }} className="w-full rounded-xl relative">
                      <img
                        src={`${configs().imageUrl}/${item}`}
                        alt={index}
                        className="w-full h-full object-cover rounded-xl"
                      />
                      <div
                        onClick={() => removeImage(item)}
                        className="absolute top-2 ts-shadow right-2 w-10 h-10 ts-base-bg-white z-10 flex justify-center items-center rounded-xl cursor-pointer p-2"
                      >
                        <HiTrash size={20} className="ts-accent -mt-1" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className="w-full flex justify-between">
            <Button
              onClick={approveBtnHandler}
              loading={loading}
              disabled={disabled}
              bcolor="primary"
              classNameContainer="ts-half-width"
              className="w-full rounded-xl h-12"
            >
              {type == "edit" ? "ویرایش قطعه" : "ثبت قطعه"}
            </Button>
            <Button
              onClick={onClose}
              bcolor="primaryTransparent"
              classNameContainer="ts-half-width"
              className="w-full rounded-xl h-12"
            >
              لغو
            </Button>
          </div>
        </div>
      </BottomModal>
      <CarsModal
        isOpen={carModalOpen}
        onClose={() => setCarModalOpen(false)}
        isSingleChoice
        selectedCars={car}
        selectCarHandler={(value) => {
          setInfo({ car: value });
        }}
      />

      <DeleteAllImagesModal
        isOpen={deleteAllModal}
        onClose={() => setDeleteAllModal(false)}
        onDone={removeAllImageHandler}
      />
    </>
  );
});

export { AddEditPartModal };

const styles = {};
