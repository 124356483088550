/* eslint-disable */
import React from "react";
import { NoResult } from "ui/components";
import { ProviderStaffCard } from "./ProviderStaffCard";

interface IProviderStaffsTabProps {
  staffs: any[];
}

const ProviderStaffsTab: React.FC<IProviderStaffsTabProps> = React.memo(({ staffs }) => {
  console.log(staffs);

  return (
    <>
      {staffs.length === 0 ? (
        <NoResult />
      ) : (
        staffs.map((item, index) => {
          return <ProviderStaffCard key={index} data={item} />;
        })
      )}
    </>
  );
});

export { ProviderStaffsTab };
