/* eslint-disable */
import { ConfigLink, negativeItems, positiveItems, tools, useQueryString } from "core";
import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { StarRating, Text } from "./";

interface IFeedbacksCardProps {
  data: any;
  forUser?: boolean;
}

const FeedbacksCard: React.FC<IFeedbacksCardProps> = React.memo(({ data, forUser = false }) => {
  const navigate = useNavigate();

  const [cardOpen, setCardOpen] = useState(false);
  const [positives, setPositives] = useState([]);
  const [negatives, setNegatives] = useState([]);

  const separateFeedback = (feedback) => {
    if (feedback.length !== 0) {
      let pos: any = positives;
      let neg: any = negatives;
      Object.keys(feedback).map((item) => {
        if (feedback[item] === true) {
          pos.push(positiveItems.filter((pos) => pos.value === item)[0]);
          setPositives(pos);
        } else if (feedback[item] === false) {
          neg.push(negativeItems.filter((neg) => neg.value === item)[0]);
          setNegatives(neg);
        }
      });
    }
  };

  useEffect(() => {
    if (data) {
      separateFeedback(data);
    }
  }, [data]);

  const userClickHandler = () => {
    if (data.userInfo) {
      navigate({ pathname: ConfigLink.userDetail, search: useQueryString({ id: data.userInfo.id }) });
    }
  };

  const workshopClickHandler = () => {
    if (data.workshop) {
      navigate({ pathname: ConfigLink.serviceProviderDetail, search: useQueryString({ id: data.workshop.id }) });
    }
  };

  return (
    <div
      className={`w-full ${
        cardOpen ? "ts-feedback-box-open" : "ts-feedback-box-close"
      } ts-shadow mb-4 rounded-2xl bg-white flex flex-col justify-start items-start p-4`}
    >
      <div
        onClick={() => setCardOpen((prev) => !prev)}
        className={`w-full ${cardOpen ? "mb-4" : "mb-2.5"} flex justify-between items-center`}
      >
        <Text type="H6" className="ts-accent">
          {forUser ? data.workshop.name || "---" : data.userInfo.phoneNumber || "---"}
        </Text>

        <BiChevronDown
          size={23}
          className={`ts-accent transition-all duration-300 mr-1.5 ${cardOpen ? "rotate-180" : "rotate-0"}`}
        />
      </div>

      <div
        className={`w-full transition-all duration-300 flex ${
          cardOpen ? "justify-start" : "justify-between"
        } items-center mb-3`}
      >
        {cardOpen ? (
          <Text type="P4" className="w-28 ml-3 ts-gray-06">
            امتیاز داده شده:
          </Text>
        ) : (
          <Text type="P4" className="ts-gray-06">
            {data.createdAt ? tools.getCardsTime(data.createdAt) : "---"}
          </Text>
        )}
        <StarRating initialValue={data.score || 0} size={19} allowFraction readonly />
      </div>

      {!forUser && (
        <div className="w-full flex justify-start items-center mb-3">
          <Text type="P4" className="w-28 ml-3 ts-gray-06">
            نام و نام خانوادگی:
          </Text>
          <Text onClick={userClickHandler} type="H6" className="ts-primary cursor-pointer">
            {data.userInfo.name || "---"}
          </Text>
        </div>
      )}

      <div className="w-full flex justify-start items-center mb-3">
        <Text type="P4" className="w-28 ml-3 ts-gray-06">
          زمان ارسال:
        </Text>
        <Text type="P4" className="ts-accent">
          {data.createdAt
            ? `${new Date(data.createdAt).toLocaleDateString("fa-IR")} - ساعت ${new Date(
                data.createdAt,
              ).toLocaleTimeString("fa-IR", { hour: "2-digit", minute: "2-digit" })}`
            : "---"}
        </Text>
      </div>

      <div className="w-full flex justify-start items-center mb-3">
        <Text type="P4" className="w-28 ml-3 ts-gray-06">
          سرویس دهنده:
        </Text>
        <Text onClick={workshopClickHandler} type="H6" className="ts-primary cursor-pointer">
          {data.workshop.name || "---"}
        </Text>
      </div>
      {(positives.length !== 0 || negatives.length !== 0 || data.description) && (
        <hr className="w-full border-gray-200 mb-3" />
      )}
      {positives.length !== 0 && (
        <>
          <Text type="P4" className="ts-gray-06 mb-2">
            نقاط قوت:
          </Text>
          <ul className="list-disc mr-4">
            {positives.map((item: any) => {
              return <li className="ts-text-p4">{item.label}</li>;
            })}
          </ul>
        </>
      )}

      {negatives.length !== 0 && (
        <>
          <Text type="P4" className="ts-gray-06 mt-3 mb-2">
            نقاط ضعف:
          </Text>
          <ul className="list-disc mr-4">
            {negatives.map((item: any) => {
              return <li className="ts-text-p4">{item.label}</li>;
            })}
          </ul>
        </>
      )}

      {data.description && (
        <>
          <Text type="P4" className="ts-gray-06 mt-3 mb-2">
            توضیحات اضافی:
          </Text>
          <Text type="P4" className="ts-accent">
            {data.description}
          </Text>
        </>
      )}
    </div>
  );
});

export { FeedbacksCard };

const styles = {};
