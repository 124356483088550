/* eslint-disable */
import { configs } from "#configs";
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, useQueryString, vState } from "core";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { HiPencilAlt } from "react-icons/hi";
import { IoIosAddCircle } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  CardLoading,
  ContainerScroll,
  NoResult,
  SearchInput,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";
import { AddCarBrandModal, AddCarModelModal, CarModelsCard } from "./components";

interface IQueryStringTransfer {
  id: string;
  name: string;
  image?: string;
}

const CarBrandDetail = ({}) => {
  const { id, name, image } = useFetchQueryString<IQueryStringTransfer>();
  const metaTags = useChangeTitle("ویراژ - جزئیات برند");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [addCarModal, setAddCarModal] = useState(false);

  const [addModelModal, setAddModelModal] = useState(false);
  const [modelModalType, setModelModalType] = useState<"add" | "edit">("add");
  const [selectedModel, setSelectedModel] = useState<any>(null);

  const [models, setModels] = useState<any[]>([]);
  const [modelAll, setModelAll] = useState<any[]>([]);

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const getModel = async () => {
    await ApiInstance.car
      .getCarModels(id)
      .then((res) => {
        console.log(res);
        setModels(res.data.data);
        setModelAll(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "مدل های برند");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getModel();
  }, []);

  const searchHandler = (e, list) => {
    console.log(e);
    let result;
    if (e.length === 0) {
      result = list;
    } else {
      result = list.filter((item) => item.name.includes(e));
    }
    console.log(result);

    setModels(result);
    setLoading(false);
  };

  const callApi = (e, list) => {
    setLoading(true);
    searchHandler(e, list);
  };
  const [debouncedCallApi] = useState(() => _debounce((e, list) => callApi(e, list), 1100));

  const handleChange = (e) => {
    setSearchText(e);
    debouncedCallApi(e, modelAll);
  };

  const resetSearch = () => {
    setSearchText("");
    setLoading(true);
    setTimeout(() => {
      callApi("", modelAll);
    }, 1100);
  };

  const onEditDone = (item) => {
    navigate(
      {
        pathname: ConfigLink.brandDetail,
        search: useQueryString({ id: id, name: item.name, ...(item.image ? { image: item.image } : undefined) }),
      },
      { replace: true },
    );
  };

  const carClickHandler = (data) => {
    setSelectedModel(data);
    setModelModalType("edit");
    setAddModelModal(true);
  };

  const addModelHandler = (data) => {
    setSelectedModel(null);
    setModelModalType("add");
    setAddModelModal(true);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={60} className="">
        <Breadcrumb
          links={[
            {
              name: "برند و تیپ خودرو‌ها",
              active: true,
              link: ConfigLink.carBrandAndTypes,
            },
            {
              name: "جزئیات",
              active: false,
              link: "",
            },
          ]}
          className="mb-6"
        />
        <div className="w-full flex justify-between items-center mb-6">
          <div className="flex justify-center items-center">
            {image && (
              <div className="w-14 h-12 p-2 rounded-xl ts-car-logo-border ml-4 flex justify-center items-center">
                <img src={`${configs().imageUrl}/icons/${image}`} alt={name || "img"} className="object-cover" />
              </div>
            )}
            <Text type="H3" className="ts-accent pt-1">
              {name || "نام تعریف نشده"}
            </Text>
          </div>

          {snap.superAdmin && (
            <div className="w-10 h-10 ts-shadow cursor-pointer rounded-xl flex justify-center items-center">
              <HiPencilAlt size={23} className="ts-primary" onClick={() => setAddCarModal(true)} />
            </div>
          )}
        </div>

        <SearchInput
          value={searchText}
          onChange={handleChange}
          onReset={resetSearch}
          onSearch={() => callApi(searchText, modelAll)}
        />

        <div className="w-full flex justify-between items-center mt-6">
          {loading ? (
            <>
              <Skeleton className="w-28 mb-4" />
            </>
          ) : (
            models.length !== 0 && (
              <>
                <Text type="H6" className="ts-accent mb-4">
                  {`${models.length} مدل`}
                </Text>
              </>
            )
          )}
        </div>

        {snap.superAdmin && (
          <Button
            onClick={addModelHandler}
            textType="H6"
            bcolor="blackTransparent"
            icon={<IoIosAddCircle size={21} className="ts-accent" />}
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl mb-4"
          >
            اضافه کردن مدل جدید
          </Button>
        )}

        {loading ? (
          <CardLoading height={"56px"} className="mb-2" />
        ) : models.length === 0 ? (
          <NoResult />
        ) : (
          models.map((item, index) => {
            return <CarModelsCard data={item} key={item} editClicked={() => carClickHandler(item)} />;
          })
        )}
      </ContainerScroll>
      <AddCarBrandModal
        isOpen={addCarModal}
        onClose={() => setAddCarModal(false)}
        onDone={(data) => onEditDone(data)}
        data={{ id, name, image: image || "" }}
        id={id}
        type={"edit"}
      />
      <AddCarModelModal
        isOpen={addModelModal}
        onClose={() => setAddModelModal(false)}
        onDone={getModel}
        data={selectedModel}
        parentId={id}
        childId={selectedModel ? selectedModel.id : ""}
        type={modelModalType}
      />
    </>
  );
};

export { CarBrandDetail as default };
