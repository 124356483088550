/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IUserCardProps {
  data: any;
}

const UserCard: React.FC<IUserCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  const userClickHandler = () => {
    navigate({
      pathname: ConfigLink.userDetail,
      search: useQueryString({ id: data.userProfileId }),
    });
  };

  return (
    <div
      style={{ height: "76px" }}
      onClick={userClickHandler}
      className={`w-full bg-white flex justify-between items-center ts-shadow rounded-2xl p-3 mb-4`}
    >
      <div className="flex flex-col w-11/12 justify-start items-start">
        <Text type="H6" className="ts-accent truncate mb-2">
          {data.name || "---"}
        </Text>
        <Text type="P4" className="ts-gray-06">
          {data.phoneNumber || data.username || "---"}
        </Text>
      </div>
      <div className={`w-8 h-full ts-bg-gray-01 flex justify-center items-center rounded-xl`}>
        <BiChevronLeft size={24} className="ts-accent" />
      </div>
    </div>
  );
});

export { UserCard };

const styles = {};
