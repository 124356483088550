/* eslint-disable */
import { ApiInstance } from "core";
import React, { useEffect, useState } from "react";
import { BottomModal, Button, Text, useNotification } from "ui/components";

import { CheckPicker, Loader } from "rsuite";
import { RelatedServiceCard } from "./RelatedServiceCard";

interface IReminder {
  name: string;
  expiresType: string;
  expiresInDays?: number;
  expiresInKilometers?: number;
  disabled?: boolean | null;
  id?: string;
  recommendations?: any[];
}

interface IRelatedServicesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  value?: IReminder | null;
  id?: string | null;
}

const RelatedServicesModal: React.FC<IRelatedServicesModalProps> = React.memo(
  ({ isOpen, onClose, onDone, value, id }) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [allServices, setAllServices] = useState([]);

    const [pickerSelectedRelated, setPickerSelectedRelated] = useState([]);
    const [viewableRelatedServices, setViewableRelatedServices] = useState<any[]>([]);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const getAllServices = async () => {
      await ApiInstance.common
        .getServices(1, true, null)
        .then((res) => {
          const newArrayOfObj: any = res.data.data.map(({ id: value, name: label }) => ({
            value,
            label,
          }));
          setAllServices(newArrayOfObj);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "لیست یادآورها");
        });
    };

    const getRelatedServices = async () => {
      await ApiInstance.reminders
        .getRelatedServices(id)
        .then((res) => {
          console.log(res);
          if (res.data.data.length !== 0) {
            addToPickerSelected(res.data.data);
            addToViewableRelated(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "یادآورهای مرتبط");
        })
        .finally(() => {
          setDataLoading(false);
        });
    };

    const addToPickerSelected = (data) => {
      const services = data.map((item) => item.recommendService.id);
      setPickerSelectedRelated(services);
    };

    const addToViewableRelated = (data) => {
      let services = data.map((item) => {
        return { ...item.recommendService, kilometerRatio: item.kilometerRatio };
      });
      const newArrayOfObj: any = services.map(({ id: value, name: label, kilometerRatio: kilometerRatio }) => ({
        value,
        label,
        kilometerRatio: kilometerRatio > 0 ? kilometerRatio : 1,
      }));

      setViewableRelatedServices(newArrayOfObj);
    };

    useEffect(() => {
      if (allServices.length === 0) {
      }
      if (id && isOpen) {
        setPickerSelectedRelated([]);
        setViewableRelatedServices([]);
        setDataLoading(true);
        getAllServices();
        getRelatedServices();
      }
    }, [isOpen]);

    const addRelatedReminderHandler = async () => {
      const finalValue =
        viewableRelatedServices.length === 0
          ? []
          : viewableRelatedServices.map(({ value: service, kilometerRatio: kilometerRatio }) => ({
              service,
              kilometerRatio,
            }));

      console.log(finalValue);

      await ApiInstance.reminders
        //@ts-ignore
        .addRelatedServices(id, finalValue)
        .then((res) => {
          console.log("add", res);
          useNotification("تغییرات روی یادآورهای مرتبط اعمال شد", "success", "یادآورهای مرتبط");
          onDone();
          setTimeout(() => {
            onClose();
          }, 1500);
        })
        .catch((err) => {
          console.log("errAdd", err);
          useNotification(`مشکلی رخ داده است. لطفا مجددا امتحان کنید.`, "error", "یادآور مرتبط");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const reminderHandler = () => {
      setLoading(true);
      // if (pickerSelectedRelated.length !== 0) {
      addRelatedReminderHandler();
      // }
    };

    const addRelatedHandler = (value, selected) => {
      if (selected) {
        setPickerSelectedRelated(value);

        let serObj;
        if (value.indexOf(selected) !== -1) {
          serObj = [
            ...viewableRelatedServices,
            ...allServices
              .filter((item: any) => item.value === selected)
              .map((item: any) => {
                return { ...item, kilometerRatio: 1 };
              }),
          ];
        } else {
          serObj = [...viewableRelatedServices.filter((item: any) => item.value !== selected)];
          console.log(serObj);
        }
        setViewableRelatedServices(serObj);
      } else {
        setViewableRelatedServices([]);
      }
    };

    const removeRelatedServiceHandler = (singleItem) => {
      const finalObj = viewableRelatedServices.filter((item: any) => item.value !== singleItem);
      const service = pickerSelectedRelated.filter((item: any) => item !== singleItem);

      setViewableRelatedServices(finalObj);
      setPickerSelectedRelated(service);
    };

    const incrementRelatedServiceRatioHandler = (index) => {
      let finalObj: any[] = [...viewableRelatedServices];
      finalObj[index].kilometerRatio += 0.5;

      setViewableRelatedServices(finalObj);
    };

    const decrementRelatedServiceRatioHandler = (index) => {
      let finalObj: any[] = [...viewableRelatedServices];
      if (finalObj[index].kilometerRatio > 0.5) finalObj[index].kilometerRatio -= 0.5;

      setViewableRelatedServices(finalObj);
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent mb-6">
            {`تعریف یادآور مرتبط با ${value?.name || ""}`}
          </Text>
          <Text type="H6" className="ts-accent mb-2">
            یادآور های مرتبط
          </Text>
          <Text type="P5" className="ts-accent mb-2">
            می توانید چند گزینه را انتخاب کنید.
          </Text>
          <CheckPicker
            disabled={dataLoading}
            size="lg"
            countable={false}
            searchable={true}
            data={allServices}
            value={pickerSelectedRelated}
            placement="auto"
            onClean={() => {
              setViewableRelatedServices([]);
              setPickerSelectedRelated([]);
            }}
            onChange={(value, e: any) => addRelatedHandler(value, e.target.value)}
            block
            placeholder={dataLoading ? "لطفا صبر کنید..." : "انتخاب کنید"}
            className={`w-full h-12 placeholder:text-black outline-none rounded-xl mb-2`}
            renderValue={(value) => {
              return <p>{`انتخاب کنید`}</p>;
            }}
          />
          {dataLoading ? (
            <div className="w-full flex justify-center items-center mt-6 mb-4">
              <Loader size="sm" vertical content="لطفا صبر کنید..." className="ts-primary" />
            </div>
          ) : (
            <div className="w-full flex flex-wrap overflow-hidden pt-6 pb-4">
              {pickerSelectedRelated.length !== 0 &&
                viewableRelatedServices.map((item: any, index) => {
                  return (
                    <RelatedServiceCard
                      data={item}
                      onRemove={(id) => removeRelatedServiceHandler(id)}
                      increment={() => incrementRelatedServiceRatioHandler(index)}
                      decrement={() => decrementRelatedServiceRatioHandler(index)}
                    />
                  );
                })}
            </div>
          )}
          <Button
            onClick={reminderHandler}
            disabled={dataLoading}
            loading={loading}
            bcolor="primary"
            textType="H5"
            classNameContainer="w-full"
            className="w-full rounded-lg h-12"
          >
            اضافه کردن یادآور مرتبط
          </Button>
        </div>
      </BottomModal>
    );
  },
);

export { RelatedServicesModal };

const styles = {};
