/* eslint-disable */
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString } from "core";
import moment from "moment";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Breadcrumb, ContainerScroll, Text, useNotification } from "ui/components";
import {
  DeleteWorkshopModal,
  ProviderAdmissionsTab,
  ProviderFestivalsTab,
  ProviderInfoSection,
  ProviderPartsTab,
  ProviderRatingSection,
  ProviderReportsTab,
  ProviderStaffsTab,
  ServiceProviderTabBar,
} from "./components";

interface IQueryStringTransfer {
  id: string;
}

const ServiceProviderDetail = ({}) => {
  const metaTags = useChangeTitle("ویراژ - سرویس دهندگان");
  let { id } = useFetchQueryString<IQueryStringTransfer>();

  const [detail, setDetail] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [isShrunk, setShrunk] = useState(false);

  const [deleteWorkshopModalOpen, setDeleteWorkshopModalOpen] = useState(false);

  const [festivals, setFestivals] = useState<any>(null);

  const getDetails = async () => {
    await ApiInstance.common
      .getServiceProviderDetail(id)
      .then((res) => {
        console.log(res);
        const data = res.data.data;
        setDetail(data);
        if (data.festivalActivatedAt) {
          setFestivals({
            festivalActivatedAt: data.festivalActivatedAt,
            festivalTerminatedAt: moment(data.festivalActivatedAt).add(14, "days"),
            festivalCount: data.festivalCount,
            festivalStatus: data.festivalStatus,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "خودروهای ثبت شده");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDetails();
  }, []);

  // Tabs

  const tabChangedHandler = (number) => {
    setActiveTab(number);
  };

  useEffect(() => {
    const updateScrollDirection = () => {
      const tab = document.getElementById("providers-tabs");
      if (tab) {
        if (tab.getBoundingClientRect().top <= 89) {
          setShrunk(true);
        } else {
          setShrunk(false);
        }
      }
    };
    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, []);

  const providerTabWrapper = () => {
    if (activeTab === 1) {
      return <ProviderAdmissionsTab id={id} />;
    } else if (activeTab === 2) {
      return <ProviderStaffsTab staffs={detail.agent || []} />;
    } else if (activeTab === 3) {
      return <ProviderPartsTab id={id} />;
    } else if (activeTab === 4) {
      return <ProviderReportsTab id={id} />;
    } else if (activeTab === 5) {
      return <ProviderFestivalsTab id={id} festivals={festivals} />;
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={20} className="">
        <Breadcrumb
          links={[
            {
              name: "لیست سرویس دهندگان",
              active: true,
              link: ConfigLink.serviceProviders,
            },
            {
              name: "جزییات کسب و کار",
              active: false,
              link: ``,
            },
          ]}
          className="mb-9"
        />

        {loading ? (
          <Skeleton className="w-36 h-6 mb-6" />
        ) : (
          <div className="w-full flex justify-start items-center mb-6">
            <Text type="H3" className="ts-accent">
              {detail.name || "---"}
            </Text>
            {detail.archive && (
              <div className="ts-bg-gray-03 mr-2 flex justify-center items-center rounded-full px-4 py-1">
                <Text type="HM" className="ts-gray-06">
                  آرشیو شده
                </Text>
              </div>
            )}
          </div>
        )}

        {/* feedback section */}
        {loading ? (
          <Skeleton containerClassName="w-full" className="w-full h-11 mb-6" />
        ) : (
          detail.feedbacks &&
          Object.keys(detail.feedbacks).length !== 0 && <ProviderRatingSection data={detail.feedbacks} id={id} />
        )}

        {loading ? (
          <Skeleton containerClassName="w-full" style={{ height: "310px" }} className="w-full rounded-2xl mb-8" />
        ) : (
          <ProviderInfoSection data={detail} onDelete={() => setDeleteWorkshopModalOpen(true)} />
        )}

        <div id="providers-tabs" className={`w-full transition-all duration-500 ts-car-status-info bg-white`}>
          <div
            className={`w-full transition-all duration-75 ${
              isShrunk ? "fixed top-16 left-0 px-6 max-2xs:px-3 pt-9 bg-white z-20" : ""
            }`}
          >
            <ServiceProviderTabBar activeTab={activeTab} onActiveChanged={(number) => tabChangedHandler(number)} />
          </div>
        </div>

        {providerTabWrapper()}
      </ContainerScroll>

      <DeleteWorkshopModal
        isOpen={deleteWorkshopModalOpen}
        onClose={() => setDeleteWorkshopModalOpen(false)}
        onEnd={() => setDetail({ ...detail, archive: true })}
        id={id}
      />
    </>
  );
};

export { ServiceProviderDetail as default };
