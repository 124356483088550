/* eslint-disable */
import React, { useEffect } from "react";
import { BottomModal, Button, Text } from "ui/components";

interface IDeleteAllImagesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
}

const DeleteAllImagesModal: React.FC<IDeleteAllImagesModalProps> = React.memo(({ isOpen, onClose, onDone }) => {
  useEffect(() => {}, []);

  const deleteBtnHandler = () => {
    onDone();
    onClose();
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <Text type="H4" className="ts-accent">
          حذف همه عکس‌ها
        </Text>
        <Text type="P3" className="ts-accent my-4">
          آیا میخواهید همه عکس‌های درخواست را حذف کنید؟
        </Text>
        <div className=" w-full flex justify-between items-center">
          <Button
            onClick={deleteBtnHandler}
            bcolor="black"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            بله، حذف شوند
          </Button>

          <Button
            onClick={() => onClose()}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            خیر
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { DeleteAllImagesModal };

const styles = {};
