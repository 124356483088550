/* eslint-disable */
import { ConfigLink, tools, useQueryString } from "core";
import React from "react";
import { HiCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface ISupplierCardProps {
  data: any;
}

const SupplierCard: React.FC<ISupplierCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  const workshopClickHandler = (id) => {
    navigate({ pathname: ConfigLink.serviceProviderDetail, search: useQueryString({ id }) });
  };

  return (
    <div key={data.id} className="w-full rounded-2xl ts-shadow p-4 mb-3 flex flex-col justify-start items-start">
      <Text type="H6" onClick={() => workshopClickHandler(data.workshop.id)} className="ts-primary cursor-pointer mb-2">
        {data.workshop?.name || "---"}
      </Text>
      <div className="flex justify-center items-center mb-3">
        <HiCheckCircle size={18} className="ts-success -mt-0.5" />
        <Text type="HM" className="ts-success mr-1">
          {`تایید تامین قطعه در تاریخ ${data.createdAt ? new Date(data.createdAt).toLocaleDateString("fa-IR") : "---"}`}
        </Text>
      </div>
      <Text type="P4" className="ts-gray-06">
        {`قیمت پیشنهادی: ${tools.thousandSeparator(parseFloat(data.price).toFixed(0).toString())} تومان`}
      </Text>
    </div>
  );
});

export { SupplierCard };

const styles = {};
