import Axios from "axios";
import { configs } from "configs";
import { vState } from "core";
import NProgress from "nprogress";
import { keycloak } from "../../keycloak";
import { ServiceStorage } from "../services";

interface ConfigType {
  contentType?: string;
  auth?: boolean;
}

export const axios = Axios.create({
  baseURL: configs().endpointUrl,
});

axios.interceptors.request.use(
  (config) => {
    if (keycloak.authenticated && keycloak.idToken) {
      config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${keycloak.idToken}`,
      };
    }
    NProgress.start();
    return config;
  },
  (e) => Promise.reject(e),
);

axios.interceptors.response.use(
  (res) => {
    NProgress.done();
    return res;
  },
  (err) => {
    NProgress.done();
    if (err.response.status === 401) {
      console.log("401");
      const savedToken = ServiceStorage.getToken();
      if (keycloak.idToken && keycloak.idToken !== savedToken) {
        console.log("SAVED", savedToken);
        console.log("KEYCLOAK", keycloak.idToken);

        ServiceStorage.setToken(keycloak.idToken);
        err.config.headers["Authorization"] = `Bearer ${keycloak.idToken}`;
        axios.request(err.config);
      } else {
        vState.isAuthenticated = false;
        vState.userInfo = {
          groups: [],
          id: "",
          username: "",
          mobile: "",
          name: "",
          roles: [],
          userProfileId: "",
        };
        ServiceStorage.removeAuth();
        ServiceStorage.removeToken();
        keycloak.logout();
        window.location.href = `/`;
      }
    }

    return Promise.reject(err);
  },
);
