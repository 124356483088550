/* eslint-disable */
import { ConfigLink, tools, useQueryString } from "core";
import React, { useEffect } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IPlateHistoryCardProps {
  data: any;
  plateId?: string;
}

const PlateHistoryCard: React.FC<IPlateHistoryCardProps> = React.memo(({ data, plateId }) => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const seeDetailHandler = () => {
    navigate({
      pathname: ConfigLink.plateHistory,
      search: useQueryString({ id: data.id || "", plateId }),
    });
  };

  return (
    <div
      onClick={seeDetailHandler}
      className={`w-full bg-white ts-shadow rounded-2xl p-4 mb-4 flex justify-between items-center`}
    >
      <div className="h-full flex flex-col justify-between items-start">
        <Text type="H5" className="ts-accent mb-4">
          {data.updatedAt ? tools.getCardsTime(data.updatedAt) : "---"}
        </Text>

        <Text type="H6" className="ts-primary- ts-gray-06 cursor-pointer-">
          {data.name || "---"}
        </Text>
      </div>

      <BiChevronLeft size={30} className="ts-accent" />
    </div>
  );
});

export { PlateHistoryCard };

const styles = {};
