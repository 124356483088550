/* eslint-disable */
import { configs } from "configs";
import { ApiInstance, useObjectState, vState } from "core";
import { keycloak } from "keycloak";
import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { Uploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader/Uploader";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

type IType = "add" | "edit";
const env = import.meta.env;

interface IAddCarBrandModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: IType;
  data?: any;
  id?: string;
  onDone: (data?: any) => void;
}

const AddCarBrandModal: React.FC<IAddCarBrandModalProps> = React.memo(({ isOpen, onClose, type, data, id, onDone }) => {
  const snap = useSnapshot(vState);
  const uploader = React.useRef();

  const [imagesFiles, setImageFiles] = useState<FileType[]>([]);
  const [failedUploads, setFailedUploads] = useState<FileType[]>([]);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useObjectState<any>({
    name: "",
    image: "",
  });
  const { name, image } = values;

  useEffect(() => {
    if (isOpen && data) {
      console.log(data);
      setValues(data);
      if (data && data.image) {
        setImageFiles([
          {
            name: `لوگو ${data.name || ""}`,
            fileKey: data.image,
            url: `${configs().imageUrl}/icons/${data.image}`,
          },
        ]);
      }
    }
  }, [isOpen]);

  const addBrand = async (value) => {
    await ApiInstance.car
      .addCar(value)
      .then((res) => {
        console.log(res);
        useNotification("برند جدید به لیست برندها اضافه شد.", "success", "اضافه کردن برند جدید");
        onDone();
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "اضافه کردن برند جدید");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editBrand = async (value) => {
    await ApiInstance.car
      .editCar(id || "", value)
      .then((res) => {
        console.log(res);
        useNotification("برند ویرایش شد", "success", "ویرایش برند");
        onDone(value);
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "ویرایش برند");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actionFunction = (image) => {
    setLoading(true);
    const originalData = {
      name: name,
      image: image || null,
    };
    type === "add" ? addBrand(originalData) : editBrand(originalData);
  };

  const imageHandler = (file) => {
    console.log(file);
    setImageFiles(file);

    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   setFile(reader.result);
    // };
    // reader.readAsDataURL(file[0].blobFile);
  };

  const removeHandler = (file) => {
    setValues({ image: "" });
  };

  const handleSuccess = async (res, file) => {
    console.log(res);
    setValues({ image: res.id });
    setFailedUploads([]);
    actionFunction(res.id);
  };

  const handleUploadError = (error, file) => {
    setFailedUploads((prevFailedUploads) => [...prevFailedUploads, file]);
    // setFile(null);
  };

  const handleRetryUpload = (file) => {
    // Remove the file from the list of failed uploads
    setFailedUploads([]);

    // Trigger the upload of the file again
    if (uploader) {
      // @ts-ignore
      uploader.current.start(file);
    }
  };

  const saveBrandHandler = () => {
    if (imagesFiles.length === 0 || (data && data.image === imagesFiles[0].fileKey)) {
      actionFunction(data && data.image && image.length !== 0 ? data.image : null);
    } else {
      if (uploader) {
        // @ts-ignore
        uploader.current.start();
      }
    }
  };

  return (
    <>
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <Text type="H4" className="ts-accent text-right mb-6">
          {type === "add" ? "اضافه کردن برند جدید" : "ویرایش برند"}
        </Text>

        <InputField label="نام برند" value={name} onChange={(e) => setValues({ name: e })} placeholder="برند خودرو" />

        <div className="w-full flex flex-col justify-start items-start">
          <Text type="P3" className="ts-accent text-right mb-2">
            لوگو برند را از طریق دکمه زیر اضافه کنید. حجم عکس کمتر از یک مگابایت و سایز آن ۸۰ * ۸۰ باشد.
          </Text>
          {
            <Uploader
              ref={uploader}
              disabled={imagesFiles.length === 1}
              className={`w-full ts-part-uploader mb-6 rounded-2xl ${imagesFiles.length === 1 && "ts-hide-upload"}`}
              listType="picture"
              accept="image/*"
              action={`${configs().endpointUrl}/files/upload`}
              headers={{
                Authorization: `Bearer ${keycloak.idToken}`,
              }}
              autoUpload={false}
              fileList={imagesFiles}
              onChange={(f) => imageHandler(f)}
              // onUpload={(f) => uploadImages(f)}
              onRemove={(file) => removeHandler(file)}
              onError={(err, file) => handleUploadError(err, file)}
              onSuccess={(res, file) => handleSuccess(res, file)}
              onReupload={(file) => handleRetryUpload(file)}
            >
              <div className="w-full flex flex-col bg-white justify-center items-center py-2">
                <IoIosAddCircle size={24} className="ts-gray-06" />
                <Text type={"P4"} className={`mt-2 ts-gray-08`}>
                  آپلود لوگو
                </Text>
              </div>
            </Uploader>
          }
        </div>

        <Button
          onClick={saveBrandHandler}
          loading={loading}
          disabled={name.length === 0}
          bcolor="primary"
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl"
        >
          ذخیره
        </Button>
      </BottomModal>
    </>
  );
});

export { AddCarBrandModal };

const styles = {};
