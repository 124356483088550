/* eslint-disable */
import NoAccess from "assets/images/no-access.svg";
import { ConfigLink } from "core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

//Images

interface IUnauthorizedAccessProps {}

const UnauthorizedAccess: React.FC<IUnauthorizedAccessProps> = React.memo(({}) => {
  const navigate = useNavigate();

  const homePageHandler = () => {
    navigate(ConfigLink.homePage);
    // vState.unauthorizedAccess = false;
  };

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-center items-center">
      <img src={NoAccess} alt="no-access" className="w-full md:w-2/3 md:h-2/3 mb-16" />
      <div className="w-full px-6 flex flex-col justify-center items-center text-center">
        <Text type="H5" className="ts-gray-07">
          شما به این صفحه دسترسی ندارید!
        </Text>
        <Text type="P4" className="ts-gray-07 mt-3 mb-6">
          در حال تغییر مسیر...
        </Text>
        {/* <Button
          onClick={homePageHandler}
          bcolor="primary"
          classNameContainer="w-full"
          className="w-full md:w-2/3 rounded-xl h-12 xs:mb-10"
        >
          صفحه اصلی
        </Button> */}
      </div>
    </div>
  );
});

export { UnauthorizedAccess as default };
