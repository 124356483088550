/* eslint-disable */
import noSearchFound from "assets/images/no-search-found.svg";
import notFound from "assets/images/search-empty.svg";
import React, { useEffect } from "react";
import { Text } from "./";

interface INoResultProps {
  note?: string;
  forSearch?: boolean;
}

const NoResult: React.FC<INoResultProps> = React.memo(({ note = "", forSearch = false }) => {
  useEffect(() => {}, []);

  return (
    <div className="w-full flex flex-col justify-center items-center mt-20">
      <img src={forSearch ? noSearchFound : notFound} alt="not-found" />
      <Text type="P3" className="ts-gray-07 mt-4">
        {note ? note : `نتیجه ای یافت نشد`}
      </Text>
    </div>
  );
});

export { NoResult };

const styles = {};
