/* eslint-disable */
import { ConfigLink, tools, useQueryString } from "core";
import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Button, Text } from "ui/components";

interface IPlateCardProps {
  data: any;
  forShow?: boolean;
  forAdmission?: boolean;
}

const PlateCard: React.FC<IPlateCardProps> = React.memo(({ data, forShow = false, forAdmission = false }) => {
  const navigate = useNavigate();

  const [cardOpen, setCardOpen] = useState(true);

  useEffect(() => {}, []);

  const seeDetailHandler = () => {
    navigate({
      pathname: ConfigLink.plateDetail,
      search: useQueryString({ id: forAdmission ? data.plate.id : data.id }),
    });
  };

  return (
    <div
      className={`w-full ${
        cardOpen ? "ts-plate-box-open" : "ts-plate-box-close"
      } bg-white ts-shadow rounded-2xl p-4 mb-4`}
    >
      <div className="w-full flex justify-between items-center mb-6">
        <div
          onClick={() => setCardOpen((prev) => !prev)}
          className={`flex ${forShow ? "w-full justify-between" : "justify-start"} items-center`}
        >
          <Text type="H5" className="ts-accent ml-2 mt-1">
            {tools.setPlateNumber(forAdmission ? data.plate.plateNumber : data.plateNumber)}
          </Text>
          <HiChevronDown size={22} className={`ts-accent -mt-0.5 ${cardOpen ? "ts-arrow-up" : "ts-arrow-down"}`} />
          {data.archive && (
            <div className="ts-bg-gray-03 flex justify-center items-center rounded-full px-4 py-1 -mt-0.5 mr-2">
              <Text type="HM" className="ts-gray-06">
                آرشیو شده
              </Text>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-28 ml-3">
          برند و مدل خودرو:
        </Text>
        <Text type="P5" className="ts-accent">
          {forAdmission
            ? data.plate.car
              ? data.plate?.car?.name || "---"
              : data.plate.carName
              ? data.plate.carName || "---"
              : "---"
            : data.car
            ? data.car.name || "---"
            : data.carName
            ? data.carName
            : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-28 ml-3">
          میزان کارکرد:
        </Text>
        <Text type="P5" className="ts-accent">
          {data.kilometer ? `${tools.thousandSeparator(data.kilometer)} کیلومتر` : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center">
        <Text type="HM" className="ts-gray-06 w-28 ml-3">
          زمان اضافه شدن:
        </Text>
        <Text type="P5" className="ts-accent">
          {data.createdAt ? tools.getCardsTime(data.createdAt) : "---"}
        </Text>
      </div>

      {!forShow && (
        <div className=" w-full flex justify-between items-center mt-6">
          <Button
            onClick={seeDetailHandler}
            bcolor="primaryTransparent"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12"
          >
            مشاهده جزئیات
          </Button>
        </div>
      )}
    </div>
  );
});

export { PlateCard };

const styles = {};
