/* eslint-disable */
import { ConfigLink, vState } from "core";
import { useAuth } from "hooks";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Drawer } from "rsuite";
import { Text } from "ui/components";
import { useSnapshot } from "valtio";
import metadata from "../../../metadata.json";
import { AccordionMenu } from "./AccrodionMeu";

interface IDrawerMenuProps {
  open: boolean;
  onClose: () => void;
}

const env = import.meta.env;

const DrawerMenu: React.FC<IDrawerMenuProps> = React.memo(({ open, onClose }) => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let location = useLocation();
  const auth = useAuth();

  React.useEffect(() => {
    // trigger on url change. In order to close drawer
    if (open) {
      onClose();
    }
  }, [location]);

  return (
    <Drawer size="xs" className="w-3/4" open={open} onClose={onClose}>
      <Drawer.Body className="ts-menu-bg p-0 m-0">
        <div className="w-full h-full flex flex-col justify-between items-start">
          <div className="w-full">
            <Text
              onClick={() => navigate(ConfigLink.reminders)}
              type="H5"
              className={`w-full p-6 mt-24 ${
                window.location.pathname === ConfigLink.reminders ? "ts-primary bg-white" : "ts-gray-08"
              } cursor-pointer`}
            >
              لیست یادآورها
            </Text>

            <AccordionMenu
              title="کسب و کارها"
              menuItems={[
                {
                  label: "لیست کسب و کارها",
                  url: ConfigLink.serviceProviders,
                },
                {
                  label: "فعالیت کسب و کارها",
                  url: ConfigLink.serviceProvidersActivity,
                },
              ]}
            />

            <Text
              onClick={() => navigate(ConfigLink.plates)}
              type="H5"
              className={`w-full p-6 ${
                window.location.pathname === ConfigLink.plates ? "ts-primary bg-white" : "ts-gray-08"
              } cursor-pointer`}
            >
              لیست خودرو های ثبت شده
            </Text>

            <Text
              onClick={() => navigate(ConfigLink.cities)}
              type="H5"
              className={`w-full p-6 ${
                window.location.pathname === ConfigLink.cities ? "ts-primary bg-white" : "ts-gray-08"
              } cursor-pointer`}
            >
              شهرها و استان‌ها
            </Text>

            <Text
              onClick={() => navigate(ConfigLink.usersList)}
              type="H5"
              className={`w-full p-6 ${
                window.location.pathname === ConfigLink.usersList ? "ts-primary bg-white" : "ts-gray-08"
              } cursor-pointer`}
            >
              لیست کاربران
            </Text>

            <AccordionMenu
              title="قطعات"
              menuItems={[
                {
                  label: "لیست قطعات",
                  url: ConfigLink.partsList,
                },
                {
                  label: "درخواست قطعات",
                  url: ConfigLink.partRequests,
                },
                {
                  label: "مدیریت دسته بندی قطعات",
                  url: ConfigLink.partCategories,
                },
              ]}
            />

            <AccordionMenu
              title="گزارشات"
              menuItems={[
                {
                  label: "تخلفات",
                  url: ConfigLink.reports,
                },
                {
                  label: " نظرات مشتریان",
                  url: ConfigLink.feedbacks,
                },
              ]}
            />

            <AccordionMenu
              title="پیامک"
              menuItems={[
                {
                  label: "پیامک‌های سرویس دهندگان",
                  url: ConfigLink.bulkSmsList,
                },
              ]}
            />

            <Text
              onClick={() => navigate(ConfigLink.carBrandAndTypes)}
              type="H5"
              className={`w-full p-6 ${
                window.location.pathname === ConfigLink.carBrandAndTypes ? "ts-primary bg-white" : "ts-gray-08"
              } cursor-pointer`}
            >
              برند و تیپ خودرو‌ها
            </Text>

            {snap.superAdmin && (
              <Text
                onClick={() => navigate(ConfigLink.versions)}
                type="H5"
                className={`w-full p-6 ${
                  window.location.pathname === ConfigLink.versions ? "ts-primary bg-white" : "ts-gray-08"
                } cursor-pointer`}
              >
                مدیریت بروزرسانی ها
              </Text>
            )}

            {snap.superAdmin && (
              <AccordionMenu
                title="تنظیمات پایه"
                menuItems={[
                  // {
                  //   label: "مدیریت امکانات",
                  //   url: ConfigLink.featuresManagement,
                  // },
                  {
                    label: "انواع کسب و کار",
                    url: ConfigLink.providerFeatures,
                  },
                  {
                    label: "تنظیمات مالک خودرو",
                    url: ConfigLink.ownerFeatures,
                  },
                  // {
                  //   label: "مدیریت بسته ها",
                  //   url: ConfigLink.packageManagement,
                  // },
                ]}
              />
            )}

            <Text onClick={() => auth.logout()} type="H5" className="w-full p-6 ts-secondary-red mt-6-- cursor-pointer">
              خروج
            </Text>
          </div>

          <div className="w-full">
            <div className="w-full flex justify-center items-center pb-10 ">
              <Text type="P3" className="ts-gray-06 ml-2">{`ویراژ ©`}</Text>
              <Text type="P3" className="ts-gray-06">
                {/* @ts-ignore */}
                {`نسخه ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}
              </Text>
            </div>
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );
});

export { DrawerMenu };

const styles = {};
