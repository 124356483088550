/* eslint-disable */
import React from "react";
import { Text } from "./";

interface ITabProps {
  tabNumber: number;
  activeTabNumber: number;
  title: string;
  onClick: (n: number) => void;
  className?: string;
}

const Tab: React.FC<ITabProps> = React.memo(({ tabNumber, title, activeTabNumber, onClick, className = "" }) => {
  return (
    <div
      onClick={() => onClick(tabNumber)}
      className={`w-max p-3 cursor-pointer  transition-all duration-300 -mb-px flex justify-center items-center h-12 ${
        activeTabNumber === tabNumber && "ts-bg-primary-light rounded-tl-xl rounded-tr-xl border-b-2 ts-active-tab-line"
      } ${className}`}
    >
      <Text
        type={activeTabNumber === tabNumber ? "H5" : "P3"}
        className={`w-max transition-all duration-300 ${activeTabNumber === tabNumber ? "ts-primary" : "ts-gray-07"}`}
      >
        {title}
      </Text>
    </div>
  );
});

export { Tab };

const styles = {};
