/* eslint-disable */
import { ConfigLink, useQueryString, vState } from "core";
import React from "react";
import { IoCarSport } from "react-icons/io5";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";
import { useSnapshot } from "valtio";

interface IPlateOwnerInformationProps {
  data: any;
  loading: boolean;
  onTransferOwnership: () => void;
}

const PlateOwnerInformation: React.FC<IPlateOwnerInformationProps> = React.memo(
  ({ data, loading, onTransferOwnership }) => {
    const snap = useSnapshot(vState);
    const navigate = useNavigate();

    const userDetailHandler = () => {
      if (data && data.createdBy && data.createdBy?.name && data.createdBy?.userProfileId) {
        navigate({ pathname: ConfigLink.userDetail, search: useQueryString({ id: data.createdBy?.userProfileId }) });
      }
    };

    const callHandler = () => {
      if (data && data.createdBy && data?.createdBy?.mobile) {
        window.open(`tel:${data?.createdBy?.mobile || ""}`, "_self");
      }
    };

    return (
      <div className="w-full rounded-xl ts-shadow p-6 mb-8 flex flex-col justify-start items-start">
        <div className="w-full flex justify-between items-center mb-8 ">
          <Text type="H4" className="ts-accent">
            اطلاعات مالک
          </Text>
          {snap.superAdmin && (
            <div onClick={onTransferOwnership} className="flex justify-center items-center cursor-pointer">
              <Text type="H6" className="ts-primary ml-1">
                انتقال مالکیت
              </Text>
              <IoCarSport size={23} className="ts-primary -mt-1" />
            </div>
          )}
        </div>

        <div className="w-full flex justify-start items-center mb-4">
          <Text type="H6" className="ts-gray-06 w-28 ml-2">
            نام مالک:
          </Text>
          {loading ? (
            <Skeleton className="w-24" />
          ) : (
            // check if info available send to user detail page
            <Text
              onClick={userDetailHandler}
              type={data?.createdBy?.name && data?.createdBy?.userProfileId ? "H5" : "H6"}
              className={`${
                data?.createdBy?.name && data?.createdBy?.userProfileId
                  ? "ts-primary cursor-pointer hover:underline"
                  : "ts-accent"
              } ts-sp-detail-item `}
            >
              {data?.createdBy?.name || "---"}
            </Text>
          )}
        </div>

        <div className="w-full flex justify-start items-center mb-4">
          <Text type="H6" className="ts-gray-06 w-28 ml-2">
            شماره تماس:
          </Text>
          {loading ? (
            <Skeleton className="w-24" />
          ) : (
            <Text onClick={callHandler} type="P4" className="ts-accent ts-sp-detail-item">
              {data?.createdBy?.mobile || "---"}
            </Text>
          )}
        </div>
      </div>
    );
  },
);

export { PlateOwnerInformation };
