/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { CardLoading, CustomLoader, NoResult, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { InviteeCard } from "./InviteeCard";

interface IUserInviteeTabProps {
  id: string;
}

const UserInviteeTab: React.FC<IUserInviteeTabProps> = React.memo(({ id }) => {
  const snap = useSnapshot(vState);

  const [tabLoading, setTabLoading] = useState(true);
  const [invitees, setInvitees] = useState<any[]>([]);
  const [inviteesPage, setInviteesPage] = useState(1);
  const [inviteesCount, setInviteesCount] = useState(0);

  const getInvitees = async () => {
    await ApiInstance.common
      .getUsersInvitee(id, inviteesPage)
      .then((res) => {
        console.log("Invitees", res);
        setInviteesCount(res.data.meta.total);
        if (inviteesPage > 1) {
          let data = [...invitees, ...res.data.data];
          setInvitees(data);
          setInviteesPage((prev) => prev + 1);
        } else {
          setInvitees(res.data.data);
        }
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "لیست دعوت شدگان");
      })
      .finally(() => {
        setTabLoading(false);
      });
  };

  useEffect(() => {
    getInvitees();
  }, [id]);

  useEffect(() => {
    if (snap.isEndOfPage && inviteesCount > invitees.length && !tabLoading) {
      setInviteesPage((prev) => prev + 1);
      getInvitees();
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      {tabLoading ? (
        <CardLoading height={"76px"} />
      ) : invitees.length === 0 ? (
        <NoResult />
      ) : (
        invitees.map((item, index) => {
          return <InviteeCard key={index} data={item} />;
        })
      )}
      {snap.isEndOfPage && !tabLoading && inviteesCount > invitees.length && invitees.length !== 0 && (
        <div className="w-full flex justify-center">
          <CustomLoader />
        </div>
      )}
    </>
  );
});

export { UserInviteeTab };

const styles = {};
