/* eslint-disable */
import { useObjectState } from "core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { BiChevronDown } from "react-icons/bi";
import { HiCalendar } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import DatePicker, { DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/purple.css";
import { SelectPicker } from "rsuite";
import { CarsModal } from "ui/components/CarsModal";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { BottomModal, Button, Text } from "../../../components";
import { carMonthlyUsage } from "./../../../../core/services/carValues";

interface IPlatesFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  filter: any;
  onFilterChanged: (f) => void;
}

const PlatesFilterModal: React.FC<IPlatesFilterModalProps> = React.memo(
  ({ isOpen, onClose, filter, onFilterChanged }) => {
    const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];
    const cityPicker = useRef();
    const datePickerRef = useRef();

    const snap = useSnapshot(vState);

    const [carModalOpen, setCarModalOpen] = useState(false);
    const [modalFilters, setModalFilters] = useObjectState<any>({
      cars: null,
      monthlyUsage: "",
      date: [],
      dateLabel: [],
    });
    const { cars, monthlyUsage, date, dateLabel } = modalFilters;

    useEffect(() => {
      if (isOpen) {
        console.log(filter);

        setModalFilters({
          ...filter,
          ...(filter.date
            ? {
                dateLabel: [
                  new Date(filter.date[0]).toLocaleDateString("fa-IR"),
                  new Date(filter.date[1]).toLocaleDateString("fa-IR"),
                ],
              }
            : undefined),
        });
      }
    }, [isOpen]);

    const saveDateHandler = (sDate: any) => {
      if (sDate.length === 2) {
        setModalFilters({
          date: [moment(sDate[0].toDate()).toISOString(), moment(sDate[1].toDate()).toISOString()] as any,
          dateLabel: [sDate[0]?.toString(), sDate[1]?.toString()] as any,
        });
        // @ts-ignore
        datePickerRef.current?.closeCalendar();
      }
    };

    const removeAllFilters = () => {
      setModalFilters({
        cars: null,
        monthlyUsage: "",
        date: [],
        dateLabel: [],
      });
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <Text type="H4" className="ts-accent text-right mb-6">
            فیلتر
          </Text>

          {cars || date.length !== 0 || monthlyUsage.length !== 0 ? (
            <Text onClick={removeAllFilters} type="H6" className="ts-primary mb-6 cursor-pointer">
              حذف همه فیلترها
            </Text>
          ) : null}

          <div className="w-full flex flex-col justify-start items-start mb-6">
            <Text type="H6" className="ts-accent text-right mb-2">
              برند و تیپ خودرو
            </Text>
            <div
              onClick={() => setCarModalOpen(true)}
              className={`w-full rounded-xl cursor-pointer outline-none ts-car-input-border h-12 flex justify-between items-center px-4 py-3`}
            >
              <Text type="P4" className={`ts-accent`}>
                {cars
                  ? // ? cars.map((item: any) => item.name).join("، ")
                    cars.name
                  : "خودروی مورد نظر خود را انتخاب کنید"}
              </Text>
              <BiChevronDown size={20} className="ts-accent -mt-0.5" />
            </div>
          </div>

          <div className="w-full flex flex-col justify-start items-start mb-6">
            <Text type="H6" className="ts-accent text-right mb-2">
              میزان کارکرد
            </Text>
            <SelectPicker
              size="lg"
              placement="auto"
              data={carMonthlyUsage}
              value={monthlyUsage}
              onChange={(value) => setModalFilters({ monthlyUsage: value })}
              onClean={() => setModalFilters({ monthlyUsage: "" })}
              block
              cleanable
              placeholder="میزان کارکرد خودرو را انتخاب کنید"
              menuClassName="ts-workshops-picker"
              className={`ts-filter-picker w-full h-12 outline-none rounded-xl`}
            />
          </div>

          <Text type="H6" className="ts-accent text-right mb-2">
            تاریخ اضافه شدن
          </Text>
          <DatePicker
            ref={datePickerRef}
            range
            dateSeparator=" - "
            value={date}
            editable={false}
            onChange={saveDateHandler}
            weekDays={weekDays}
            maxDate={new DateObject({ calendar: persian })}
            calendar={persian}
            locale={persian_fa}
            containerClassName="w-full h-12 mb-8"
            className="purple"
            inputClass="w-full h-12"
            calendarPosition="top-center"
            mapDays={({ date }) => {
              //disable friday
              let isWeekend = [6, 6].includes(date.weekDay.index);

              if (isWeekend)
                return {
                  disabled: false,
                  style: { color: "#900" },
                };
            }}
            render={(value, openCalendar) => {
              return (
                <div
                  onClick={openCalendar}
                  className="w-full h-12 px-4 py-3 rounded-xl ts-date-picker-border flex justify-between items-center"
                >
                  <Text type="P4" className={`ts-accent`}>
                    {date.length === 0 ? "انتخاب بازه زمانی" : dateLabel.join(" - ")}
                  </Text>
                  {date.length === 0 ? (
                    <HiCalendar size={20} className={`ts-accent -mt-px`} />
                  ) : (
                    <IoMdClose
                      onClick={() => {
                        setModalFilters({ date: [], dateLabel: [] });
                      }}
                      size={21}
                      className="ts-gray-07 -mt-px cursor-pointer"
                    />
                  )}
                </div>
              );
            }}
          />

          <Button
            onClick={() => {
              onFilterChanged(modalFilters);
              onClose();
            }}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl"
          >
            اعمال فیلتر
          </Button>
        </BottomModal>

        <CarsModal
          isOpen={carModalOpen}
          onClose={() => setCarModalOpen(false)}
          isSingleChoice={false}
          selectedCars={cars}
          selectCarHandler={(value) => {
            setModalFilters({ cars: value });
          }}
        />
      </>
    );
  },
);

const roles = [
  {
    label: "مالک",
    value: "owner",
  },
  {
    label: "سرویس دهنده",
    value: "provider",
  },
  {
    label: "ادمین",
    value: "admin",
  },
];

export { PlatesFilterModal };

const styles = {};
