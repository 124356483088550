/* eslint-disable */
import { ApiInstance, ConfigLink } from "core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomModal, Button, Text, useNotification } from "ui/components";

interface IDeletePartRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const DeletePartRequestModal: React.FC<IDeletePartRequestModalProps> = React.memo(({ isOpen, onClose, id }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const approveBtnHandler = async () => {
    setLoading(true);

    await ApiInstance.partSupply
      .deleteRequest(id)
      .then((res) => {
        console.log(res);
        useNotification("درخواست قطعه حذف شد", "success", "حذف درخواست");
        setTimeout(() => {
          onClose();
        }, 1000);
        navigate(ConfigLink.partRequests, { replace: true });
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "حذف درخواست");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <Text type="H4" className="ts-accent mb-6 text-right">
        حذف درخواست
      </Text>

      <Text type="P3" className="ts-accent mb-6 text-right">
        آيا میخواهید این درخواست تامین قطعه را حذف کنید؟
      </Text>

      <div className="w-full flex justify-between">
        <Button
          onClick={approveBtnHandler}
          loading={loading}
          bcolor="primary"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          بله، حذف شود
        </Button>
        <Button
          onClick={onClose}
          bcolor="primaryTransparent"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          لغو
        </Button>
      </div>
    </BottomModal>
  );
});

export { DeletePartRequestModal };

const styles = {};
