/* eslint-disable */
import React from "react";
import { Tab } from "ui/components";

interface IServiceProviderTabBarProps {
  activeTab: number;
  onActiveChanged: (id: number) => void;
}

const ServiceProviderTabBar: React.FC<IServiceProviderTabBarProps> = React.memo(({ activeTab, onActiveChanged }) => {
  return (
    <div className="w-full flex-nowrap whitespace-nowrap overflow-x-auto overflow-y-hidden ts-hide-scroll-x-but-keep-working touch-pan-x border-b-2 border-b-gray-300 flex justify-start items-center mb-6">
      <Tab onClick={(id) => onActiveChanged(id)} tabNumber={1} title="خودروهای پذیرش شده" activeTabNumber={activeTab} />
      <Tab
        className="mx-4"
        onClick={(id) => onActiveChanged(id)}
        tabNumber={2}
        title="لیست افراد"
        activeTabNumber={activeTab}
      />
      <Tab
        className="mx-4"
        onClick={(id) => onActiveChanged(id)}
        tabNumber={3}
        title="لیست قطعات"
        activeTabNumber={activeTab}
      />
      {/* <Tab
        className="mx-4"
        onClick={(id) => onActiveChanged(id)}
        tabNumber={6}
        title="امکانات فعال"
        activeTabNumber={activeTab}
      /> */}
      <Tab
        className="mx-4"
        onClick={(id) => onActiveChanged(id)}
        tabNumber={4}
        title="گزارشات"
        activeTabNumber={activeTab}
      />
      <Tab
        className="mx-4"
        onClick={(id) => onActiveChanged(id)}
        tabNumber={5}
        title="جشنواره ها"
        activeTabNumber={activeTab}
      />
    </div>
  );
});

export { ServiceProviderTabBar };
