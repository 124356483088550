/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IReportsCardProps {
  data: any;
}

const ReportsCard: React.FC<IReportsCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  const [cardOpen, setCardOpen] = useState(false);

  useEffect(() => {}, []);

  const reporterClickHandler = () => {
    if (data.userInfo) {
      navigate({ pathname: ConfigLink.userDetail, search: useQueryString({ id: data.userInfo.id }) });
    }
  };

  const workshopClickHandler = () => {
    if (data.workshop) {
      navigate({
        pathname: ConfigLink.serviceProviderDetail,
        search: useQueryString({ id: data.reporteeInfo.id }),
      });
    }
  };

  const options = [
    {
      id: 0,
      label: "اطلاعات قطعه ناقص است.",
      value: "not_precise",
    },
    {
      id: 1,
      label: "حاوی محتوای نامربوط است.",
      value: "irrelevant_content",
    },
    {
      id: 2,
      label: "تعریف کننده قطعه نامعتبر است.",
      value: "invalid_creator",
    },
    {
      id: 3,
      label: "سایر",
      value: "other",
    },
    {
      id: 4,
      label: "این کسب و کار تکراری است.",
      value: "duplicate",
    },
    {
      id: 5,
      label: "کسب و کاری با این مشخصات پیدا نکردم.",
      value: "not_found",
    },
  ];

  const reason = options.filter((item) => item.value === data.reason);

  return (
    <div
      className={`w-full ${
        cardOpen ? "ts-report-box-open" : "ts-report-box-close"
      } bg-white ts-shadow rounded-2xl p-4 mb-4`}
    >
      <div className="w-full flex justify-between items-center mb-6">
        <div onClick={() => setCardOpen((prev) => !prev)} className={`flex w-full justify-between items-center`}>
          <Text type="H5" className="ts-accent mt-1">
            {data.reporterInfo.username || data.reporterInfo.phoneNumber || "---"}
          </Text>
          <HiChevronDown size={22} className={`ts-accent mt-1 ${cardOpen ? "ts-arrow-up" : "ts-arrow-down"}`} />
        </div>
      </div>

      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          زمان ارسال:
        </Text>
        <Text type="P4" className="ts-accent">
          {data.createdAt
            ? `${new Date(data.createdAt).toLocaleDateString("fa-IR")} ساعت ${new Date(
                data.createdAt,
              ).toLocaleTimeString("fa-IR", { hour: "2-digit", minute: "2-digit" })}`
            : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          دلیل گزارش:
        </Text>
        <Text type="P4" className="ts-accent">
          {reason.length !== 0 ? reason[0].label : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          نام و نام خانوادگی:
        </Text>
        <Text
          onClick={reporterClickHandler}
          type="H6"
          className={`ts-primary ${data.reporterInfo.username ? "cursor-pointer" : ""}`}
        >
          {data.reporterInfo.username || "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          متخلف:
        </Text>
        <Text
          onClick={workshopClickHandler}
          type="H6"
          className={`ts-primary ${data.reporteeInfo.name ? "cursor-pointer" : ""}`}
        >
          {data.reporteeInfo.name || "---"}
        </Text>
      </div>

      {reason.length !== 0 && reason[0].value === "other" && (
        <>
          <hr className="w-full my-3 ts-report-line" />
          <div className="w-full flex flex-col justify-start items-start">
            <Text type="HM" className="ts-gray-06 mb-2">
              متن گزارش:
            </Text>
            <Text type="P4" className={`ts-accent`}>
              {data.description || "---"}
            </Text>
          </div>
        </>
      )}
    </div>
  );
});

export { ReportsCard };

const styles = {};
