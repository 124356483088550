/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { ApiInstance, tools } from "core";
import React, { useEffect, useState } from "react";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";

interface ITransferOwnershipProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}

const TransferOwnership: React.FC<ITransferOwnershipProps> = React.memo(({ isOpen, onClose, data }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [mobile, setMobile] = useState("");

  const isIos = tools.isIOS();

  useEffect(() => {
    if (isOpen && data.mobile) {
      setMobile(data.mobile);
    }
  }, [isOpen]);

  useEffect(() => {
    setDisabled(
      mobile.length !== 11 || digitsFaToEn(mobile.slice(0, 2)) !== "09" || (data.mobile && mobile === data.mobile),
    );
  }, [mobile]);

  const transferBtnHandler = async () => {
    setLoading(true);
    const mobileNumber = digitsFaToEn(mobile);
    await ApiInstance.plates
      .changeOwnership(data.id, mobileNumber || mobile)
      .then((res) => {
        console.log(res);
        useNotification("مالکیت خودرو به شماره جدید انتقال یافت.", "success", "انتقال مالکیت");
        onClose();
      })
      .catch((err) => {
        console.log(err);
        useNotification(
          "مشکلی رخ داده است. لطفا اطلاعات وارد شده را بررسی کنید و مجددا امتحان کنید",
          "error",
          "انتقال مالکیت",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <Text type="H4" className="ts-accent mb-6">
          تغییر مالکیت خودرو
        </Text>

        <InputField
          label="شماره تماس"
          inputType="number"
          value={mobile}
          onChange={(e) => {
            if (e.length <= 11) {
              setMobile(e);
            }
          }}
          placeholder="09000000000"
        />

        <Button
          onClick={transferBtnHandler}
          loading={loading}
          disabled={disabled}
          bcolor="primary"
          textType="H6"
          classNameContainer="w-full"
          className="w-full rounded-xl h-12 mt-6"
        >
          تغییر مالکیت خودرو
        </Button>
      </div>
    </BottomModal>
  );
});

export { TransferOwnership };

const styles = {};
