/* eslint-disable */
import React from "react";
import { NoResult } from "ui/components";
import { FestivalCard } from "./FestivalCard";

interface IFestival {
  festivalActivatedAt: string | null;
  festivalTerminatedAt: string | null;
  festivalCount: number;
  festivalStatus: string | null;
}

interface IProviderFestivalsTabProps {
  id: string;
  festivals: IFestival;
}

const ProviderFestivalsTab: React.FC<IProviderFestivalsTabProps> = React.memo(({ id, festivals }) => {
  console.log(festivals);

  return <>{festivals === null ? <NoResult /> : <FestivalCard data={festivals} />}</>;
});

export { ProviderFestivalsTab };
