/* eslint-disable */
import { ApiInstance } from "core";
import React, { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { CheckPicker } from "rsuite";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";

interface IAddEditBusinessTypeProps {
  isOpen: boolean;
  onClose: () => void;
  type: "add" | "edit";
  selectedItem: any | null;
  featuresList: any[];
}

const AddEditBusinessType: React.FC<IAddEditBusinessTypeProps> = React.memo(
  ({ isOpen, onClose, type, selectedItem, featuresList }) => {
    const featuresPicker = useRef();

    const [service, setService] = useState("");
    const [alias, setAlias] = useState("");
    const [features, setFeatures] = useState<string[]>([]);
    const [featuresObj, setFeaturesObj] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
      setDisabled(service.length === 0 || alias.length === 0);
    }, [service, alias]);

    useEffect(() => {
      if (isOpen) {
        if (type === "add") {
          setService("");
          setAlias("");
          setFeatures([]);
        } else {
          setService(selectedItem.name || "");
          setAlias(selectedItem.alias || "");
          setFeatures(selectedItem.features || []);
        }
      }
    }, [isOpen]);

    const getUI = async () => {
      await ApiInstance.common.getUI();
    };

    const getFeaturesObject = (cat) => {
      return featuresList.filter((item) => cat.filter((ws) => ws === item.value)[0]);
    };
    useEffect(() => {
      const values: any[] = getFeaturesObject(features);
      setFeaturesObj(values);
    }, [features]);

    const addCategoryHandler = async () => {
      await ApiInstance.common
        .addCategory(service, alias, features)
        .then((res) => {
          console.log(res);
          useNotification("کسب و کار جدید اضافه شد.", "success", "اضافه کردن کسب و کار");
          getUI();
          onClose();
        })
        .catch((err) => {
          console.log("err", err);
          useNotification("مشکلی رخ داده است.", "error", "اضافه کردن کسب و کار");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editCategoryHandler = async () => {
      await ApiInstance.common
        .editCategory(selectedItem.id, service, alias, features)
        .then((res) => {
          console.log(res);
          useNotification("اطلاعات کسب و کار تغییر یافت.", "success", "ویرایش اطلاعات کسب و کار");
          getUI();
          onClose();
        })
        .catch((err) => {
          console.log("err", err);
          useNotification("مشکلی رخ داده است.", "error", "ویرایش اطلاعات کسب و کار");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const categoryHandler = () => {
      setLoading(true);
      type === "add" ? addCategoryHandler() : editCategoryHandler();
    };

    const removeCategoryItem = (id) => {
      setFeatures(features.filter((item) => item !== id));
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent mb-6">
            {type === "add" ? "تعریف کسب و کار جدید" : "ویرایش کسب و کار"}
          </Text>
          <InputField label="نام" value={service} onChange={(e) => setService(e)} placeholder="مثلا: تعویض روغن" />

          <InputField
            label="کلید(انگلیسی)"
            value={alias}
            onChange={(e) => setAlias(e)}
            placeholder="مثلا: oil_change"
          />

          <div className="w-full flex flex-col justify-start items-start mb-8">
            <Text type="H6" className="ts-accent text-right mb-1">
              امکانات
            </Text>
            <Text type="P5" className="ts-accent text-right mb-2">
              می توانید چند گزینه را انتخاب کنید.
            </Text>
            <CheckPicker
              size="lg"
              name="categories_type"
              placement="auto"
              // @ts-ignore
              ref={featuresPicker}
              data={featuresList}
              value={features}
              onChange={(value) => setFeatures(value)}
              onClean={() => setFeatures([])}
              block
              cleanable
              searchable={false}
              countable={false}
              placeholder="امکانات مرتبط با این کسب و کار را انتخاب کنید"
              menuClassName="ts-workshops-picker"
              className={`ts-filter-picker w-full h-12 outline-none rounded-xl placeholder:text-black`}
              renderExtraFooter={() => (
                <div className="w-full py-3 px-5 border-t-2 flex items-center">
                  <Button
                    bcolor="primaryTransparent"
                    onClick={() => {
                      //@ts-ignore
                      featuresPicker.current.close();
                    }}
                    className="w-full rounded-xl h-11"
                    classNameContainer="w-full"
                  >
                    تایید و بستن
                  </Button>
                </div>
              )}
            />
            {featuresObj.length !== 0 && (
              <div className="w-full flex flex-wrap justify-start items-center gap-2 mt-2">
                {featuresObj.map((item: any) => {
                  return (
                    <div
                      onClick={() => removeCategoryItem(item.value)}
                      className="flex rounded-full cursor-pointer justify-center items-center px-4 py-1 ts-bg-gray-08 w-max"
                    >
                      <Text type="HM" className="text-white ml-1">
                        {item.label}
                      </Text>
                      <IoMdClose size={20} className="text-white -mt-1" />
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <Button
            onClick={categoryHandler}
            disabled={disabled}
            loading={loading}
            bcolor="primary"
            textType="H5"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12"
          >
            {type === "add" ? "اضافه کردن کسب و کار" : "ویرایش کسب و کار"}
          </Button>
        </div>
      </BottomModal>
    );
  },
);

export { AddEditBusinessType };
