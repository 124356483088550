/* eslint-disable */
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, useQueryString } from "core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Progress } from "rsuite";
import { Breadcrumb, ContainerScroll, Text } from "ui/components";

interface IPlateReminderDetailProps {}

interface IQueryStringTransfer {
  id: string;
  currentKilometer: string;
  plateId: string;
}

const PlateReminderDetail: React.FC<IPlateReminderDetailProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - جزئیات یادآور خودرو");
  let { id, currentKilometer, plateId } = useFetchQueryString<IQueryStringTransfer>();

  const [data, setData] = useState({
    committed: true,
    createdAt: "",
    description: "",
    expiresInKilometer: 0,
    id: "",
    kilometer: 0,
    updatedAt: "",
    service: {
      name: "",
      expiresInDays: 0,
      expiresInKilometers: 0,
    },
  });
  const [loading, setLoading] = useState(true);

  const getServiceHistory = async () => {
    await ApiInstance.common
      .getCarReminderDetail(plateId, id)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getServiceHistory();
  }, []);

  const today = moment();
  const nextChangeTime =
    data.updatedAt && data.service.expiresInDays ? moment(data.updatedAt).add(data.service.expiresInDays, "days") : 0;
  const nextChangeDays =
    data.updatedAt && data.service.expiresInDays
      ? moment(data.updatedAt).add(data.service.expiresInDays, "days").diff(moment(data.updatedAt), "days")
      : 0;
  const nextChangeDaysAccordingToToday =
    nextChangeTime && data.service.expiresInDays ? moment(nextChangeTime).diff(today, "days") : 0;
  const daysPercentage = 100 - +(+nextChangeDaysAccordingToToday / +nextChangeDays).toFixed(2) * 100;

  const daysRemained = nextChangeTime ? nextChangeTime.diff(today, "days") : -1000;
  const isTimePassed = nextChangeTime ? nextChangeTime.diff(today, "days") <= 0 : false;

  const currentKM = +currentKilometer || 0;
  const nextKM = +(+data.kilometer + +data.service.expiresInKilometers);
  const remainKM = nextKM - currentKM;
  const percentage =
    currentKM > nextKM || remainKM === 0 ? 100 : 100 - +(remainKM / data.service.expiresInKilometers).toFixed(2) * 100;

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={100} className={`relative`}>
        <Breadcrumb
          links={[
            {
              name: "لیست خودروها",
              active: true,
              link: ConfigLink.plates,
            },
            {
              name: "جزییات خودرو",
              active: true,
              link: `${ConfigLink.plateDetail}?${useQueryString({ id: plateId })}`,
            },
            {
              name: "جزییات یادآور",
              active: false,
              link: ``,
            },
          ]}
          className="mb-9"
        />
        <div className="w-full flex flex-col justify-start items-start">
          <div className="w-full flex justify-between items-center mb-6">
            {loading ? (
              <Skeleton className="w-14" />
            ) : (
              <Text type="H4" className="ts-gray-08">
                {data.service?.name || "---"}
              </Text>
            )}

            {isTimePassed && data.committed && (
              <div className="p-2 -mt-px flex justify-center items-center ts-bg-light-red rounded-xl">
                <Text type="HM" className="ts-secondary-red">
                  موعد زمانی گذشته
                </Text>
              </div>
            )}
          </div>
          {loading ? (
            <>
              <Skeleton containerClassName="w-full" className="w-full h-4" />
              <Skeleton className="w-36 h-4" />
            </>
          ) : (
            <Text type="P4" className="ts-gray-07 mb-4">
              آخرین {data.service.name}، در تاریخ{" "}
              <b className="ts-accent font-bold">{new Date(data.updatedAt).toLocaleDateString("fa-IR")}</b> و کیلومتر{" "}
              <b className="ts-accent font-bold">{data.kilometer}</b> انجام شده است.
            </Text>
          )}

          {loading ? (
            <Skeleton style={{ height: "68px" }} containerClassName="w-full" className="w-full mt-4 mb-3 rounded-2xl" />
          ) : (
            <div className="w-full mb-3 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
              <Text type="P4" className="ts-accent mb-2">
                {`${
                  !data.committed
                    ? data.service.expiresInKilometers
                    : remainKM <= 0
                    ? "0"
                    : Math.abs(nextKM - (currentKM || 0))
                } کیلومتر مانده تا تعویض بعدی`}
              </Text>
              <Progress.Line
                strokeColor={percentage >= 80 ? "#CA2525" : "#272D37"}
                strokeWidth={7}
                percent={percentage < 0 || !data.committed ? 0 : percentage}
                showInfo={false}
                className="w-full p-0"
              />
            </div>
          )}

          {loading ? (
            <Skeleton style={{ height: "68px" }} containerClassName="w-full" className="w-full mb-3 rounded-2xl" />
          ) : (
            <div className="w-full mb-3 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
              <Text type="P4" className="ts-accent mb-2">
                {`${
                  daysRemained < 30
                    ? daysRemained < 0
                      ? daysRemained < -30
                        ? `${Math.round(Math.abs(daysRemained) / 30)} ماه از موعد زمانی گذشته است.`
                        : `${Math.abs(daysRemained)} روز از موعد زمانی گذشته است.`
                      : `${Math.abs(daysRemained)} روز مانده تا تعویض بعدی`
                    : `${Math.round(daysRemained / 30)} ماه مانده تا تعویض بعدی`
                }`}
              </Text>
              <Progress.Line
                strokeColor={daysPercentage >= 80 ? "#CA2525" : "#752FBB"}
                strokeWidth={7}
                percent={daysPercentage < 0 ? 0 : daysPercentage}
                showInfo={false}
                className="w-full p-0"
              />
              {daysRemained < 0 && daysRemained !== -1000 && (
                <Text type="P5" className="ts-gray-07 mt-2">
                  {`تاریخ آخرین تعویض: ${new Date(data.updatedAt).toLocaleDateString("fa-IR")}`}
                </Text>
              )}
            </div>
          )}

          {loading ? (
            <Skeleton containerClassName="w-full" className="w-full h-28 mb-4 rounded-2xl" />
          ) : (
            <div className="w-full cursor-pointer mb-4 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
              <Text type="H6" className="ts-accent mb-3">
                توضیحات سرویس:
              </Text>
              <Text type="P4" className="ts-gray-06">
                {data.description || "توضیحاتی برای این سرویس اضافه نشده است."}
              </Text>
            </div>
          )}
        </div>
      </ContainerScroll>
    </>
  );
});

export { PlateReminderDetail as default };
