/* eslint-disable */
import { ApiInstance, useObjectState } from "core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { BiChevronDown } from "react-icons/bi";
import { HiCalendar } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import DatePicker, { DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/purple.css";
import { SelectCity } from "ui/_layouts/components";
import { BottomModal, Button, Text } from "../../../components";

interface IUsersFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  filter: any;
  onFilterChanged: (f) => void;
}

const UsersFilterModal: React.FC<IUsersFilterModalProps> = React.memo(
  ({ isOpen, onClose, filter, onFilterChanged }) => {
    const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];
    const datePickerRef = useRef();

    const [modalFilters, setModalFilters] = useObjectState<any>({
      city: "",
      date: [],
      dateLabel: [],
    });
    const { city, date, dateLabel } = modalFilters;
    const [cityModal, setCityModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);

    const getCities = async (cityId) => {
      await ApiInstance.common
        .getAllCities()
        .then((res) => {
          console.log(res);
          const selectedCityObj = res.data.data.filter((item) => item.id === cityId);
          if (selectedCityObj.length !== 0) {
            const selected_city = {
              label: `${selectedCityObj[0].city}، ${selectedCityObj[0].province}`,
              value: selectedCityObj[0].id,
            };
            setModalFilters({ city: selected_city });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      if (isOpen) {
        setModalFilters({
          ...filter,
          ...(filter.date
            ? {
                dateLabel: [
                  new Date(filter.date[0]).toLocaleDateString("fa-IR"),
                  new Date(filter.date[1]).toLocaleDateString("fa-IR"),
                ],
              }
            : undefined),
        });
        if (filter.city) {
          getCities(filter.city);
        } else {
          setLoading(false);
        }
      }
    }, [isOpen]);

    const selectCityHandler = (city) => {
      setModalFilters({ city });
    };

    const saveDateHandler = (sDate: any) => {
      if (sDate.length === 2) {
        setModalFilters({
          date: [moment(sDate[0].toDate()).toISOString(), moment(sDate[1].toDate()).toISOString()] as any,
          dateLabel: [sDate[0]?.toString(), sDate[1]?.toString()] as any,
        });
        // @ts-ignore
        datePickerRef.current?.closeCalendar();
      }
    };

    const removeAllFilters = () => {
      setModalFilters({
        city: "",
        date: [],
        dateLabel: [],
      });
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <Text type="H4" className="ts-accent text-right mb-6">
            فیلتر
          </Text>

          {city.length !== 0 || date.length !== 0 ? (
            <Text onClick={removeAllFilters} type="H6" className="ts-primary mb-6 cursor-pointer">
              حذف همه فیلترها
            </Text>
          ) : null}
          <div className="w-full flex flex-col justify-start items-start mb-6">
            <Text type="H6" className="ts-accent text-right mb-2">
              شهر
            </Text>
            {loading ? (
              <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl" />
            ) : (
              <div
                onClick={() => setCityModal(true)}
                className={`transition-all w-full cursor-pointer rounded-xl p-4 flex justify-between items-center h-12 ts-city-input-border`}
              >
                <Text type="P4" className="ts-accent">
                  {city ? city.label || "انتخاب شهر" : "انتخاب شهر"}
                </Text>
                <BiChevronDown size={21} className="ts-accent -mt-px" />
              </div>
            )}
          </div>

          <Text type="H6" className="ts-accent text-right mb-2">
            تاریخ ثبت نام
          </Text>
          {loading ? (
            <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl mb-6" />
          ) : (
            <DatePicker
              ref={datePickerRef}
              range
              dateSeparator=" - "
              value={date}
              editable={false}
              onChange={saveDateHandler}
              weekDays={weekDays}
              maxDate={new DateObject({ calendar: persian })}
              calendar={persian}
              locale={persian_fa}
              containerClassName="w-full h-12 mb-6"
              className="purple"
              inputClass="w-full h-12"
              calendarPosition="bottom-center"
              offsetY={-60}
              mapDays={({ date }) => {
                //disable friday
                let isWeekend = [6, 6].includes(date.weekDay.index);

                if (isWeekend)
                  return {
                    disabled: false,
                    style: { color: "#900" },
                  };
              }}
              render={(value, openCalendar) => {
                return (
                  <div
                    onClick={openCalendar}
                    className="w-full h-12 px-4 py-3 rounded-xl ts-date-picker-border flex justify-between items-center"
                  >
                    <Text type="P4" className={`ts-accent`}>
                      {date.length === 0 ? "انتخاب بازه زمانی" : dateLabel.join(" - ")}
                    </Text>
                    {date.length === 0 ? (
                      <HiCalendar size={20} className={`ts-accent -mt-px`} />
                    ) : (
                      <IoMdClose
                        onClick={() => {
                          setModalFilters({ date: [], dateLabel: [] });
                        }}
                        size={21}
                        className="ts-gray-07 -mt-px cursor-pointer"
                      />
                    )}
                  </div>
                );
              }}
            />
          )}

          <Button
            onClick={() => {
              onFilterChanged({ ...modalFilters, city: city ? city.value : "" });
              onClose();
            }}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl"
          >
            اعمال فیلتر
          </Button>
        </BottomModal>

        <SelectCity
          isOpen={cityModal}
          selectedId={typeof city === "object" ? (city ? city.value : "") : city ? city : ""}
          onClose={() => setCityModal(false)}
          onSelect={(city) => selectCityHandler(city)}
        />
      </>
    );
  },
);

export { UsersFilterModal };

const styles = {};
