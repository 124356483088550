/* eslint-disable */
import { carMonthlyUsage, fuelTypes, tools, vState } from "core";
import React from "react";
import { HiTrash } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { Button, Text } from "ui/components";
import { useSnapshot } from "valtio";

interface IPlateInformationProps {
  data: any;
  loading: boolean;
  onDelete: () => void;
}

const PlateInformation: React.FC<IPlateInformationProps> = React.memo(({ data, loading, onDelete }) => {
  const snap = useSnapshot(vState);

  return (
    <div className="w-full rounded-xl ts-shadow p-6 mb-8 flex flex-col justify-start items-start">
      <Text type="H4" className="ts-accent mb-8">
        اطلاعات خودرو
      </Text>

      <div className="w-full flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          خودرو:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent ts-sp-detail-item">
            {data.car ? data.car?.name : data.carName || "---"}
          </Text>
        )}
      </div>

      {/* <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-28 ml-2">
              :VIN
            </Text>
            {loading ? (
              <Skeleton className="w-24" />
            ) : (
              <Text type="P4" className="ts-accent ts-sp-detail-item">
                {data.carName || "---"}
              </Text>
            )}
          </div> */}

      <div className="w-full flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          نوع سوخت:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent ts-sp-detail-item">
            {`${
              data.fuelType ? `${fuelTypes.filter((item) => item.value === data.fuelType)[0].label || "---"} ` : "---"
            }`}
          </Text>
        )}
      </div>

      <div className="w-full flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          سال ساخت:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent ts-sp-detail-item">
            {data.buildYear || "---"}
          </Text>
        )}
      </div>

      <div className="w-full flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          کارکرد متوسط:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent ts-sp-detail-item">
            {data.monthlyUsage
              ? carMonthlyUsage.filter((item) => item.value === data.monthlyUsage)[0].label || "---"
              : "---"}
          </Text>
        )}
      </div>

      {/* <div className="w-full flex justify-start items-center mb-4">
            <Text type="H6" className="ts-gray-06 w-28 ml-2">
              اضافه کننده:
            </Text>
            {loading ? (
              <Skeleton className="w-24" />
            ) : (
              <Text type="H4" className="ts-primary ts-sp-detail-item">
                {data.carName || "---"}
              </Text>
            )}
          </div> */}

      <div className="w-full flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          زمان ایجاد:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent ts-sp-detail-item">
            {data.createdAt ? tools.getCardsTime(data.createdAt) : "---"}
          </Text>
        )}
      </div>

      <div className="w-full flex justify-start items-center mb-4">
        <Text type="H6" className="ts-gray-06 w-28 ml-2">
          آخرین بروزرسانی:
        </Text>
        {loading ? (
          <Skeleton className="w-24" />
        ) : (
          <Text type="P4" className="ts-accent ts-sp-detail-item">
            {data.updatedAt
              ? `${new Date(data.updatedAt).toLocaleDateString("fa-IR")} ساعت ${new Date(
                  data.updatedAt,
                ).toLocaleTimeString("fa-IR", { hour: "2-digit", minute: "2-digit" })}`
              : "---"}
          </Text>
        )}
      </div>

      {snap.superAdmin && (
        <Button
          bcolor="redTransparent"
          disabled={data.archive || false}
          onClick={onDelete}
          icon={<HiTrash size={23} className="-mt-1" />}
          classNameContainer="w-full"
          className="w-full h-12"
        >
          حذف خودرو
        </Button>
      )}
    </div>
  );
});

export { PlateInformation };
