/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, useQueryString, vState } from "core";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { SelectPicker } from "rsuite";
import {
  Breadcrumb,
  CardLoading,
  ContainerScroll,
  CustomLoader,
  FeedbacksCard,
  NoResult,
  SearchInput,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";

interface IQueryStringTransfer {
  id: string;
}

type SORT_TYPE = "NON" | "DATE" | "NAME";

const ServiceProviderFeedbacks = ({}) => {
  const metaTags = useChangeTitle("ویراژ - نظرات مشتریان");
  const snap = useSnapshot(vState);
  let { id } = useFetchQueryString<IQueryStringTransfer>();

  const [feedbacks, setFeedbacks] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("DATE");

  const getFeedbacks = async (sort: SORT_TYPE, pageIncreased: boolean, query?: string | null) => {
    await ApiInstance.common
      .getFeedbacks(pageCount + 1, sort, query || null, id)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...feedbacks, ...res.data.data];
          setFeedbacks(data);
          setPageCount((prev) => prev + 1);
        } else {
          setFeedbacks(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "نظرات");
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getFeedbacks(sortReminders, false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > feedbacks.length && !loading) {
      getFeedbacks(sortReminders, true, searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    setLoading(true);
    getFeedbacks(value, false, searchText);
  };

  const callApi = (e) => {
    setLoading(true);
    getFeedbacks(sortReminders, false, digitsFaToEn(e));
  };

  const handleChange = (e) => {
    setSearchText(e);
    // debouncedCallApi(e);
  };

  const resetSearch = () => {
    setSearchText("");
    setLoading(true);
    setTimeout(() => {
      callApi(null);
    }, 1100);
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={26} className="">
        <Breadcrumb
          links={[
            {
              name: "لیست سرویس دهندگان",
              active: true,
              link: ConfigLink.serviceProviders,
            },
            {
              name: "جزییات کسب و کار",
              active: true,
              link: `${ConfigLink.serviceProviderDetail}?${useQueryString({ id })}`,
            },
            {
              name: "دیدگاه مشتریان",
              active: false,
              link: ``,
            },
          ]}
          className="mb-6"
        />

        <Text type="H3" className="ts-accent mb-6">
          دیدگاه مشتریان
        </Text>

        <SearchInput
          value={searchText}
          onChange={handleChange}
          onReset={resetSearch}
          onSearch={() => callApi(searchText)}
          disabled={loading}
        />

        {(loading || feedbacks.length !== 0) && (
          <div className="w-full flex justify-between items-center mb-6">
            {loading ? (
              <Skeleton className="w-28" />
            ) : (
              <Text type="H6" className="ts-accent">
                {`${count} نظر`}
              </Text>
            )}
            <SelectPicker
              size="lg"
              data={sortData}
              value={sortReminders}
              disabled={loading}
              cleanable={false}
              onChange={(value: any) => sortChangeHandler(value)}
              searchable={false}
              placeholder="مرتب سازی"
              className={`w-36 h-12 outline-none rounded-xl`}
            />
          </div>
        )}

        {loading ? (
          <CardLoading height="77px" />
        ) : feedbacks.length === 0 ? (
          <NoResult forSearch={searchText.length !== 0} />
        ) : (
          feedbacks.map((item) => {
            return <FeedbacksCard key={item.id} data={item} />;
          })
        )}
        {snap.isEndOfPage && !loading && (
          <div className="w-full flex justify-center">
            <CustomLoader />
          </div>
        )}
      </ContainerScroll>
    </>
  );
};

export { ServiceProviderFeedbacks as default };
