/* eslint-disable */
import { ApiInstance, ConfigLink, useChangeTitle, useFetchQueryString, vState } from "core";
import { useEffect, useState } from "react";
import { Breadcrumb, ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import {
  DeletePlateModal,
  EditPlateInfo,
  PlateDetailTabs,
  PlateHistoryTab,
  PlateInformation,
  PlateMilageInfo,
  PlateOwnerInformation,
  PlateRemindersTab,
  TransferOwnership,
} from "./components";

interface IQueryStringTransfer {
  id: string;
}

const PlateDetail = ({}) => {
  const metaTags = useChangeTitle("ویراژ - خودروهای ثبت شده");
  const snap = useSnapshot(vState);
  let { id } = useFetchQueryString<IQueryStringTransfer>();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({
    carName: "",
    createdAt: "",
    updatedAt: "",
    id: "",
    kilometer: 0,
    fuelType: null,
    mobile: "",
    monthlyUsage: null,
    ownerName: "",
    plateNumber: "",
    archive: false,
  });

  const [editCarInfoModal, setEditCarInfoModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [deletePlateModal, setDeletePlateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [isShrunk, setShrunk] = useState(false);

  const getPlateDetail = async () => {
    await ApiInstance.common
      .getCarDetail(id)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "اطلاعات خودرو");
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getPlateDetail();
  }, []);

  const plateCardOperationHandler = (item, operation) => {
    setSelectedItem(item);
    operation();
  };

  // Tabs

  const tabChangedHandler = (number) => {
    setActiveTab(number);
  };

  useEffect(() => {
    const updateScrollDirection = () => {
      const tab = document.getElementById("plate-tabs");
      if (tab) {
        // console.log(tab.getBoundingClientRect().top);
        if (tab.getBoundingClientRect().top <= 123) {
          setShrunk(true);
        } else {
          setShrunk(false);
        }
      }
    };
    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, []);

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={20} className="">
        <Breadcrumb
          links={[
            {
              name: "لیست خودروها",
              active: true,
              link: ConfigLink.plates,
            },
            {
              name: "جزییات خودرو",
              active: false,
              link: ``,
            },
          ]}
          className="mb-9"
        />

        <div className="w-full flex justify-between items-center">
          <span className="flex justify-center items-center">
            <Text type="H3" className="ts-accent">
              مشخصات خودرو
            </Text>
            {data.archive && (
              <div className="ts-bg-gray-03 mr-2 flex justify-center items-center rounded-full px-4 py-1">
                <Text type="HM" className="ts-gray-06">
                  آرشیو شده
                </Text>
              </div>
            )}
          </span>
        </div>
        {/* plate milage */}
        <PlateMilageInfo data={data} loading={loading} />

        {/* plate information */}
        <PlateInformation data={data} loading={loading} onDelete={() => setDeletePlateModal(true)} />

        {/* plate owner info */}
        <PlateOwnerInformation
          data={data}
          loading={loading}
          onTransferOwnership={() => plateCardOperationHandler(data, () => setTransferModal(true))}
        />

        <div id="plate-tabs" className={`w-full transition-all duration-500 bg-white`}>
          <div
            className={`w-full transition-all duration-75 ${
              isShrunk ? "fixed top-20 left-0 px-6 max-2xs:px-3 pt-9 bg-white z-20" : ""
            }`}
          >
            <PlateDetailTabs activeTab={activeTab} onActiveChanged={(number) => tabChangedHandler(number)} />
          </div>
        </div>

        {activeTab === 1 ? <PlateRemindersTab id={id} kilometer={data.kilometer} /> : <PlateHistoryTab id={id} />}
      </ContainerScroll>
      <EditPlateInfo isOpen={editCarInfoModal} onClose={() => setEditCarInfoModal(false)} data={selectedItem} />
      <TransferOwnership isOpen={transferModal} onClose={() => setTransferModal(false)} data={selectedItem} />

      <DeletePlateModal
        isOpen={deletePlateModal}
        onClose={() => setDeletePlateModal(false)}
        onEnd={() => setData({ ...data, archive: true })}
        id={id}
      />
    </>
  );
};

export { PlateDetail as default };
