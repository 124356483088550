/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { SelectPicker } from "rsuite";
import { CardLoading, CustomLoader, NoResult, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { PlateReminderCard } from "./PlateReminderCard";

interface IPlateRemindersTabProps {
  id: string;
  kilometer: any;
}
type SORT_TYPE = "DATE" | "NAME" | "NON";

const PlateRemindersTab: React.FC<IPlateRemindersTabProps> = React.memo(({ id, kilometer }) => {
  const snap = useSnapshot(vState);

  const [remindersLoading, setRemindersLoading] = useState(true);
  const [reminders, setReminders] = useState<any[]>([]);
  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("NON");
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);

  const getReminders = async (pageIncreased: boolean, sort?: string | undefined) => {
    await ApiInstance.plates
      .getPlateReminders(id, pageCount + 1, sort || sortReminders)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...reminders, ...res.data.data];
          setReminders(data);
          setPageCount((prev) => prev + 1);
        } else {
          setReminders(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "یادآورهای ثبت شده");
        setRemindersLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setRemindersLoading(false);
        vState.isEndOfPage = false;
      });
  };

  const sortRemindersHandler = (value) => {
    setSortReminders(value);
    setRemindersLoading(true);
    setPageCount(0);
    getReminders(false, value);
  };

  useEffect(() => {
    setRemindersLoading(true);
    getReminders(false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > reminders.length && !remindersLoading) {
      getReminders(true);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      <div className="w-full flex justify-between items-center my-6">
        {remindersLoading ? (
          <Skeleton className="w-28 h-5" />
        ) : (
          <Text type="H6" className="ts-accent">
            {`${count} یادآور تعریف شده`}
          </Text>
        )}
        <SelectPicker
          size="lg"
          data={sortData}
          value={sortReminders}
          disabled={remindersLoading}
          cleanable={true}
          onClean={() => setSortReminders("NON")}
          onChange={(value: any) => sortRemindersHandler(value)}
          searchable={false}
          placeholder="مرتب سازی"
          className={`w-36 h-12 outline-none rounded-xl z-10`}
        />
      </div>

      {remindersLoading ? (
        <CardLoading height="72px" />
      ) : reminders.length === 0 ? (
        <NoResult />
      ) : (
        reminders.map((item) => {
          return <PlateReminderCard data={item} currentKilometer={kilometer} plateId={id} />;
        })
      )}
      {snap.isEndOfPage && !remindersLoading && (
        <div className="w-full flex justify-center">
          <CustomLoader />
        </div>
      )}
    </>
  );
});

const sortData = [
  {
    label: "به ترتیب تاریخ",
    value: "DATE",
  },
  {
    label: "به ترتیب الفبا",
    value: "NAME",
  },
];

export { PlateRemindersTab };
