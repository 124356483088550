/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { ApiInstance, useChangeTitle, vState } from "core";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { SelectPicker } from "rsuite";
import {
  CardLoading,
  ContainerScroll,
  CustomLoader,
  NoResult,
  SearchInput,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";
import { PartRequestCard } from "./components";

type SORT_TYPE = "NON" | "DATE" | "NAME";

const PartRequests = ({}) => {
  const metaTags = useChangeTitle("ویراژ - درخواست قطعات");
  const snap = useSnapshot(vState);

  const [requests, setRequests] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [sortRequests, setSortRequests] = useState<SORT_TYPE>("NAME");

  const getRequests = async (pageIncreased: boolean, query?: string | null) => {
    if (!pageIncreased) setPageCount(0);

    await ApiInstance.partSupply
      .getRequests(pageCount + 1, sortRequests, query || null)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          setPageCount((prev) => prev + 1);
          let data = [...requests, ...res.data.data];
          setRequests(data);
        } else {
          setRequests(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          setRequests([]);
        } else {
          useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "لیست کاربرها");
        }
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getRequests(false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > requests.length && !loading) {
      getRequests(true, searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const callApi = (e) => {
    setLoading(true);
    getRequests(false, digitsFaToEn(e));
  };

  const handleChange = (e) => {
    setSearchText(e);
  };

  const resetSearch = () => {
    setSearchText("");
    setLoading(true);
    setTimeout(() => {
      getRequests(false, null);
    }, 1100);
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  const sortChangeHandler = (value) => {
    setSortRequests(value);
    getRequests(false, searchText);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={20} className="">
        <div className="w-full fixed top-14 pt-6 bg-white z-40 ts-fixed-header">
          <Text type="H3" className="ts-accent mb-6">
            درخواست قطعات
          </Text>

          <SearchInput
            value={searchText}
            onChange={handleChange}
            onReset={resetSearch}
            onSearch={() => callApi(searchText)}
            disabled={loading}
          />
        </div>

        <div className="w-full ts-container-margin">
          <div className="w-full flex justify-between items-center mb-6">
            {loading ? (
              <>
                <Skeleton className="w-28" />
                <Skeleton className="w-36 h-12 rounded-xl" />
              </>
            ) : (
              <>
                {requests.length !== 0 && (
                  <Text type="H6" className="ts-accent">
                    {`${count} درخواست`}
                  </Text>
                )}
                <SelectPicker
                  size="lg"
                  data={sortData}
                  value={sortRequests}
                  disabled={loading}
                  cleanable={false}
                  onChange={(value: any) => sortChangeHandler(value)}
                  searchable={false}
                  placeholder="مرتب سازی"
                  className={`w-36 h-12 outline-none rounded-xl`}
                />
              </>
            )}
          </div>

          {loading ? (
            <CardLoading height="99px" />
          ) : requests.length === 0 ? (
            <NoResult forSearch={searchText.length !== 0} />
          ) : (
            requests.map((item) => {
              return <PartRequestCard key={item.id} data={item} />;
            })
          )}
          {snap.isEndOfPage && !loading && (
            <div className="w-full flex justify-center">
              <CustomLoader />
            </div>
          )}
        </div>
      </ContainerScroll>
    </>
  );
};

export { PartRequests as default };
