export const positiveItems = [
  {
    id: 0,
    label: "دقت در انجام کار",
    score: 1,
    value: "accuracy",
  },
  {
    id: 1,
    label: "سرعت بالای انجام کار",
    score: 1,
    value: "efficiency",
  },
  {
    id: 2,
    label: "کامل بودن تجهیزات",
    score: 1,
    value: "equipped",
  },
  {
    id: 3,
    label: "کاربلدی، تشخیص خوب",
    score: 1,
    value: "expert",
  },
  {
    id: 4,
    label: "ثبت با حوصله سرویس",
    score: 1,
    value: "addReminder",
  },
  {
    id: 5,
    label: "استفاده از قطعه با کیفیت",
    score: 1,
    value: "qualityPiece",
  },
  {
    id: 6,
    label: "اخلاق خوب و با حوصله",
    score: 1,
    value: "wellBehaved",
  },
  {
    id: 7,
    label: "قیمت مناسب",
    score: 1,
    value: "fairPrice",
  },
  {
    id: 8,
    label: "محیط تمیز",
    score: 1,
    value: "clean",
  },
  {
    id: 9,
    label: "شاگردهای خوب",
    score: 1,
    value: "skilledAide",
  },
];

export const negativeItems = [
  {
    id: 0,
    label: "بی دقتی در انجام کار",
    score: 0,
    value: "accuracy",
  },
  {
    id: 1,
    label: "کندی در انجام کار",
    score: 0,
    value: "efficiency",
  },
  {
    id: 2,
    label: "کامل نبودن تجهیزات",
    score: 0,
    value: "equipped",
  },
  {
    id: 3,
    label: "عدم تشخیص درست",
    score: 0,
    value: "expert",
  },
  {
    id: 4,
    label: "ثبت نکردن سرویس",
    score: 0,
    value: "addReminder",
  },
  {
    id: 5,
    label: "استفاده از قطعه بی کیفیت",
    score: 0,
    value: "qualityPiece",
  },
  {
    id: 6,
    label: "برخورد نامناسب",
    score: 0,
    value: "wellBehaved",
  },
  {
    id: 7,
    label: "قیمت زیاد",
    score: 0,
    value: "fairPrice",
  },
  {
    id: 8,
    label: "محیط کثیف",
    score: 0,
    value: "clean",
  },
  {
    id: 9,
    label: "شاگردهای بد",
    score: 0,
    value: "skilledAide",
  },
];
