/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { CardLoading, NoResult, useNotification, UserPlatesCard } from "ui/components";
import { useSnapshot } from "valtio";

interface IUserPlateTabProps {
  id: string;
}

const UserPlateTab: React.FC<IUserPlateTabProps> = React.memo(({ id }) => {
  const snap = useSnapshot(vState);

  const [tabLoading, setTabLoading] = useState(true);
  const [cars, setCars] = useState<any[]>([]);
  const [carsPage, setCarsPage] = useState(1);
  const [carsCount, setCarsCount] = useState(0);

  const getCars = async () => {
    await ApiInstance.common
      .getUsersPlates(id, carsPage)
      .then((res) => {
        console.log("Cars", res);
        setCarsCount(res.data.meta.total);
        if (carsPage > 1) {
          let data = [...cars, ...res.data.data];
          setCars(data);
          setCarsPage((prev) => prev + 1);
        } else {
          setCars(res.data.data);
        }
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "لیست خودروها");
      })
      .finally(() => {
        setTabLoading(false);
      });
  };

  useEffect(() => {
    getCars();
  }, [id]);

  useEffect(() => {
    if (snap.isEndOfPage && carsCount > cars.length && !tabLoading) {
      setCarsPage((prev) => prev + 1);
      getCars();
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      {tabLoading ? (
        <CardLoading height={"315px"} />
      ) : cars.length === 0 ? (
        <NoResult />
      ) : (
        cars.map((item, index) => {
          return <UserPlatesCard key={index} data={item} />;
        })
      )}
    </>
  );
});

export { UserPlateTab };

const styles = {};
