/* eslint-disable */
import { ConfigLink, tools, useQueryString } from "core";
import React from "react";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import { MdChevronLeft, MdOutlineAccessTimeFilled } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { NoResult, Text } from "ui/components";

interface IBulkSmsTabDataProps {
  data: any[];
}

const BulkSmsTabData: React.FC<IBulkSmsTabDataProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  if (data.length !== 0) {
    return (
      <>
        {data.map((item) => {
          const statusColor =
            item.status === "sent"
              ? "ts-success"
              : item.status === "rejected" || item.status === "payment_failed"
              ? "ts-secondary-red"
              : "ts-warning";
          return (
            <div
              key={item.id}
              onClick={() => navigate({ pathname: ConfigLink.bulkSmsDetail, search: useQueryString({ id: item.id }) })}
              className="w-full flex justify-between items-center cursor-pointer ts-shadow mb-3 rounded-2xl p-3 overflow-hidden"
            >
              <span className="flex flex-col justify-start items-start w-11/12 truncate">
                <Text type="H6" className="ts-primary mb-2">
                  {item.workshop?.name || "---"}
                </Text>
                <Text type="P4" className="ts-gray-07 mb-2 w-full truncate">
                  عنوان: <b className="ts-accent ts-text-p4">{item.title || "---"}</b>
                </Text>
                <Text type="P5" className="ts-accent mb-2">
                  {item.createdAt ? tools.getSimpleDate(item.createdAt, true) : "---"}
                </Text>
                <span className="flex justify-center items-center">
                  {item.status === "sent" ? (
                    <IoIosCheckmarkCircle size={16} className="ts-success" />
                  ) : item.status === "rejected" || item.status === "payment_failed" ? (
                    <IoIosCloseCircle size={16} className="ts-secondary-red" />
                  ) : (
                    <MdOutlineAccessTimeFilled size={16} className="ts-warning -mt-0.5" />
                  )}
                  <Text type="H6" className={`${statusColor} mr-1 pt-px`}>
                    {item.status === "sent"
                      ? "ارسال شده"
                      : item.status === "approved"
                      ? "در انتظار ارسال"
                      : item.status === "rejected"
                      ? "رد شده"
                      : item.status === "payment_succeeded"
                      ? "در صف انتشار"
                      : item.status === "created"
                      ? "در انتظار پرداخت"
                      : "پرداخت ناموفق"}
                  </Text>
                </span>
              </span>

              <MdChevronLeft size={28} className="ts-accent" />
            </div>
          );
        })}
      </>
    );
  } else {
    return <NoResult note="داده ای یافت نشد." />;
  }
});

export { BulkSmsTabData };

const styles = {};
