/* eslint-disable */
import { ApiInstance, useObjectState, vState } from "core";
import React, { useEffect, useState } from "react";
import { SelectPicker } from "rsuite";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

interface IReminder {
  name: string;
  expiresInDays?: number;
  expiresInKilometers?: number;
  expiresType?: string;
  disabled?: boolean | null;
  id?: string;
  recommendations?: any[];
}

interface IAddEditReminderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone?: () => void;
  forUpdate?: boolean;
  value?: IReminder | null;
  id?: string | null;
}

const AddEditReminderModal: React.FC<IAddEditReminderModalProps> = React.memo(
  ({ isOpen, onClose, onDone, forUpdate = false, value, id }) => {
    const snap = useSnapshot(vState);

    const defaultValue = {
      name: "",
      expiresInDays: "",
      expiresInKilometer: "",
      expiresType: "kilometers",
    };

    const [service, setService] = useObjectState(defaultValue);
    const { name, expiresInDays, expiresInKilometer, expiresType } = service;

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
      setDisabled(
        name.length === 0 ||
          (expiresInDays.length === 0 && (expiresType === "days" || expiresType === "both")) ||
          (expiresInKilometer.length === 0 && (expiresType === "kilometers" || expiresType === "both")),
      );
    }, [name, expiresInDays, expiresInKilometer]);

    useEffect(() => {
      if (forUpdate) {
        if (value) {
          setService({
            name: value.name,
            expiresInDays: value?.expiresInDays ? (+value?.expiresInDays / 30).toString() : "",
            expiresInKilometer: value?.expiresInKilometers ? value?.expiresInKilometers.toString() : "",
            expiresType: value?.expiresType ? value?.expiresType : "",
          });
        }
      } else {
        if (isOpen) {
          setService(defaultValue);
        }
      }
    }, [isOpen]);

    const addReminderHandler = async () => {
      const service = {
        name: name,
        expiresType: expiresType,
        ...(expiresInDays.length !== 0 ? { expiresInDays: +expiresInDays * 30 } : undefined),
        ...(expiresInKilometer.length !== 0 ? { expiresInKilometers: +expiresInKilometer } : undefined),
      };

      await ApiInstance.reminders
        .addReminder(service)
        .then((res) => {
          console.log(res);
          useNotification("یادآور جدید اضافه شد", "success", "ایجاد یادآور");
          let data = [...snap.services, res.data.data];
          vState.services = data;
          onClose();
        })
        .catch((err) => {
          console.log("err", err);
          useNotification("مشکلی رخ داده است. لطفا بعدا امتحان کنید", "error", "ایجاد یادآور");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editReminderHandler = async () => {
      const service = {
        name: name,
        expiresType: expiresType,
        ...(expiresInDays.length !== 0 ? { expiresInDays: +expiresInDays * 30 } : undefined),
        ...(expiresInKilometer.length !== 0 ? { expiresInKilometers: +expiresInKilometer } : undefined),
      };

      await ApiInstance.reminders
        .editReminder(id, service)
        .then((res) => {
          console.log(res);
          useNotification("یادآور تغییر یافت", "success", "ویرایش یادآور");
          onDone && onDone();
          setTimeout(() => {
            onClose();
          }, 1500);
        })
        .catch((err) => {
          console.log("err", err);
          useNotification("مشکلی رخ داده است. لطفا بعدا امتحان کنید", "error", "ایجاد یادآور");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const reminderHandler = () => {
      setLoading(true);
      if (forUpdate) {
        editReminderHandler();
      } else {
        addReminderHandler();
      }
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent mb-6">
            {forUpdate ? `ویرایش یادآور` : "تعریف یادآور جدید"}
          </Text>

          <InputField
            label="نام یادآور"
            value={name}
            onChange={(e) => setService({ name: e })}
            placeholder="مثلا: تعویض روغن"
          />

          <Text type="H6" className="ts-accent mb-2">
            نوع یادآور
          </Text>
          <SelectPicker
            size="lg"
            placement="auto"
            data={reminderType}
            value={expiresType}
            disabled={loading}
            cleanable={false}
            onChange={(value) => setService({ expiresType: value })}
            searchable={false}
            placeholder="نوع یادآور را انتخاب کنید"
            className={`w-full h-12 outline-none rounded-xl mb-6`}
          />

          {(expiresType === "days" || expiresType === "both") && (
            <>
              <InputField
                label="مدت زمان تعویض"
                inputType="number"
                value={expiresInDays}
                onChange={(e) => setService({ expiresInDays: e })}
                placeholder="6"
                hint="ماه"
              />
            </>
          )}

          {expiresType !== "days" && (
            <>
              <InputField
                label="کیلومتر پیشنهادی تعویض"
                inputType="number"
                value={expiresInKilometer}
                onChange={(e) => setService({ expiresInKilometer: e })}
                placeholder="5,000"
                hint="کیلومتر"
              />
            </>
          )}

          <Button
            onClick={reminderHandler}
            disabled={disabled}
            loading={loading}
            bcolor="primary"
            textType="H5"
            classNameContainer="w-full"
            className="w-full rounded-lg h-12"
          >
            {forUpdate ? "به روز رسانی" : "تعریف یادآور"}
          </Button>
        </div>
      </BottomModal>
    );
  },
);

export { AddEditReminderModal };

const reminderType = [
  {
    label: "بر اساس زمان و کیلومتر",
    value: "both",
  },
  {
    label: "فقط بر اساس زمان",
    value: "days",
  },
  {
    label: "فقط بر اساس کیلومتر",
    value: "kilometers",
  },
];
