/* eslint-disable */
import { isArabic, toPersianChars } from "@persian-tools/persian-tools";
import { ApiInstance, ConfigLink, useChangeTitle, useQueryString, vState } from "core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { HiCalendar, HiOutlineDownload } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import DatePicker, { DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/purple.css";
import { useNavigate } from "react-router-dom";
import { CheckPicker, SelectPicker, Table } from "rsuite";
import {
  Breadcrumb,
  Button,
  ContainerScroll,
  CustomLoader,
  DownloadPromptModal,
  NoResult,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";

const ServiceProvidersActivity = ({}) => {
  const datePickerRef = useRef();
  const { Column, HeaderCell, Cell } = Table;

  const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];
  const metaTags = useChangeTitle("ویراژ - فعالیت کسب و کارها");
  const snap = useSnapshot(vState) as typeof vState;
  const navigate = useNavigate();
  const picker = useRef();

  const [serviceProviders, setServiceProviders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [downloadModal, setDownloadModal] = useState(false);

  const [cities, setCities] = useState<any>([]);
  const [selectedWorkshops, setSelectedWorkshops] = useState([]);
  const [citiesType, setCitiesType] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>([]);
  const [selectedDateLabel, setSelectedDateLabel] = useState<any>([]);

  const [columnKeys, setColumnKeys] = useState(defaultColumns.map((column) => column.key));

  const columns = defaultColumns.filter((column) => columnKeys.some((key) => key === column.key));
  const CustomCell = Cell;
  const CustomHeaderCell = HeaderCell;

  const getCities = async () => {
    await ApiInstance.common
      .getAllCities()
      .then((res) => {
        const newArrayOfObj = res.data.data.map(({ id: value, city: city, province: province }) => ({
          value,
          label: `${isArabic(city) ? toPersianChars(city) : city}، ${
            isArabic(province) ? toPersianChars(province) : province
          }`,
        }));
        setCities(newArrayOfObj);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadExcelFile = (res) => {
    const url = URL.createObjectURL(new Blob([res.data]));
    console.log(url);
    const link = document.createElement("a");
    link.href = url;
    link.download = `virazh-providers-activities.xlsx`;
    link.click();
    useNotification("فایل فعالیت کسب و کارها با موفقیت دانلود شد.", "success", "دانلود فعالیت کسب و کارها");
  };

  const downloadHandler = async () => {
    const result = await ApiInstance.common
      .exportServiceProvidersActivities(selectedWorkshops, citiesType, selectedDate)
      .then((res) => {
        console.log(res);
        return {
          data: res,
          name: "activities",
        };
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "دانلود فعالیت کسب و کارها");
        vState.isEndOfPage = false;
        return null;
      })
      .finally(() => {
        vState.isEndOfPage = false;
      });

    return result;
  };

  const getActivities = async (
    workshops?: string[] | null,
    cities?: string | null,
    timeLine?: [string, string] | null,
  ) => {
    await ApiInstance.common
      .getServiceProvidersActivities(workshops, cities, timeLine)
      .then((res) => {
        console.log(res);
        let data: any[] = res.data.data.map((item) => {
          return item;
        });

        data = data.sort((a, b) => {
          return +b.sessionsCount - +a.sessionsCount;
        });

        setServiceProviders(data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "فعالیت کسب و کارها");
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    const minDate = new DateObject({ calendar: persian }).toFirstOfMonth();
    const maxDate = new DateObject({ calendar: persian });
    const timeLine: [string, string] = [moment(minDate.toDate()).toISOString(), moment(maxDate.toDate()).toISOString()];
    setSelectedDate(timeLine);
    setSelectedDateLabel([minDate?.toString(), maxDate?.toString()]);

    getCities();
    getActivities(null, null, timeLine);
  }, []);

  const confirmChangesHandler = (workshops: string[], city: string, timeLine: [string, string] | null) => {
    setLoading(true);
    setTimeout(() => {
      getActivities(workshops, city, timeLine);
    }, 1000);
  };

  const saveDateHandler = (date) => {
    if (date.length === 2) {
      const data: [string, string] = [moment(date[0].toDate()).toISOString(), moment(date[1].toDate()).toISOString()];
      setSelectedDate(data);
      setSelectedDateLabel([date[0]?.toString(), date[1]?.toString()]);
      // @ts-ignore
      datePickerRef.current?.closeCalendar();
      confirmChangesHandler(selectedWorkshops, citiesType, data);
    }
  };

  const renderCell = (value) => {
    return value ? value : "---";
  };

  const showWorkshopHandler = (workshop) => {
    navigate({ pathname: ConfigLink.serviceProviderDetail, search: useQueryString({ id: workshop.id }) });
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={26} className="">
        <Breadcrumb
          links={[
            {
              name: "کسب و کارها",
              active: false,
              link: "",
            },
            {
              name: "فعالیت کسب و کارها",
              active: false,
              link: "",
            },
          ]}
          className="mb-6"
        />

        <div className="w-full flex justify-between items-center mb-6">
          <Text type="H3" className="ts-accent">
            فعالیت کسب و کارها
          </Text>
          <div
            onClick={() => setDownloadModal(true)}
            className="w-10 h-10 p-2 rounded-xl ts-shadow bg-white cursor-pointer flex justify-center items-center"
          >
            <HiOutlineDownload size={22} className="ts-primary mr-px" />
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-full flex justify-between items-center gap-4 mb-2">
            <CheckPicker
              size="lg"
              //@ts-ignore
              ref={picker}
              data={snap.SelectPickerCategories}
              placement="auto"
              value={selectedWorkshops}
              disabled={loading}
              countable={false}
              cleanable
              onChange={(value: any) => setSelectedWorkshops(value)}
              onClean={() => {
                setSelectedWorkshops([]);
                confirmChangesHandler([], citiesType, selectedDate);
              }}
              searchable={false}
              placeholder="همه کسب و کارها"
              className={`ts-activity-filter truncate w-1/2 h-12 outline-none rounded-xl ts-text-p4`}
              renderExtraFooter={() => (
                <div className="w-full p-3 border-t-2 flex justify-center items-center">
                  <Button
                    bcolor="primary"
                    onClick={() => {
                      //@ts-ignore
                      picker.current.close();
                      confirmChangesHandler(selectedWorkshops, citiesType, selectedDate);
                    }}
                    classNameContainer="w-full"
                    className="w-full rounded-xl h-10"
                  >
                    تایید و بستن
                  </Button>
                </div>
              )}
            />

            <SelectPicker
              size="lg"
              data={cities}
              placement="auto"
              value={citiesType}
              disabled={loading}
              cleanable
              searchable
              onChange={(value: any) => {
                setCitiesType(value);
                confirmChangesHandler(selectedWorkshops, value, selectedDate);
              }}
              onClean={() => {
                setCitiesType("");
                confirmChangesHandler(selectedWorkshops, "", selectedDate);
              }}
              placeholder="همه شهرها"
              className={`ts-activity-filter w-1/2 h-12 outline-none rounded-xl ts-text-p4`}
            />
          </div>

          <DatePicker
            ref={datePickerRef}
            range
            dateSeparator=" - "
            disabled={loading}
            value={selectedDate.dateLabel}
            editable={false}
            onChange={saveDateHandler}
            weekDays={weekDays}
            maxDate={new DateObject({ calendar: persian })}
            calendar={persian}
            locale={persian_fa}
            containerClassName="w-full h-12 mb-8"
            className="purple"
            inputClass="w-full h-12"
            mapDays={({ date }) => {
              //disable friday
              let isWeekend = [6, 6].includes(date.weekDay.index);

              if (isWeekend)
                return {
                  disabled: false,
                  style: { color: "#900" },
                };
            }}
            render={(value, openCalendar) => {
              return (
                <div
                  onClick={openCalendar}
                  className="w-full h-12 px-4 py-3 rounded-xl ts-date-picker-border flex justify-between items-center"
                >
                  <Text
                    type="P4"
                    className={`${loading ? "ts-gray-03" : selectedDate.length === 0 ? "ts-accent" : "ts-primary"}`}
                  >
                    {selectedDate.length === 0 ? "انتخاب بازه زمانی" : selectedDateLabel.join(" - ")}
                  </Text>
                  {selectedDate.length === 0 ? (
                    <HiCalendar size={20} className={` ${loading ? "ts-gray-03" : "ts-accent"} -mt-px`} />
                  ) : (
                    <IoMdClose
                      onClick={() => {
                        setSelectedDate([]);
                        setSelectedDateLabel([]);
                        confirmChangesHandler(selectedWorkshops, citiesType, null);
                      }}
                      size={21}
                      className="ts-gray-07 -mt-px cursor-pointer"
                    />
                  )}
                </div>
              );
            }}
          />
        </div>

        {loading ? (
          <div className="w-full flex flex-col justify-center items-center mt-20">
            <CustomLoader />
            <Text type="HM" className="ts-gray-07 mt-5">
              لطفا صبر کنید...
            </Text>
          </div>
        ) : serviceProviders.length === 0 ? (
          <NoResult />
        ) : (
          <Table
            loading={loading}
            hover={false}
            showHeader
            // @ts-ignore
            autoHeight
            className="ts-providers-activity-wrapper"
            rowClassName=""
            affixHeader
            data={serviceProviders}
            bordered={false}
            cellBordered={false}
            virtualized
            headerHeight={36}
            rowHeight={52}
            onRowClick={(row) => showWorkshopHandler(row)}
          >
            {columns.map((column) => {
              const { key, label, style, ...rest } = column;
              return (
                <Column {...rest} key={key} align="center">
                  <CustomHeaderCell fixed className="ts-text-h6 ts-accent">
                    {label}
                  </CustomHeaderCell>
                  <CustomCell dataKey={key} verticalAlign="middle" className={`${style}`} renderCell={renderCell} />
                </Column>
              );
            })}
          </Table>
        )}
      </ContainerScroll>

      <DownloadPromptModal
        isOpen={downloadModal}
        onClose={() => setDownloadModal(false)}
        onApprove={downloadHandler}
        title="فعالیت کسب و کارها"
      />
    </>
  );
};

const defaultColumns = [
  {
    key: "remindersCount",
    label: "یادآور",
    flexGrow: 1,
    style: "ts-text-p4 ts-accent",
  },
  {
    key: "sessionsCount",
    label: "پذیرش",
    flexGrow: 1,
    style: "ts-text-p4 ts-accent",
  },
  {
    key: "city",
    label: "شهر",
    flexGrow: 1,
    style: "ts-text-p4 ts-accent",
  },
  // {
  //   key: "categories",
  //   label: "نوع",
  //   flexGrow: 1,
  //   style: "ts-text-p4 ts-accent",
  // },
  {
    key: "name",
    label: "نام",
    flexGrow: 1,
    style: "ts-text-h6 ts-primary cursor-pointer hover:underline",
  },
];

export { ServiceProvidersActivity as default };
