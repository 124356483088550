/* eslint-disable */
import { tools } from "core";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Text } from "ui/components";
import { LicensePlate } from "./LicensePlate";

interface IPlateMilageInfoProps {
  data: any;
  loading: boolean;
}

const PlateMilageInfo: React.FC<IPlateMilageInfoProps> = React.memo(({ data, loading }) => {
  return (
    <div className="w-full my-8 p-6 ts-bg-primary-light rounded-xl flex flex-col justify-start items-start">
      <Text type="H5" className="ts-gray-08">
        کارکرد فعلی:
      </Text>
      {loading ? (
        <Skeleton baseColor="#8F9CA9" className="w-28 my-2.5" />
      ) : (
        <Text type="H4" className="ts-accent my-2.5">
          {data.kilometer ? `${tools.thousandSeparator(data.kilometer)} کیلومتر` : "---"}
        </Text>
      )}
      {loading ? (
        <Skeleton baseColor="#8F9CA9" className="w-48 mb-6" />
      ) : (
        <Text type="H6" className="ts-gray-06 mb-6">
          آخرین تاریخ بروزرسانی:
          {data.updatedAt ? `${new Date(data.updatedAt).toLocaleDateString("fa-IR")}` : "---"}
        </Text>
      )}
      {loading ? (
        <Skeleton baseColor="#8F9CA9" containerClassName="w-full" className="h-14 rounded-xl" />
      ) : (
        <LicensePlate
          firstSection={+data.plateNumber.slice(0, 2)}
          letter={data.plateNumber.slice(2, 3) === "ا" ? "الف" : data.plateNumber.slice(2, 3)}
          secondSection={+data.plateNumber.substring(3, 6)}
          thirdSection={+data.plateNumber.substring(7, 9)}
        />
      )}
    </div>
  );
});

export { PlateMilageInfo };
