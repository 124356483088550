/* eslint-disable */
import defaultCar from "assets/images/part-request-default.svg";
import { configs } from "configs";
import { ConfigLink, tools, useQueryString } from "core";
import React from "react";
import { MdChevronLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Text } from "ui/components";

interface IPartCardProps {
  data: any;
}

const PartCard: React.FC<IPartCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  const requestClickHandler = () => {
    navigate({ pathname: ConfigLink.partDetail, search: useQueryString({ id: data.id }) });
  };

  return (
    <div
      onClick={requestClickHandler}
      key={data.id}
      className="w-full flex justify-between items-center p-3 bg-white rounded-2xl ts-shadow mb-4 cursor-pointer"
    >
      <div className="flex h-full flex-col justify-between items-start">
        <div className="flex justify-start items-start">
          <div className="w-12 h-12 rounded-xl">
            <img
              src={data.images && data.images.length !== 0 ? `${configs().imageUrl}/${data.images[0]}` : defaultCar}
              alt="default"
              className={`w-full h-full ${
                data.images && data.images.length !== 0 ? "object-cover aspect-square rounded-xl" : ""
              }`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultCar;
              }}
            />
          </div>
          <div className="flex flex-col justify-start items-start mr-3">
            <Text type="H6" className="ts-accent">
              {data.name || "---"}
            </Text>
            <Text type="P4" className="ts-gray-07 my-2">
              {data.cars && data.cars.length !== 0 ? data.cars.map((item) => item.name).join("، ") : "---"}
            </Text>
            <Text type="HM" className="ts-primary">
              <strong className="ts-text-h6">{data.price ? tools.thousandSeparator(data.price) : "---"}</strong>{" "}
              {data.price ? "تومان" : ""}
            </Text>
          </div>
        </div>
      </div>
      <MdChevronLeft size={25} className="ts-accent" />
    </div>
  );
});

export { PartCard };

const styles = {};
