/* eslint-disable */
import markerImg from "assets/images/marker.svg";
import { useFetchQueryString } from "core";
import L from "leaflet";
import React, { useEffect, useState } from "react";
import { FiCornerUpRight } from "react-icons/fi";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { Text, useNotification } from "ui/components";

interface IViewLocationProps {}

interface IQueryStringTransfer {
  lat: string;
  lng: string;
}

const ViewLocation: React.FC<IViewLocationProps> = React.memo(({}) => {
  const navigate = useNavigate();
  let { lat, lng } = useFetchQueryString<IQueryStringTransfer>();
  let mapIcon = L.icon({
    iconUrl: markerImg,
    iconSize: [40, 48],
    iconAnchor: [20, 50],
  });

  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    if (lat && lng) {
      setCurrentLocation([+lat, +lng]);
    } else {
      useNotification("امکان نمایش موقعیت مکانی کسب و کار در حال حاضر وجود ندارد.", "error");
      setTimeout(() => {
        navigate(-1);
      }, 500);
    }

    setTimeout(() => {
      setPageLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <div className="w-full h-full relative overflow-hidden">
        <div className="absolute top-8 right-6 z-30">
          <div
            onClick={() => navigate(-1)}
            className="h-10 px-3 bg-white rounded-xl absolute flex justify-start items-center cursor-pointer"
          >
            <FiCornerUpRight size={18} className="ts-primary" />
            <Text type="H6" className="ts-primary mr-1">
              بازگشت
            </Text>
          </div>
        </div>
        {pageLoading ? (
          <div className="w-full h-screen flex justify-center items-center">
            <Loader size="sm" content="لطفا صبر کنید..." vertical />
          </div>
        ) : (
          <>
            <MapContainer
              id="provider-map"
              whenReady={() => setPageLoading(false)}
              center={currentLocation}
              zoom={16}
              zoomControl={false}
              dragging={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker icon={mapIcon} autoPan={false} position={currentLocation}></Marker>
            </MapContainer>
          </>
        )}
      </div>
    </>
  );
});

export { ViewLocation as default };
