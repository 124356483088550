/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { StarRating, Text } from "ui/components";
import { FeedbackStatistics } from "./FeedbackStatistics";

interface IProviderRatingSectionProps {
  data: any;
  id: string;
}

const ProviderRatingSection: React.FC<IProviderRatingSectionProps> = React.memo(({ data, id }) => {
  const navigate = useNavigate();

  const [cardOpen, setCardOpen] = useState(false);

  useEffect(() => {}, []);

  return (
    <div
      onClick={() => setCardOpen((prev) => !prev)}
      className={`${
        cardOpen ? "ts-feedback-box-open" : "ts-workshop-feedback-box-close"
      } w-full flex flex-col justify-start bg-white cursor-pointer ts-shadow rounded-2xl py-3 px-4 items-center mb-4`}
    >
      <div className="w-full flex justify-between items-center">
        <div className="flex justify-center items-center">
          <StarRating
            // @ts-ignore
            initialValue={data && data.averageScore ? parseFloat(data.averageScore).toFixed(1) : 0}
            size={18}
          />

          <Text type="P3" className="ts-gray-06 mr-2.5 mt-0.5">
            {data.averageScore ? parseFloat(data.averageScore).toFixed(1) : 0}
          </Text>
        </div>
        <div
          onClick={() => navigate({ pathname: ConfigLink.serviceProviderFeedbacks, search: useQueryString({ id }) })}
          className="cursor-pointer flex justify-center items-center z-30"
        >
          <Text type="P5" className="ts-info mt-0.5">
            {+data.total || 0} دیدگاه
          </Text>

          <BiChevronDown
            size={23}
            className={`ts-accent transition-all duration-300 -mt-0.5 mr-1.5 ${cardOpen ? "rotate-180" : "rotate-0"}`}
          />
        </div>
      </div>
      {data.serviceQuality && <FeedbackStatistics label="کیفیت خدمت" data={data.serviceQuality} />}
      {data.wellBehaved && <FeedbackStatistics label="حسن رفتار" data={data.wellBehaved} />}
      {data.fairPrice && <FeedbackStatistics label="قیمت منصفانه" data={data.fairPrice} />}
      {data.equipment && <FeedbackStatistics label="قطعات و تجهیزات" data={data.equipment} />}
    </div>
  );
});

export { ProviderRatingSection };

const styles = {};
