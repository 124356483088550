/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { CardLoading, NoResult, useNotification, UserWorkshops } from "ui/components";
import { useSnapshot } from "valtio";

interface IUserWorkshopsTabProps {
  id: string;
}

const UserWorkshopsTab: React.FC<IUserWorkshopsTabProps> = React.memo(({ id }) => {
  const snap = useSnapshot(vState);

  const [tabLoading, setTabLoading] = useState(true);
  const [workshops, setWorkshops] = useState<any[]>([]);
  const [workshopsPage, setWorkshopsPage] = useState(1);
  const [workshopsCount, setWorkshopsCount] = useState(0);

  const getWorkshops = async () => {
    await ApiInstance.common
      .getUsersWorkshops(id, workshopsPage)
      .then((res) => {
        console.log("Workshops", res);
        setWorkshopsCount(res.data.meta.total);
        if (workshopsPage > 1) {
          let data = [...workshops, ...res.data.data];
          setWorkshops(data);
          setWorkshopsPage((prev) => prev + 1);
        } else {
          setWorkshops(res.data.data);
        }
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "لیست تعمیرگاه ها");
      })
      .finally(() => {
        setTabLoading(false);
      });
  };

  useEffect(() => {
    getWorkshops();
  }, [id]);

  useEffect(() => {
    if (snap.isEndOfPage && workshopsCount > workshops.length && !tabLoading) {
      setWorkshopsPage((prev) => prev + 1);
      getWorkshops();
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  return (
    <>
      {tabLoading ? (
        <CardLoading height={"231px"} />
      ) : workshops.length === 0 ? (
        <NoResult />
      ) : (
        workshops.map((item, index) => {
          return <UserWorkshops key={index} data={item} />;
        })
      )}
    </>
  );
});

export { UserWorkshopsTab };

const styles = {};
