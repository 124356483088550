/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import noSegment from "assets/images/no-segment-requested.svg";
import {
  ApiInstance,
  ConfigLink,
  useChangeTitle,
  useEffectNoInitial,
  useFetchQueryString,
  useObjectState,
  useQueryString,
  vState,
} from "core";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { SelectPicker } from "rsuite";
import { ContainerScroll, CustomLoader, SearchInput, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { PartCard, PartListCardLoader, PartsListLoader } from "./components";

interface IPartsListProps {}

type SORT_TYPE = "DATE" | "NAME" | "";

interface IQueryStringTransfer {
  q?: string;
  carId?: string[];
  company?: string;
  country?: string;
  cat?: string;
}

interface IFilter {
  car: null | any;
  manufacture: string;
  manufacturingCountry: string;
}

const PartsList: React.FC<IPartsListProps> = React.memo(() => {
  const metaTags = useChangeTitle("ویراژ - قطعات");
  let { q, carId, company, country, cat } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  const defaultValues: IFilter = {
    car: null,
    manufacture: "",
    manufacturingCountry: "",
  };

  const [requests, setRequests] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("");

  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useObjectState<any>(defaultValues);
  const { car, manufacture, manufacturingCountry } = filters;
  const [selectedCategory, setSelectedCategory] = useState("");

  const getPartsList = async (pageIncreased: boolean, query?: string | null, sort?: SORT_TYPE | null) => {
    await ApiInstance.shop
      .getParts(pageCount + 1, sort || sortReminders, query || searchText, filters, selectedCategory)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...requests, ...res.data.data];
          setRequests(data);
          setPageCount((prev) => prev + 1);
        } else {
          setRequests(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "قطعات");
      })
      .finally(() => {
        setLoading(false);
        setCardLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    if (q) {
      setSearchText(q);
      setSearch(q);
    }
    setFilters({
      car: carId ? { id: carId[0], name: carId[1] } : null,
      manufacture: company || "",
      manufacturingCountry: country || "",
    });
    if (cat) setSelectedCategory(cat);
  }, []);

  useEffectNoInitial(() => {
    updateURL(search, filters, selectedCategory);
    getPartsList(false, search);
  }, [filters, selectedCategory, search]);

  useEffect(() => {
    if (snap.isEndOfPage && count > requests.length && !loading) {
      getPartsList(true, searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    console.log(filters, selectedCategory);
    setCardLoading(true);
    getPartsList(false, search, value);
  };

  const callApi = (e) => {
    setCardLoading(true);
    setSearch(digitsFaToEn(e));
  };

  const handleChange = (e) => {
    setSearchText(e);
  };

  const resetSearch = () => {
    setSearchText("");
    setCardLoading(true);
    setTimeout(() => {
      callApi("");
    }, 1100);
  };

  const updateURL = (search: string, filter: IFilter, cat: string) => {
    navigate(
      {
        pathname: ConfigLink.partsList,
        search: useQueryString({
          ...(search ? { q: search } : undefined),
          ...(filter.car ? { carId: [filter.car.id, filter.car.name] } : undefined),
          ...(filter.manufacture ? { company: filter.manufacture } : undefined),
          ...(filter.manufacturingCountry ? { country: filter.manufacturingCountry } : undefined),
          ...(cat ? { cat } : undefined),
        }),
      },
      { replace: true },
    );
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={160} className={`relative overflow-x-hidden`}>
        {loading ? (
          <PartsListLoader />
        ) : (
          <>
            <div className="w-full fixed top-14 pt-6 bg-white z-40 ts-fixed-header">
              <div className="w-full flex justify-between items-center mb-4">
                <Text type="H4" className="ts-accent">
                  قطعات
                </Text>
              </div>
              <div className="w-full flex justify-between items-center mb-4 gap--2">
                <SearchInput
                  value={searchText}
                  onChange={handleChange}
                  onReset={resetSearch}
                  onSearch={() => callApi(searchText)}
                />

                {/* filter here */}
              </div>
            </div>

            <div className="w-full ts-container-low-margin">
              <div className="w-full flex justify-between items-center mb-4">
                {cardLoading ? (
                  <Skeleton className="w-12" />
                ) : (
                  <Text type="H6" className="ts-accent">
                    {`${count} قطعه`}
                  </Text>
                )}
                <SelectPicker
                  size="lg"
                  data={sortData}
                  value={sortReminders}
                  disabled={loading}
                  cleanable={false}
                  onChange={(value: any) => sortChangeHandler(value)}
                  searchable={false}
                  placeholder="مرتب سازی"
                  className={`w-36 h-12 outline-none rounded-xl`}
                />
              </div>

              {cardLoading ? (
                <PartListCardLoader />
              ) : requests.length === 0 ? (
                <div className="w-full flex flex-col justify-center items-center mt-20">
                  <img src={noSegment} alt="no-part" className="mb-6" />
                  <Text type="P4" className="ts-gray-07">
                    قطعه‌ای تاکنون ثبت نشده است.
                  </Text>
                </div>
              ) : (
                requests.map((item) => {
                  return <PartCard key={item.id} data={item} />;
                })
              )}
              {snap.isEndOfPage && !loading && (
                <div className="w-full flex justify-center my-6">
                  <CustomLoader />
                </div>
              )}
            </div>
          </>
        )}
      </ContainerScroll>
    </>
  );
});

const sortData = [
  {
    label: "به ترتیب تاریخ",
    value: "DATE",
  },
  {
    label: "به ترتیب الفبا",
    value: "NAME",
  },
];

export { PartsList as default };
