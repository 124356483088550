/* eslint-disable */
import { useEffect, useState } from "react";
import { HiPencilAlt } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { Toggle } from "rsuite";
import { ContainerScroll, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { ApiInstance, tools, useChangeTitle, useFetchQueryString, useObjectState, vState } from "../../../core";
import { AddEditReminderModal, RelatedServicesModal, ServiceDescriptionModal } from "./components";

interface IQueryStringTransfer {
  id: string;
}

const ReminderDetail = ({}) => {
  const metaTags = useChangeTitle("ویراژ - اطلاعات یادآور");
  let { id } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const [addServiceShow, setAddServiceShow] = useState(false);
  const [relatedServiceShow, setRelatedServiceShow] = useState(false);
  const [descriptionShow, setDescriptionShow] = useState(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);

  const [service, setService] = useObjectState<any>({
    description: null,
    disabled: null,
    expiresType: null,
    showInPlateFirstSetup: false,
    expiresInDays: 0,
    expiresInKilometers: 0,
    id: "",
    name: "",
    recommendations: [],
  });
  const {
    name,
    description,
    disabled,
    expiresType,
    expiresInDays,
    expiresInKilometers,
    showInPlateFirstSetup,
    recommendations,
  } = service;

  const [loading, setLoading] = useState(true);

  const getReminders = async () => {
    await ApiInstance.common
      .getServiceDetail(id)
      .then((res: any) => {
        console.log(res);
        setService(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "یادآورها");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getReminders();
  }, []);

  const prepareData = () => {
    return { name, description, disabled, expiresType, expiresInDays, expiresInKilometers, showInPlateFirstSetup };
  };

  const editItemHandler = () => {
    setAddServiceShow(true);
  };

  const relatedItemHandler = () => {
    setRelatedServiceShow(true);
  };

  const editHandler = async (data, onDone: () => void, onError: () => void, onEnd: () => void) => {
    await ApiInstance.reminders
      .editReminder(service.id, data)
      .then((res) => {
        console.log(res);
        onDone();
      })
      .catch((err) => {
        console.log("err", err);
        onError();
      })
      .finally(() => {
        onEnd();
      });
  };

  const isArchiveHandler = async () => {
    setIsArchiveLoading(true);
    const data = { name, disabled: !disabled, expiresType };
    console.log(data);

    editHandler(
      data,
      () => {
        useNotification(`سرویس ${disabled ? "" : "غیر"} فعال شد.`, "success", `آرشیو کردن یادآور`);
        setService({ disabled: !disabled });
      },
      () => {
        useNotification("مشکلی رخ داده است. لطفا بعدا امتحان کنید.", "error", `آرشیو کردن یادآور`);
      },
      () => {
        setIsArchiveLoading(false);
      },
    );
  };

  const editDescriptionHandler = async (value) => {
    const data = { name, description: value, expiresType };

    editHandler(
      data,
      () => {
        useNotification(`توضیحات به سرویس اضافه شد`, "success", `توضیحات`);
        setService({ description: value });
      },
      () => {
        useNotification("مشکلی رخ داده است. لطفا بعدا امتحان کنید.", "error", `توضیحات`);
      },
      () => {
        setDescriptionShow(false);
      },
    );
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={60} className="">
        {loading ? (
          <Skeleton className="w-36 mb-8" />
        ) : (
          <Text type="H3" className="ts-accent mb-8">
            {name || "---"}
          </Text>
        )}
        {loading ? (
          <Skeleton style={{ height: "123px" }} containerClassName="w-full" className="w-full rounded-xl mb-4" />
        ) : (
          <div
            style={{ height: "123px" }}
            className={`w-full rounded-xl ts-shadow mb-4 p-3 relative bg-white flex flex-col justify-start items-start`}
          >
            {snap.superAdmin && (
              <HiPencilAlt
                size={23}
                className="ts-primary absolute top-3 left-3 -rotate-90"
                onClick={() => editItemHandler()}
              />
            )}
            <Text type="H6" className="ts-gray-08 mb-7">
              جزئیات یادآور
            </Text>
            <div className="flex justify-center items-center mb-4">
              <Text type="HM" className="ts-accent w-36">
                کیلومتر پیشنهادی تعویض
              </Text>
              <Text type="P5" className="ts-accent">
                {expiresInKilometers ? `${tools.thousandSeparator(expiresInKilometers)} کیلومتر` : "---"}
              </Text>
            </div>
            <div className="flex justify-center items-center">
              <Text type="HM" className="ts-accent w-36">
                مدت زمان تعویض
              </Text>
              <Text type="P5" className="ts-accent">
                {expiresInDays ? `${expiresInDays / 30} ماه` : "---"}
              </Text>
            </div>
          </div>
        )}

        {loading ? (
          <Skeleton style={{ height: "69px" }} containerClassName="w-full" className="w-full rounded-xl mb-4" />
        ) : (
          <div className={`w-full rounded-2xl relative ts-shadow mb-4 p-3 bg-white flex flex-col justify-center`}>
            <div className="w-full flex justify-between items-center mb-4">
              <Text type="H6" className="ts-gray-08 mb-2">
                آرشیو کردن یادآور
              </Text>
              <Toggle
                size="sm"
                className="ts-reminders-toggle"
                loading={isArchiveLoading}
                checked={Boolean(disabled)}
                onChange={isArchiveHandler}
                disabled={!snap.superAdmin}
              />
            </div>
            <Text type="P5" className="ts-gray-04">
              آرشیو کردن به معنای حذف موقت یک یادآور می باشد.
            </Text>
          </div>
        )}

        {loading ? (
          <Skeleton style={{ height: "94px" }} containerClassName="w-full" className="w-full rounded-xl mb-4" />
        ) : (
          <div
            className={`w-full rounded-xl ts-shadow mb-4 p-3 relative bg-white flex flex-col justify-start items-start`}
          >
            {snap.superAdmin && (
              <HiPencilAlt
                size={23}
                className="ts-primary absolute top-3 left-3 -rotate-90"
                onClick={() => relatedItemHandler()}
              />
            )}
            <Text type="H6" className="ts-gray-08 mb-8">
              یادآور های مرتبط
            </Text>
            <Text type="P5" className="ts-gray-07">
              {recommendations && recommendations.length !== 0
                ? recommendations.map((item: any) => item.service?.name || "---").join(", ")
                : "----"}
            </Text>
          </div>
        )}

        {loading ? (
          <Skeleton style={{ height: "94px" }} containerClassName="w-full" className="w-full rounded-xl mb-4" />
        ) : (
          <div
            className={`w-full rounded-xl ts-shadow mb-4 p-3 relative bg-white flex flex-col justify-start items-start`}
          >
            {snap.superAdmin && (
              <HiPencilAlt
                size={23}
                className="ts-primary absolute top-3 left-3 -rotate-90"
                onClick={() => setDescriptionShow(true)}
              />
            )}
            <Text type="H6" className="ts-gray-08 mb-8">
              توضیحات
            </Text>
            <Text type="P5" className="ts-gray-07 text-justify">
              {description || "موردی ثبت نشده است."}
            </Text>
          </div>
        )}
      </ContainerScroll>
      <AddEditReminderModal
        isOpen={addServiceShow}
        onClose={() => setAddServiceShow(false)}
        onDone={() => getReminders()}
        forUpdate
        value={service}
        id={id}
      />

      <RelatedServicesModal
        isOpen={relatedServiceShow}
        onClose={() => setRelatedServiceShow(false)}
        onDone={() => getReminders()}
        value={service}
        id={id}
      />

      <ServiceDescriptionModal
        isOpen={descriptionShow}
        onClose={() => setDescriptionShow(false)}
        onDone={(value) => editDescriptionHandler(value)}
        value={description}
      />
    </>
  );
};

export { ReminderDetail as default };
