/* eslint-disable */
import React from "react";
import { Tab } from "ui/components";

interface IUserDetailTabBarProps {
  activeTab: number;
  onActiveChanged: (id: number) => void;
}

const UserDetailTabBar: React.FC<IUserDetailTabBarProps> = React.memo(({ activeTab, onActiveChanged }) => {
  return (
    <div className="w-full flex-nowrap whitespace-nowrap overflow-x-auto overflow-y-hidden ts-hide-scroll-x-but-keep-working touch-pan-x border-b-2 border-b-gray-300 flex justify-start items-center mb-6">
      <Tab onClick={(id) => onActiveChanged(id)} tabNumber={1} title="خودروها" activeTabNumber={activeTab} />
      <Tab
        className="mx-4"
        onClick={(id) => onActiveChanged(id)}
        tabNumber={2}
        title="کسب و کارها"
        activeTabNumber={activeTab}
      />
      <Tab onClick={(id) => onActiveChanged(id)} tabNumber={3} title="دعوت شده ها" activeTabNumber={activeTab} />
      <Tab onClick={(id) => onActiveChanged(id)} tabNumber={4} title="نظرات" activeTabNumber={activeTab} />
    </div>
  );
});

export { UserDetailTabBar };
