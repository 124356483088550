/* eslint-disable */
import React from "react";
import { ImageSlider, Text } from "ui/components";

interface IPartRequestDetailSegmentInfoProps {
  data: any;
}

const PartRequestDetailSegmentInfo: React.FC<IPartRequestDetailSegmentInfoProps> = React.memo(({ data }) => {
  return (
    <div className="w-full rounded-2xl ts-shadow p-4 mb-6 flex flex-col justify-start items-start">
      <div className="w-full flex justify-between items-center mb-4">
        <Text type="H5" className="ts-accent ">
          اطلاعات قطعه
        </Text>
      </div>
      <div className="w-full flex justify-start items-start mb-3">
        <Text type="H6" className="ts-gray-06 w-69 ml-2">
          خودرو
        </Text>
        <Text type="P4" className="ts-accent">
          {data.car?.name || "---"}
        </Text>
      </div>

      <div className="w-full flex justify-start items-start">
        <Text type="H6" className="ts-gray-06 w-69 ml-2">
          توضیحات
        </Text>
        <Text type="P4" className="ts-accent ts-request-description">
          {data.description || "---"}
        </Text>
      </div>

      {data.images && data.images.length !== 0 && (
        <div className="w-full flex flex-col justify-start items-start mt-3">
          <Text type="H6" className="ts-gray-06 w-69 mb-2">
            عکس قطعه
          </Text>
          <ImageSlider images={data.images} dir="/parts" />
        </div>
      )}
    </div>
  );
});

export { PartRequestDetailSegmentInfo };

const styles = {};
