/* eslint-disable */
import { ApiInstance, ConfigLink, tools, useChangeTitle, useFetchQueryString, useQueryString, vState } from "core";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, CardLoading, ContainerScroll, Text, useNotification } from "ui/components";
import { PlateReminderCard } from "./components";

interface IQueryStringTransfer {
  id: string;
  plateId: string;
}

const PlateHistoryDetail = ({}) => {
  const metaTags = useChangeTitle("ویراژ - تاریخچه خودرو");
  let { id, plateId } = useFetchQueryString<IQueryStringTransfer>();
  const navigate = useNavigate();

  const [data, setData] = useState<any>({});
  const [reminders, setReminders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const getReminders = async () => {
    await ApiInstance.plates
      .getHistoryDetail(id)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setReminders(res.data.data.reminder);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "تاریخچه خودرو");
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getReminders();
  }, []);

  const getWorkshopDetail = () => {
    const wsId = data && data.workshop && data.workshop.id ? data.workshop.id : "";
    navigate({ pathname: ConfigLink.serviceProviderDetail, search: useQueryString({ id: wsId }) });
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={16} className="">
        <Breadcrumb
          links={[
            {
              name: "لیست خودروها",
              active: true,
              link: ConfigLink.plates,
            },
            {
              name: "جزییات خودرو",
              active: true,
              link: `${ConfigLink.plateDetail}?${useQueryString({ id: plateId })}`,
            },
            {
              name: "جزییات تاریخچه",
              active: false,
              link: ``,
            },
          ]}
          className="mb-8"
        />
        <Text type="H4" className="ts-gray-08 mb-8">
          جزییات تاریخچه
        </Text>

        {loading ? (
          <Skeleton containerClassName="w-full" className="w-full h-40 rounded-2xl mb-6" />
        ) : (
          <div className="w-full rounded-2xl mb-6 flex flex-col justify-start items-start p-4 ts-shadow bg-white">
            <Text type="H5" className="ts-accent mb-4">
              {data.updatedAt
                ? `${new Date(data.updatedAt).toLocaleDateString("fa-IR")} ساعت ${new Date(
                    data.updatedAt,
                  ).toLocaleTimeString("fa-IR", { hour: "2-digit", minute: "2-digit" })}`
                : "---"}
            </Text>

            <div className="flex justify-start items-center mb-3">
              <Text type="H6" className="ts-gray-06 w-28 ml-3">
                کسب و کار:
              </Text>
              <Text
                type="H6"
                onClick={() => getWorkshopDetail()}
                className={`${data && data.workshop && data.workshop.name ? "ts-primary cursor-pointer" : "ts-accent"}`}
              >
                {data && data.workshop ? data.workshop.name : "---"}
              </Text>
            </div>

            <div className="flex justify-start items-center mb-3">
              <Text type="H6" className="ts-gray-06 w-28 ml-3">
                پذیرش کننده:
              </Text>
              <Text
                type="H6"
                onClick={() => getWorkshopDetail()}
                className={`${data && data.workshop && data.workshop.name ? "ts-primary cursor-pointer" : "ts-accent"}`}
              >
                {data && data.workshop ? data.workshop.name : "---"}
              </Text>
            </div>

            <div className="flex justify-start items-center">
              <Text type="H6" className="ts-gray-06 w-28 ml-3">
                کارکرد:
              </Text>
              <Text type="P4" className={`ts-accent`}>
                {data && data.kilometer ? `${tools.thousandSeparator(data.kilometer)} کیلومتر` : "---"}
              </Text>
            </div>
          </div>
        )}

        <Text type="H5" className="text-right ts-accent mb-4">
          یادآورهای ثبت شده
        </Text>

        {loading ? (
          <CardLoading height="72px" />
        ) : (
          reminders.map((item) => {
            return (
              <PlateReminderCard
                data={item}
                currentKilometer={data && data.kilometer ? data.kilometer : 0}
                plateId={plateId}
              />
            );
          })
        )}
      </ContainerScroll>
    </>
  );
};

export { PlateHistoryDetail as default };
