/* eslint-disable */
import { ApiInstance, useObjectState, vState } from "core";
import React, { useEffect, useState } from "react";
import { BottomModal, Button, InputField, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

type IType = "add" | "edit";
const env = import.meta.env;

interface IAddEditCityModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: IType;
  data?: any;
  onDone: () => void;
}

const AddEditCityModal: React.FC<IAddEditCityModalProps> = React.memo(({ isOpen, onClose, type, data, onDone }) => {
  const snap = useSnapshot(vState);

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useObjectState<any>({
    city: "",
    province: "",
  });
  const { city, province } = values;

  useEffect(() => {
    if (isOpen && data) {
      console.log(data);

      setValues({
        city: data.city || "",
        province: data.province || "",
      });
    }
    if (type === "add") {
      setValues({
        city: "",
        province: "",
      });
    }
  }, [isOpen]);

  const addCity = async () => {
    await ApiInstance.common
      .addCity(values)
      .then((res) => {
        console.log(res);
        useNotification("شهر جدید اضافه شد.", "success", "اضافه کردن برند جدید");
        onDone();
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "اضافه کردن برند جدید");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCity = async () => {
    await ApiInstance.common
      .editCity(data.id || "", values)
      .then((res) => {
        console.log(res);
        useNotification("شهر و استان ویرایش شد.", "success", "اضافه کردن برند جدید");
        onDone();
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "اضافه کردن برند جدید");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clickHandler = () => {
    setLoading(true);
    if (type === "add") {
      addCity();
    } else {
      editCity();
    }
  };

  return (
    <>
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <Text type="H4" className="ts-accent text-right mb-6">
          {type === "add" ? "اضافه کردن شهر جدید" : "ویرایش شهر"}
        </Text>

        <InputField label="شهر" value={city} onChange={(e) => setValues({ city: e })} placeholder="شهر" />

        <InputField label="استان" value={province} onChange={(e) => setValues({ province: e })} placeholder="استان" />

        <div className="w-full flex justify-between items-center gap-3">
          <Button
            onClick={clickHandler}
            loading={loading}
            disabled={province.length === 0 || city.length === 0}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl"
          >
            ذخیره
          </Button>
          <Button
            onClick={onClose}
            bcolor="primaryTransparent"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl"
          >
            لغو
          </Button>
        </div>
      </BottomModal>
    </>
  );
});

export { AddEditCityModal };

const styles = {};
