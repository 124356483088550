/* eslint-disable */
import owner_version from "assets/images/owner_version.svg";
import provider_version from "assets/images/provider_version.svg";
import React from "react";
import { HiPencilAlt } from "react-icons/hi";
import { Text } from "../../../components";

interface IVersionCardProps {
  data: any;
  onEdit: () => void;
}

const VersionCard: React.FC<IVersionCardProps> = React.memo(({ data, onEdit }) => {
  return (
    <div
      key={data.id}
      style={{ height: "76px" }}
      className={`w-full bg-white flex justify-between items-center ts-shadow rounded-2xl p-3 mb-4`}
    >
      <div className="h-full flex justify-center items-center">
        <img src={data.application === "service_provider" ? provider_version : owner_version} />
        <div className="h-full flex flex-col mr-3 justify-between items-start">
          <Text type="H6" className="ts-accent mt-0.5">
            {data.versionName || "---"}
          </Text>
          <Text type="P4" className="ts-gray-06">
            {data.application === "service_provider" ? "اپ سرویس دهنده" : "اپ مالک"}
          </Text>
        </div>
      </div>
      <div
        role="button"
        onClick={onEdit}
        className={`w-10 h-10 ts-bg-gray-01 flex justify-center items-center cursor-pointer rounded-xl`}
      >
        <HiPencilAlt size={23} className="ts-primary -rotate-90" />
      </div>
    </div>
  );
});

export { VersionCard };

const styles = {};
