/* eslint-disable */
import { ConfigLink, tools, useQueryString } from "core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Text } from "./";

interface IUserWorkshopsProps {
  data: any;
}

const UserWorkshops: React.FC<IUserWorkshopsProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const seeDetailHandler = () => {
    navigate({ pathname: ConfigLink.serviceProviderDetail, search: useQueryString({ id: data.id }) });
  };

  return (
    <div style={{ height: "231px" }} className={`w-full bg-white ts-shadow rounded-2xl p-4 mb-4`}>
      <div className="w-full flex justify-between items-center mb-6">
        <div className={`flex w-full justify-start items-center`}>
          <Text type="H5" className="ts-accent">
            {data.name || "بدون نام"}
          </Text>
          {data.archive && (
            <div className="ts-bg-gray-03 mr-2 flex justify-center items-center rounded-full px-4 py-1">
              <Text type="HM" className="ts-gray-06">
                آرشیو شده
              </Text>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-start items-start mb-3">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          نوع:
        </Text>
        <Text type="P5" className="ts-accent">
          {data.categories ? data.categories.map((item) => item.name).join("، ") || "---" : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          شهر:
        </Text>
        <Text type="P5" className="ts-accent">
          {data.city ? `${data.city.city || ""}, ${data.city.province || ""}` : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          زمان ایجاد:
        </Text>
        <Text type="P5" className="ts-accent">
          {data.createdAt ? tools.getCardsTime(data.createdAt) : "---"}
        </Text>
      </div>

      <Button
        onClick={seeDetailHandler}
        bcolor="primaryTransparent"
        textType="H6"
        classNameContainer="w-full"
        className="w-full rounded-xl h-12"
      >
        مشاهده جزئیات
      </Button>
    </div>
  );
});

export { UserWorkshops };

const styles = {};
