/* eslint-disable */
import qs from "qs";
import { axios } from "./api";

interface IPart {
  name: string;
  car: string;
  price: number | string;
  manufacture: string;
  grouping: string;
  manufacturingCountry: string;
  code?: string;
  description?: string;
}

interface IGrouping {
  name: string;
  alias: string;
  image?: string;
}

const baseUrl = "/shops";

const shop = {
  getParts: async (
    page: number,
    sort: "DATE" | "NAME" | "",
    query: string | null,
    filter: any,
    grouping: string | null,
  ) => {
    const url = `${baseUrl}/search`;
    const result = await axios.post(url, {
      pageSize: 50,
      page,
      ...(sort ? { sort } : undefined),
      ...(query ? { query } : undefined),
      ...(filter.car ? { carId: filter.car.id } : undefined),
      ...(filter.manufacture ? { manufacture: filter.manufacture } : undefined),
      ...(filter.manufacturingCountry ? { manufacturingCountry: filter.manufacturingCountry } : undefined),
      // ...(filter.cityId ? { cityId: filter.cityId } : undefined),
      ...(grouping ? { grouping } : undefined),
    });

    return result;
  },
  addPart: async (data: IPart) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, data);

    return result;
  },
  getPartInfo: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  editPartInfo: async (id: string, data: IPart) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  deletePart: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.delete(url);

    return result;
  },
  getWorkshopParts: async (page: number, workshopId: string) => {
    const url = `${baseUrl}/search`;
    const result = await axios.post(url, {
      pageSize: 50,
      page,
      workshopId,
    });

    return result;
  },
  addPartCategories: async (data: IGrouping) => {
    const url = `${baseUrl}/groupings`;
    const result = await axios.post(url, data);

    return result;
  },
  getPartCategories: async (page: number, query?: string | null) => {
    const url = `${baseUrl}/groupings`;
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
        ...(query ? { query } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  editPartCategories: async (id: string, data: IGrouping) => {
    const url = `${baseUrl}/${id}/groupings`;
    const result = await axios.put(url, data);

    return result;
  },
  deletePartCategories: async (id: string) => {
    const url = `${baseUrl}/${id}/groupings`;
    const result = await axios.delete(url);

    return result;
  },
};

export { shop };
