/* eslint-disable */
import React from "react";
import { HiPencilAlt } from "react-icons/hi";
import { Text } from "ui/components";

interface IProviderFeatureCardProps {
  data: any;
  featureList: string;
  onEdit: () => void;
}

const ProviderFeatureCard: React.FC<IProviderFeatureCardProps> = React.memo(({ data, featureList, onEdit }) => {
  return (
    <div
      key={data.id}
      style={{ height: "78px" }}
      className={`w-full rounded-2xl ts-shadow mb-4 p-3 bg-white flex justify-between items-center`}
    >
      <div className="w-full flex justify-between items-start">
        <div className="w-11/12 flex flex-col justify-between items-start">
          <Text type="H6" className="ts-accent">
            {data.name || "نام تعریف نشده"}
          </Text>

          <Text type="P5" className="w-11/12 truncate ts-accent mt-3 font-light">
            {featureList}
          </Text>
        </div>

        <HiPencilAlt size={23} className="ts-primary -mt-0.5 -rotate-90 cursor-pointer" onClick={onEdit} />
      </div>
    </div>
  );
});

export { ProviderFeatureCard };

const styles = {};
