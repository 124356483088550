/* eslint-disable */
import { ConfigLink, useChangeTitle, useFetchQueryString } from "core";
import { useEffect, useState } from "react";
import { Breadcrumb, ContainerScroll, Text } from "ui/components";
import {
  UserDetailTabBar,
  UserFeedbacksTab,
  UserInformationBox,
  UserInviteeTab,
  UserPlateTab,
  UserWorkshopsTab,
} from "./components";

interface IQueryStringTransfer {
  id: string;
}

const UserDetail = ({}) => {
  const metaTags = useChangeTitle("ویراژ - مشخصات کاربر");
  let { id } = useFetchQueryString<IQueryStringTransfer>();

  const [activeTab, setActiveTab] = useState(1);
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const updateScrollDirection = () => {
      //change this, compare to owner
      let st = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      // console.log(st);

      if (st >= 323) {
        setShrunk(true);
      } else {
        setShrunk(false);
      }
    };
    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, []);

  const tabChangedHandler = (number) => {
    setActiveTab(number);
  };

  const renderTabsHandler = () => {
    if (activeTab === 1) {
      return <UserPlateTab id={id} />;
    } else if (activeTab === 2) {
      return <UserWorkshopsTab id={id} />;
    } else if (activeTab === 3) {
      return <UserInviteeTab id={id} />;
    } else {
      return <UserFeedbacksTab id={id} />;
    }
  };

  return (
    <div className="w-full flex-col justify-start items-start">
      {metaTags}
      <Breadcrumb
        links={[
          {
            name: "لیست کاربران",
            active: true,
            link: ConfigLink.usersList,
          },
          {
            name: "جزییات کاربر",
            active: false,
            link: ``,
          },
        ]}
        className="mt-24 mb-9 mx-6 max-2xs:mx-3"
      />

      <ContainerScroll heightOffset={20} className="pt-0">
        <Text type="H3" className="ts-accent mb-7">
          مشخصات کاربر
        </Text>

        <UserInformationBox id={id} />
        {/* <hr className="w-full mb-6" /> */}

        <div className={`w-full transition-all duration-500 ts-car-status-info bg-white`}>
          <div
            className={`w-full transition-all duration-75 ${
              isShrunk ? "fixed top-14 left-0 px-6 max-2xs:px-3 pt-9 bg-white z-20" : ""
            }`}
          >
            <UserDetailTabBar activeTab={activeTab} onActiveChanged={(number) => tabChangedHandler(number)} />
          </div>
        </div>

        <div className="w-full">{renderTabsHandler()}</div>
      </ContainerScroll>
    </div>
  );
};

export { UserDetail as default };
