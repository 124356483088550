/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import {
  ApiInstance,
  ConfigLink,
  useChangeTitle,
  useFetchQueryString,
  useObjectState,
  useQueryString,
  vState,
} from "core";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import { HiOutlineAdjustments, HiOutlineDownload } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import {
  CardLoading,
  ContainerScroll,
  CustomLoader,
  DownloadPromptModal,
  NoResult,
  SearchInput,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";
import { UserCard, UsersFilterModal } from "./components";

const env = import.meta.env;

type SORT_TYPE = "NON" | "DATE" | "NAME";

interface IQueryStringTransfer {
  q?: string;
  ct?: any;
  dates?: any[];
}

const Users = ({}) => {
  const metaTags = useChangeTitle("ویراژ - لیست کاربران");
  let { q, ct, dates } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [users, setUsers] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [downloadModal, setDownloadModal] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");

  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useObjectState<any>({
    city: "",
    date: [],
  });

  const getUsers = async (pageIncreased: boolean, query: string | null, filterObj?: any) => {
    if (!pageIncreased) setPageCount(0);
    const filter = {
      city: filterObj ? filterObj.city : ct || "",
      date: filterObj ? filterObj.date : dates || [],
    };

    await ApiInstance.common
      .getUsers("data", pageCount + 1, query, filter)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          setPageCount((prev) => prev + 1);
          let data = [...users, ...res.data.data];
          setUsers(data);
        } else {
          setUsers(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          setUsers([]);
        } else {
          useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "لیست کاربرها");
        }
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  const downloadHandler = async () => {
    const filter = {
      city: ct || "",
      date: dates || [],
    };
    const result = await ApiInstance.common
      .getUsers("export", 1, q || null, filter)
      .then((res) => {
        console.log(res);
        return {
          data: res,
          name: "users",
        };
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "دانلود لیست کاربران");
        return null;
      });

    return result;
  };

  useEffect(() => {
    if (q) setSearchText(q);
    const filterObj = {
      city: ct || "",
      date: dates || [],
    };
    setFilters(filterObj);
    getUsers(false, q || null);
  }, []);

  useEffect(() => {
    setLoading(true);
    updateURL(search, ct || filters.city, dates || filters.date);
    getUsers(false, search);
  }, [search]);

  useEffect(() => {
    if (snap.isEndOfPage && count > users.length && !loading) {
      getUsers(true, q || searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const callApi = (e) => {
    setSearch(digitsFaToEn(e));
  };
  const [debouncedCallApi] = useState(() => _debounce((e) => callApi(e), 1100));

  const handleChange = (e) => {
    setSearchText(e);
  };

  const resetSearch = () => {
    setSearchText("");
    setSearch("");
  };

  const updateURL = (search: string | null, city: string | null, formattedDate: string[] | null) => {
    navigate(
      {
        pathname: ConfigLink.usersList,
        search: useQueryString({
          q: search || undefined,
          ct: city || undefined,
          dates: formattedDate || undefined,
        }),
      },
      { replace: true },
    );
  };

  const getFilterData = (filter) => {
    setFilters(filter);
    console.log("func", filter);
    updateURL(q || searchText, filter.city, filter.date);
    setLoading(true);
    getUsers(false, q || searchText, filter);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={20} className="">
        <div className="w-full fixed top-14 pt-6 bg-white z-40 ts-fixed-header">
          <div className="w-full flex justify-between items-center mb-6">
            <Text type="H3" className="ts-accent">
              لیست کاربران
            </Text>
            <div className="flex justify-center items-center">
              <div
                onClick={() => setDownloadModal(true)}
                className="w-10 h-10 p-2 mr-2 rounded-xl ts-shadow bg-white cursor-pointer flex justify-center items-center"
              >
                <HiOutlineDownload size={22} className="ts-accent mr-px" />
              </div>
            </div>
          </div>

          <div className="w-full flex justify-between items-center mb-6 gap-2">
            <SearchInput
              value={searchText}
              onChange={handleChange}
              onReset={resetSearch}
              onSearch={() => callApi(searchText)}
            />

            <div
              onClick={() => setFilterOpen(true)}
              className="w-14 h-12 rounded-xl ts-shadow bg-white cursor-pointer p-2 flex justify-center items-center relative"
            >
              <HiOutlineAdjustments size={22} className="ts-accent" />
              {(filters.city.length !== 0 || filters.date.length === 2) && (
                <BsCircleFill size={10} className="ts-primary absolute -top-1 -left-1" />
              )}
            </div>
          </div>
        </div>

        <div className="w-full mt-32">
          <div className="w-full flex justify-between items-center mb-4">
            {loading ? (
              <Skeleton className="w-28" />
            ) : (
              users.length !== 0 && (
                <Text type="H6" className="ts-accent">
                  {`${count} کاربر`}
                </Text>
              )
            )}
          </div>
          {loading ? (
            <CardLoading height="76px" />
          ) : users.length === 0 ? (
            <NoResult forSearch={searchText.length !== 0} />
          ) : (
            users.map((item) => {
              return <UserCard key={item.id} data={item} />;
            })
          )}
          {snap.isEndOfPage && !loading && (
            <div className="w-full flex justify-center">
              <CustomLoader />
            </div>
          )}
        </div>
      </ContainerScroll>

      <UsersFilterModal
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onFilterChanged={getFilterData}
        filter={filters}
      />

      <DownloadPromptModal
        isOpen={downloadModal}
        onClose={() => setDownloadModal(false)}
        onApprove={downloadHandler}
        title="کاربران"
      />
    </>
  );
};

export { Users as default };
