/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import { CardLoading, DownloadPromptModal, NoResult, SearchInput, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

import { ApiInstance, vState } from "../../../core";
import { useChangeTitle } from "../../../core/hooks";
import { Breadcrumb, Button, ContainerScroll, Text } from "../../components";
import { AddEditCityModal, CityCard, DeleteCityModal, FunctionalButtons } from "./components";

const CitiesList = ({}) => {
  const metaTags = useChangeTitle("ویراژ - شهرها و استان‌ها");
  const snap = useSnapshot(vState);

  const [addEditModal, setAddEditModal] = useState(false);
  const [modalType, setModalType] = useState<"add" | "edit">("add");
  const [deleteModal, setDeleteModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);

  const [forUpdate, setForUpdate] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any>(null);

  const [cities, setCities] = useState<any[]>([]);
  const [citiesAll, setCitiesAll] = useState<any[]>([]);

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const getCities = async () => {
    await ApiInstance.common
      .getCities()
      .then((res) => {
        console.log(res);
        setCities(res.data.data);
        setCitiesAll(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "برند و تیپ خودرو‌ها");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCities();
  }, []);

  const searchHandler = (e, list) => {
    console.log(e);
    let result;
    if (e.length === 0) {
      result = list;
    } else {
      result = list.filter((item) => item.city.includes(e) || item.province.includes(e));
    }
    console.log(result);

    setCities(result);
    setLoading(false);
  };

  const callApi = (e, list) => {
    setLoading(true);
    searchHandler(digitsFaToEn(e), list);
  };

  const handleChange = (e) => {
    setSearchText(e);
  };

  const resetSearch = () => {
    setSearchText("");
    setLoading(true);
    setCities(citiesAll);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const selectHandler = (item) => {
    setSelectedCity(item);
  };

  const editHandler = () => {
    setModalType("edit");
    setAddEditModal(true);
  };

  const deleteHandler = () => {
    if (selectedCity) {
      const finalList = citiesAll.filter((item) => item.id !== selectedCity.id);
      setCities(finalList);
      setCitiesAll(finalList);
    }
  };

  const downloadHandler = async () => {
    const result = await ApiInstance.common
      .downloadCities()
      .then((res) => {
        console.log(res);
        return {
          data: res,
          name: "cities",
        };
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "دانلود خودروهای ثبت شده");
        return null;
      });

    return result;
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={110} className="">
        <div className="w-full fixed top-14 pt-6 bg-white z-40 ts-fixed-header">
          <Breadcrumb
            links={[
              {
                name: "شهرها و استان‌ها",
                active: false,
                link: "",
              },
            ]}
            className="mb-4"
          />
          <div className="w-full flex justify-between items-center mb-4">
            <Text type="H3" className="ts-accent">
              شهرها و استان‌ها
            </Text>

            <FunctionalButtons downloadClicked={() => setDownloadModal(true)} onUploadFinished={getCities} />
          </div>

          <SearchInput
            value={searchText}
            onChange={handleChange}
            onReset={resetSearch}
            onSearch={() => callApi(searchText, citiesAll)}
          />
        </div>

        <div className={`w-full ${cities.length === 0 && !loading ? "mt-36" : "mt-44"}`}>
          <div className="w-full flex justify-between items-center mb-4">
            {loading ? (
              <>
                <Skeleton className="w-28" />
              </>
            ) : (
              cities.length !== 0 && (
                <>
                  <Text type="H6" className="ts-accent">
                    {`${cities.length} شهر`}
                  </Text>
                </>
              )
            )}
          </div>

          {snap.superAdmin && (
            <Button
              onClick={() => setAddEditModal(true)}
              textType="H6"
              bcolor="blackTransparent"
              icon={<IoIosAddCircle size={21} className="ts-accent" />}
              classNameContainer="w-full"
              className={`w-full h-12 rounded-xl mb-4 ${cities.length === 0 && "mt-2"}`}
            >
              اضافه کردن شهر جدید
            </Button>
          )}

          {loading ? (
            <CardLoading height={"76px"} className="mb-4" />
          ) : cities.length === 0 ? (
            <NoResult forSearch={searchText.length !== 0} />
          ) : (
            cities.map((item, index) => {
              return (
                <CityCard
                  key={index}
                  data={item}
                  onEdit={() => {
                    selectHandler(item);
                    editHandler();
                  }}
                  onDelete={() => {
                    selectHandler(item);
                    setDeleteModal(true);
                  }}
                />
              );
            })
          )}
        </div>
      </ContainerScroll>
      <AddEditCityModal
        isOpen={addEditModal}
        onClose={() => setAddEditModal(false)}
        onDone={getCities}
        data={selectedCity}
        type={modalType}
      />
      <DeleteCityModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDone={deleteHandler}
        id={selectedCity?.id || ""}
      />

      <DownloadPromptModal
        isOpen={downloadModal}
        onClose={() => setDownloadModal(false)}
        onApprove={downloadHandler}
        title="شهرها"
      />
    </>
  );
};

export { CitiesList as default };
