/* eslint-disable */
import { ApiInstance } from "core";
import React, { useState } from "react";
import { BottomModal, Button, Text, useNotification } from "ui/components";

interface IDeleteCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  id: string;
  name: string;
}

const DeleteCategoryModal: React.FC<IDeleteCategoryModalProps> = React.memo(({ isOpen, onClose, onDone, id, name }) => {
  const [loading, setLoading] = useState(false);

  const approveBtnHandler = async () => {
    setLoading(true);

    await ApiInstance.shop
      .deletePartCategories(id)
      .then((res) => {
        console.log(res);
        useNotification("دسته بندی مورد نظر حذف شد.", "success");
        onDone();
        setTimeout(() => {
          onClose();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <Text type="H4" className="ts-accent text-right">
        حذف دسته بندی
      </Text>

      <Text type="P3" className="ts-accent my-6 text-right">
        {`آيا میخواهید دسته بندی «${name}» را حذف کنید؟ `}
      </Text>

      <div className="w-full flex justify-between">
        <Button
          onClick={approveBtnHandler}
          loading={loading}
          bcolor="primary"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          بله، حذف شود
        </Button>
        <Button
          onClick={onClose}
          bcolor="primaryTransparent"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          لغو
        </Button>
      </div>
    </BottomModal>
  );
});

const privileges = [
  {
    label: "مدیر سیستم (ادمین)",
    value: "admin",
  },
  {
    label: "کاربر عادی",
    value: "agent",
  },
];

export { DeleteCategoryModal };

const styles = {};
