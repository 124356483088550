/* eslint-disable */
import React from "react";
import { HiMinusCircle, HiPlusCircle, HiTrash } from "react-icons/hi";
import { Text } from "ui/components";

interface IRelatedServiceCardProps {
  data: any;
  onRemove: (id: string) => void;
  increment: () => void;
  decrement: () => void;
}

const RelatedServiceCard: React.FC<IRelatedServiceCardProps> = React.memo(
  ({ data, onRemove, increment, decrement }) => {
    return (
      <div className="w-full flex justify-center items-center mb-6">
        <div
          style={{ height: "52px" }}
          className="w-full p-3 flex justify-between items-center rounded-2xl bg-white ml-1.5 ts-shadow"
        >
          <Text type="H6" className="ts-accent">
            {data.label}
          </Text>
          <div className="flex items-center">
            <HiPlusCircle onClick={increment} size={24} className="ts-accent -mt-px cursor-pointer" />
            <div className="w-11 h-7 ts-bg-gray-01 rounded-xl mx-1 flex items-center justify-center">
              <Text type="H6" className="ts-accent mt-1">
                {data.kilometerRatio}
              </Text>
            </div>
            <HiMinusCircle onClick={decrement} size={24} className="ts-accent -mt-px cursor-pointer" />
          </div>
        </div>
        <div
          style={{ height: "52px" }}
          onClick={() => onRemove(data.value)}
          className="w-1/5 ts-shadow flex justify-center items-center rounded-2xl cursor-pointer"
        >
          <HiTrash size={24} className="ts-accent -ml-0.5 -mt-0.5" />
        </div>
      </div>
    );
  },
);

export { RelatedServiceCard };

const styles = {};
