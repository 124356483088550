/* eslint-disable */
import { ConfigLink, tools, useQueryString } from "core";
import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Button, Text } from "./";

interface IUserPlatesCardProps {
  data: any;
}

const UserPlatesCard: React.FC<IUserPlatesCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  const [cardOpen, setCardOpen] = useState(true);

  useEffect(() => {}, []);

  const seeDetailHandler = () => {
    navigate({ pathname: ConfigLink.plateDetail, search: useQueryString({ id: data.id }) });
  };

  return (
    <div
      className={`w-full ${
        cardOpen ? "ts-plate-box-open" : "ts-plate-box-close"
      } bg-white ts-shadow rounded-2xl p-4 mb-4`}
    >
      <div className="w-full flex justify-between items-center mb-6">
        <div onClick={() => setCardOpen((prev) => !prev)} className={`flex w-full justify-start items-center`}>
          <Text type="H5" className="ts-accent ml-2 mt-1">
            {tools.setPlateNumber(data.plateNumber)}
          </Text>
          <HiChevronDown size={22} className={`ts-accent mt-1 ${cardOpen ? "ts-arrow-up" : "ts-arrow-down"}`} />
        </div>
      </div>

      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          برند و مدل خودرو:
        </Text>
        <Text type="P5" className="ts-accent">
          {data.carName || "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          میزان کارکرد:
        </Text>
        <Text type="P5" className="ts-accent">
          {data.kilometer ? `${tools.thousandSeparator(data.kilometer)} کیلومتر` : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-6">
        <Text type="HM" className="ts-gray-06 w-24 ml-3">
          زمان ایجاد:
        </Text>
        <Text type="P5" className="ts-accent">
          {data.createdAt ? tools.getCardsTime(data.createdAt) : "---"}
        </Text>
      </div>

      <Button
        onClick={seeDetailHandler}
        bcolor="primaryTransparent"
        textType="H6"
        classNameContainer="w-full"
        className="w-full rounded-xl h-12"
      >
        مشاهده جزئیات
      </Button>
    </div>
  );
});

export { UserPlatesCard };

const styles = {};
