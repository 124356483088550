/* eslint-disable */
import { ApiInstance, ConfigLink, vState } from "core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomModal, Button, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

interface IDeletePartModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const DeletePartModal: React.FC<IDeletePartModalProps> = React.memo(({ isOpen, onClose, id }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const [loading, setLoading] = useState(false);

  const approveBtnHandler = async () => {
    setLoading(true);

    await ApiInstance.shop
      .deletePart(id)
      .then((res) => {
        console.log(res);
        useNotification("قطعه مورد نظر حذف شد.", "success");
        onClose();
        navigate(ConfigLink.partsList, { replace: true });
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <Text type="H4" className="ts-accent text-right">
        حذف قطعه
      </Text>

      <Text type="P3" className="ts-accent my-6 text-right">
        آيا میخواهید این قطعه را حذف کنید؟
      </Text>

      <div className="w-full flex justify-between">
        <Button
          onClick={approveBtnHandler}
          loading={loading}
          bcolor="primary"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          بله، حذف شود
        </Button>
        <Button
          onClick={onClose}
          bcolor="primaryTransparent"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          لغو
        </Button>
      </div>
    </BottomModal>
  );
});

export { DeletePartModal };

const styles = {};
