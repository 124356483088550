/* eslint-disable */
import { ConfigLink, tools, useQueryString } from "core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Text } from "ui/components";

interface IProviderCardProps {
  data: any;
}

const ProviderCard: React.FC<IProviderCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const detailBtnHandler = (id) => {
    navigate({ pathname: ConfigLink.serviceProviderDetail, search: useQueryString({ id }) });
  };

  return (
    <div key={data.id} className={`w-full bg-white ts-shadow rounded-2xl p-4 mb-4`}>
      <div className="flex justify-start items-center mb-6">
        <Text type="H5" className="ts-accent ml-2">
          {data.name || "---"}
        </Text>
        {data.archive && (
          <div className="ts-bg-gray-03 flex justify-center items-center rounded-full px-4 py-1 -mt-0.5">
            <Text type="HM" className="ts-gray-06">
              آرشیو شده
            </Text>
          </div>
        )}
      </div>
      <div className="flex justify-start items-center mb-3">
        <Text type="HM" className="ts-gray-06 w-20 ml-3">
          نوع:
        </Text>
        <Text type="P5" className="ts-accent w-11/12 truncate">
          {data.categories ? data.categories.map((item) => item.name).join("، ") || "---" : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-3 ">
        <Text type="HM" className="ts-gray-06 w-20 ml-3">
          شهر:
        </Text>
        <Text type="P5" className="ts-accent w-11/12 truncate">
          {data.city ? `${data.city?.city || ""}, ${data.city?.province || ""}` : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-3 ">
        <Text type="HM" className="ts-gray-06 w-20 ml-3">
          نشانی:
        </Text>
        <Text type="P5" className="ts-accent w-11/12 truncate">
          {data.address ? data.address : "---"}
        </Text>
      </div>

      <div className="flex justify-start items-center mb-6">
        <Text type="HM" className="ts-gray-06 w-20 ml-3">
          زمان ایجاد:
        </Text>
        <Text type="P5" className="ts-accent w-11/12 truncate">
          {data.createdAt ? tools.getCardsTime(data.createdAt) : "---"}
        </Text>
      </div>

      <Button
        onClick={() => detailBtnHandler(data.id)}
        bcolor="primaryTransparent"
        textType="H6"
        classNameContainer="w-full"
        className="w-full h-11 rounded-xl"
      >
        مشاهده جزئیات
      </Button>
    </div>
  );
});

export { ProviderCard };

const styles = {};
